// Ask the server for the name of a facility from the facility code
class FacilityService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getReferralFacility(facilityCode) {
    return this.api.get({
      location: `/facility/referral/${facilityCode}`,
    });
  }
}

export default FacilityService;
