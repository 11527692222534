import React from "react";
import PropTypes from "prop-types";
import venuePropType from "../../Constants/venueTypes";
import PopoverTrigger from "../Popover";
import RadioFieldGroup from "@gbli-events/common/src/Components/FormElements/RadioFieldGroup/RadioFieldGroup";
import { toTitleCase } from "@gbli-events/common/src/Helpers/StringHelper";

const SpecialTaxFields = ({
  hasRegionalTax,
  taxRegions,
  isKentuckyEntity,
  setKentuckyCity,
  updateManualCity,
}) => {
  return (
    <>
      {hasRegionalTax && !isKentuckyEntity && (
        <>
          <div className="mb-1">
            In which district is this address located?
            <span className="ml-1">
              <PopoverTrigger content="Kentucky tax and insurance regulations require payment from insurers for certain risk areas in KY. We need to know which municipality to pay when more than one could apply." />
            </span>
          </div>
          <div className="custom-control custom-radio">
            {taxRegions.length > 0 && (
              <RadioFieldGroup
                controlName="venueMunicipalityCode"
                options={taxRegions.map((region) => {
                  return {
                    label: toTitleCase(region.name),
                    value: region.code,
                    onChange: () => {
                      setKentuckyCity(region.name);
                      updateManualCity(region.name);
                    },
                  };
                })}
                direction="column"
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

SpecialTaxFields.propTypes = {
  hasRegionalTax: PropTypes.bool.isRequired,
  taxRegions: PropTypes.arrayOf(venuePropType).isRequired,
  isKentuckyEntity: PropTypes.bool.isRequired,
  setKentuckyCity: PropTypes.func.isRequired,
  updateManualCity: PropTypes.func,
};

SpecialTaxFields.defaultProps = {
  updateManualCity: () => {},
};

export default SpecialTaxFields;
