import VenuePresetsService from "src/Helpers/VenuePresetsService";
import API from "src/Helpers/API";

export const CONFIRM_OPTIONAL_VENUE = "CONFIRM_OPTIONAL_VENUE";
export const confirmOptionalVenue = (
  index,
  placeId,
  address,
  addressComponents,
  utcOffset
) => ({
  type: CONFIRM_OPTIONAL_VENUE,
  payload: {
    index,
    placeId,
    address,
    addressComponents,
    utcOffset,
  },
});

export const UPDATE_BLOCKED_OPTIONAL_VENUE = "UPDATE_BLOCKED_OPTIONAL_VENUE";
export const updateBlockedOptionalVenue = (data) => ({
  type: UPDATE_BLOCKED_OPTIONAL_VENUE,
  payload: data,
});

export const UPDATE_OPTIONAL_VENUE_ERROR = "UPDATE_OPTIONAL_VENUE_ERROR";
export const updateOptionalVenueError = (data) => ({
  type: UPDATE_OPTIONAL_VENUE_ERROR,
  payload: data,
});

export const CLEAR_OPTIONAL_VENUE = "CLEAR_OPTIONAL_VENUE";
export const clearOptionalVenue = (data) => ({
  type: CLEAR_OPTIONAL_VENUE,
  payload: data,
});

export const CHOOSE_OPTIONAL_VENUE = "CHOOSE_OPTIONAL_VENUE";
export const chooseOptionalVenue =
  (index, placeId, address, addressComponents, utcOffset) => (dispatch) => {
    dispatch(updateOptionalVenueError({ index, value: false }));
    dispatch(updateBlockedOptionalVenue({ index, value: false }));

    new VenuePresetsService(new API())
      .getPresets(placeId)
      .then((response) => {
        const { data } = response;
        if (data.venue.blockedAt) {
          dispatch(
            updateBlockedOptionalVenue({ index, value: data.venue.blockedAt })
          );
        } else {
          dispatch(
            confirmOptionalVenue(
              index,
              placeId,
              address,
              addressComponents,
              utcOffset
            )
          );
        }
      })
      .catch((err) => {
        if (!err.response) {
          dispatch(updateOptionalVenueError({ index, value: true }));
        } else {
          if (err.response.status === 404) {
            dispatch(
              confirmOptionalVenue(
                index,
                placeId,
                address,
                addressComponents,
                utcOffset
              )
            );
          }
        }
      });
  };

export const SET_NEED_OPTIONAL_VENUE = "SET_NEED_OPTIONAL_VENUE";
export const setNeedOptionalVenue = (needOptionalVenue) => {
  return { type: SET_NEED_OPTIONAL_VENUE, payload: { needOptionalVenue } };
};
