import React from "react";
import PropTypes from "prop-types";
import Button from "@gbli-events/common/src/Components/Themed/Button";
import { useSelector } from "react-redux";
import { getFilteredFormStepsConfig } from "src/Selectors/Shared";
import { useRouteMatch } from "react-router-dom";
import { getFormRelativePath } from "src/Helpers/URLParser";
import { PAYMENT_URL } from "src/Constants/constants";
import { useGoToFormPath } from "src/Hooks/FormPaths";

const propTypes = {
  btnText: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

const NavContinueButton = ({ disabled, onClick }) => {
  const goToFormPath = useGoToFormPath();
  const formStepUrls = Object.keys(useSelector(getFilteredFormStepsConfig));

  const { path } = useRouteMatch();
  const relativePath = getFormRelativePath(path);
  const currentPathIndex = formStepUrls.indexOf(relativePath);
  const btnText =
    formStepUrls[currentPathIndex + 1] === PAYMENT_URL
      ? "Continue to payment"
      : "Continue";

  return (
    <Button
      type="button"
      className="continue-btn"
      disabled={disabled}
      onClick={() => {
        const canContinue = onClick && onClick();
        if (canContinue === false) {
          return;
        }
        goToFormPath(formStepUrls[currentPathIndex + 1]);
      }}
    >
      {btnText}
    </Button>
  );
};

NavContinueButton.propTypes = propTypes;

export default NavContinueButton;
