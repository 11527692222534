import {
  PRODUCTS_AND_COMPLETED_OPERATIONS,
  PERSONAL_AND_ADVERTISING_LIMIT,
  DAMAGE_TO_RENTED_PREMISES,
  TERRORISM,
  HOST_LIQUOR,
  MEDICAL,
} from "@gbli-events/common/src/Constants/additionalCoverages";

const getAdditionalCoverageByType = (state, type) =>
  state.formPage.quote.quote?.additionalCoverages?.find(
    (coverage) => coverage.type === type
  );

export const getGlTotalAmount = (state) =>
  parseInt(state.formPage.quote?.quote?.glTotal?.amount || 0);

export const getTotalAmount = (state) =>
  parseInt(state.formPage.quote?.quote?.total?.amount || 0);

export const getSurcharges = (state) => state.formPage.quote?.quote?.surcharges;

export const getPCOAmount = (state) => {
  const additionalCoverage = getAdditionalCoverageByType(
    state,
    PRODUCTS_AND_COMPLETED_OPERATIONS
  );

  return parseInt(additionalCoverage?.amount?.amount || 0);
};

export const getAdvertisingAmount = (state) => {
  const additionalCoverage = getAdditionalCoverageByType(
    state,
    PERSONAL_AND_ADVERTISING_LIMIT
  );

  return parseInt(additionalCoverage?.amount?.amount || 0);
};

export const getDamageToRentedPremisesAmount = (state) => {
  const additionalCoverage = getAdditionalCoverageByType(
    state,
    DAMAGE_TO_RENTED_PREMISES
  );

  return parseInt(additionalCoverage?.amount?.amount || 0);
};

export const getTerrorismAmount = (state) => {
  const additionalCoverage = getAdditionalCoverageByType(state, TERRORISM);

  return parseInt(additionalCoverage?.amount?.amount || 0);
};

export const getHostLiquorAmount = (state) => {
  const additionalCoverage = getAdditionalCoverageByType(state, HOST_LIQUOR);

  return parseInt(additionalCoverage?.amount?.amount || 0);
};

export const getMedicalAmount = (state) => {
  const additionalCoverage = getAdditionalCoverageByType(state, MEDICAL);

  return parseInt(additionalCoverage?.amount?.amount || 0);
};
