/**
 *
 * Middleware that gets US States data from server and dispatches events (ie to add them to a store, etc)
 *
 */
import { ConnectedRouter } from "@jauntin/react-ui";
import API from "../Helpers/API";
import StatesService from "../Helpers/StatesService";
import {
  setUSStates,
  fetchUsStatesFailure,
  errorResponse,
} from "../Actions/actions";
// Storage.setItemLifetime('eventTypes', Storage.minutes(30));

export default (store) => (next) => (action) => {
  const country = "US";
  if (
    action.type === ConnectedRouter.LOCATION_CHANGE &&
    store.getState().formPage.usStates.length === 0
  ) {
    return new StatesService(new API()).getStates(country).then(
      (response) => {
        store.dispatch(setUSStates(response.data));
        return next(action);
      },
      (err) => {
        store.dispatch(fetchUsStatesFailure());
        store.dispatch(errorResponse(err));
      }
    );
  }
  return next(action);
};
