import {
  ENTITY_COMPANY,
  ENTITY_PERSON,
} from "@gbli-events/common/src/Constants/entityTypes";
import { email, phoneNumber } from "@gbli-events/common/src/Helpers/validators";

export const getIsInsuranceContactPageValid = (state) => {
  const {
    insuredRenter,
    contactSame,
    contactFirstName,
    contactLastName,
    contactPhone,
    contactEmail,
  } = state.form.application.values;

  if (
    contactPhone &&
    !phoneNumber(contactPhone) &&
    contactEmail &&
    !email(contactEmail)
  ) {
    const insuredIsPerson = insuredRenter === ENTITY_PERSON;
    const insuredIsCompany = insuredRenter === ENTITY_COMPANY;
    const isContactSame = contactSame === "1";

    const contactNameValid = !!(contactFirstName && contactLastName);

    if (insuredIsPerson) {
      if (isContactSame) {
        return true;
      } else {
        return contactNameValid;
      }
    }

    if (insuredIsCompany) {
      return contactNameValid;
    }
  }

  return false;
};
