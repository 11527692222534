import React from "react";
import { useSelector } from "react-redux";
import {
  ENTITY_PERSON,
  ENTITY_COMPANY,
} from "@gbli-events/common/src/Constants/entityTypes";

const InsuredSummaryTable = () => {
  const {
    insuredFirstName,
    insuredLastName,
    insuredAddress,
    insuredAddress2,
    insuredCity,
    insuredState,
    insuredZip,
    insuredCompany,
    insuredCompanyType,
    insuredRenter,
  } = useSelector((state) => state.form.application.values);

  return (
    <div className="summary-table summary-table--insured">
      <h3 className="summary-table__heading text-left mb-3 font-30">
        Insured:
      </h3>
      <div className="row mb-3">
        {insuredRenter === ENTITY_PERSON && (
          <div className="col-12 col-md-6">
            <div className="summary-table__item">
              <div className="summary-table__item-label">First Name:</div>
              <div className="summary-table__item-value">
                {insuredFirstName}
              </div>
            </div>
            <div className="summary-table__item">
              <div className="summary-table__item-label">Last Name:</div>
              <div className="summary-table__item-value">{insuredLastName}</div>
            </div>
          </div>
        )}
        {insuredRenter === ENTITY_COMPANY && (
          <div className="col-12 col-md-6">
            <div className="summary-table__item">
              <div className="summary-table__item-label">
                Company/Organization Name:
              </div>
              <div className="summary-table__item-value">{insuredCompany}</div>
            </div>
            <div className="summary-table__item">
              <div className="summary-table__item-label">Type of Company:</div>
              <div className="summary-table__item-value">
                {insuredCompanyType}
              </div>
            </div>
          </div>
        )}
        <div className="col-12 col-md-6 summary-table__item">
          <div className="summary-table__item-label">Address:</div>
          <div className="summary-table__item-value">
            <div>{insuredAddress}</div>
            {insuredAddress2 && <div>{insuredAddress2}</div>}
            <div>
              {insuredCity}, {insuredState}
            </div>
            <div>{insuredZip}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuredSummaryTable;
