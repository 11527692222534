import {
  DAMAGE_TO_RENTED_PREMISES,
  DAMAGE_TO_RENTED_PREMISES_VALUES,
} from "@gbli-events/common/src/Constants/additionalCoverages";

export const getAdditionalCoverageByType = (type) => (state) =>
  state.formPage.additionalCoverages.find((ac) => ac.type === type);

export const getDrpLimits = (state) =>
  getAdditionalCoverageByType(DAMAGE_TO_RENTED_PREMISES)(state)?.value ||
  DAMAGE_TO_RENTED_PREMISES_VALUES;
