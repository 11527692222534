import { ConnectedRouter } from "@jauntin/react-ui";
import { merge } from "lodash";
import {
  SET_US_STATES,
  SET_DOWNLOAD_LINK,
  CLEAR_SAMPLE_POLICY_LINK,
} from "../Actions/actions";
import RouteParser from "../Helpers/RouteParser";
import samplePolicyPageInitialState from "../Constants/samplePolicyInitialState";

// List of US States received, should be stored to be used in components
const setUSStates = (state, data) => {
  const updated = { ...state };
  updated.usStates = data;
  return updated;
};

const setDownloadLink = (state, data) => {
  const updated = { ...state };
  updated.pdfLink = data.pdfLink;
  return updated;
};

const clearSamplePolicyLink = (state) => {
  const updated = { ...state };
  updated.pdfLink = "";
  return updated;
};

const handleLocationChange = (state, { location }) => {
  const routeParser = RouteParser.create(location.pathname);
  const updated = { ...state };
  if (routeParser.isSamplePolicyPage()) {
    return merge(updated, samplePolicyPageInitialState());
  }
  return updated;
};

export default (state = samplePolicyPageInitialState(), action) => {
  if (action.type === ConnectedRouter.LOCATION_CHANGE) {
    return { ...handleLocationChange(state, { ...action.payload }) };
  }
  if (action.type === SET_US_STATES) {
    return { ...setUSStates(state, action.payload) };
  }
  if (action.type === SET_DOWNLOAD_LINK) {
    return { ...setDownloadLink(state, action.payload) };
  }
  if (action.type === CLEAR_SAMPLE_POLICY_LINK) {
    return { ...clearSamplePolicyLink(state, action.payload) };
  }
  return state;
};
