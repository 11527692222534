import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialize, reset, reduxForm, Field } from "redux-form";
import PropTypes from "prop-types";
import { compose } from "redux";
import { Form, Modal } from "react-bootstrap";
import { useTheme } from "styled-components";
import Button from "@gbli-events/common/src/Components/Themed/Button";
import { saveAdditionalInsuredFormName } from "src/Constants/constants";
import { getAdditionalInsuredPayload } from "src/Selectors/AdditionalInsured";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import AddressFieldGroup from "@gbli-events/common/src/Components/FormElements/AddressFieldGroup";
import { getUsStates } from "src/Selectors/Shared";
import { createStateOptions } from "src/Helpers/StateSelectOptions";
import additionalInsuredPropType from "src/Constants/additionalInsuredType";
import {
  additionalInsuredTypes,
  additionalInsuredVenueAddressType,
  additionalInsuredFacilityType,
} from "src/Constants/additionalInsuredTypes";
import { required } from "@gbli-events/common/src/Helpers/validators";

const ModalSaveAdditionalInsured = ({
  show,
  type,
  address,
  pristine,
  invalid,
  onSave,
  handleClose,
  companyNameFieldLabel,
}) => {
  const dispatch = useDispatch();
  const data = useSelector(getAdditionalInsuredPayload);
  const theme = useTheme();
  const usStatesOptions = createStateOptions(useSelector(getUsStates));

  useEffect(() => {
    if (show) {
      dispatch(reset(saveAdditionalInsuredFormName));
      dispatch(
        initialize(saveAdditionalInsuredFormName, {
          companyName: address.companyName,
          address1: address.address1,
          address2: address.address2,
          city: address.city,
          state: address.state,
          zip: address.zip,
          country: "US",
          // need to reset form completely (include state field) https://stackoverflow.com/questions/50640858/react-select-does-not-clear-value-when-redux-form-is-reset
          uniqueKey: new Date().getTime(),
        })
      );
    }
  }, [show, address, dispatch]);

  const dataEmpty = [
    "companyName",
    "address1",
    "address2",
    "city",
    "state",
    "zip",
  ].every((key) => !data[key].trim());

  return (
    <Modal show={show} size="lg" key={data.uniqueKey}>
      <Modal.Body className="pb-4">
        <Modal.Header className="w-100 border-0 pb-0" />
        <div className="additional-insured-address container">
          <Form.Group>
            <Field
              component={TextField}
              label={companyNameFieldLabel}
              ariaLabel={companyNameFieldLabel}
              validate={
                type === additionalInsuredFacilityType ? [required] : []
              }
              name="companyName"
            />
          </Form.Group>
          <AddressFieldGroup
            theme={theme}
            usStatesOptions={usStatesOptions}
            streetAddressControl="address1"
            addressLine2Control="address2"
            cityControl="city"
            stateControl="state"
            zipControl="zip"
            required={type === additionalInsuredVenueAddressType}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 pb-4">
        <Button type="button" variant="secondary" outline onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="button"
          onClick={() => {
            onSave(type, data);
            handleClose();
          }}
          disabled={pristine || invalid || dataEmpty}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalSaveAdditionalInsured.propTypes = {
  show: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(additionalInsuredTypes).isRequired,
  handleClose: PropTypes.func.isRequired,
  address: additionalInsuredPropType,
  onSave: PropTypes.func,
  companyNameFieldLabel: PropTypes.string.isRequired,
};

ModalSaveAdditionalInsured.defaultProps = {
  show: false,
  uniqueKey: "",
};

export default compose(reduxForm({ form: saveAdditionalInsuredFormName }))(
  ModalSaveAdditionalInsured
);
