import { FORM_CONTROL_YES } from "@gbli-events/common/src/Constants/formControls";
import {
  ENTITY_PERSON,
  ENTITY_COMPANY,
} from "@gbli-events/common/src/Constants/entityTypes";
import {
  creditCardNumber,
  creditCardCvv,
  zipCode,
  required,
} from "@gbli-events/common/src/Helpers/validators";
import cc from "card-validator";
import { normalizeYear } from "@gbli-events/common/src/Helpers/normalizers";
import { cyberSourceIdFromCardTypeName } from "src/Helpers/CyberSourceCardTypes";
import { getTouchedFieldError } from "./Form";

export const getCCParams = (state) => {
  const cardNumber = state.form.application.values.payeeCardNum;
  const { payeeCardExpMon: month, payeeCardExpYr: year } =
    state.form.application.values;
  const fourDigitYear = normalizeYear(year);
  const cardValidator = cc.number(cardNumber);
  const cardValidatorCard = cardValidator.card || { type: null };
  const cardType = cyberSourceIdFromCardTypeName(cardValidatorCard.type);
  return {
    cardNumber,
    cardExpirationMonth: month,
    cardExpirationYear: fourDigitYear,
    cardType,
  };
};

export const getIsPaymentPageValid = (state) => {
  const {
    payeeCardNum,
    payeeCardName,
    payeeCardExpMon,
    payeeCardExpYr,
    payeeSame,
    payeeRenter,
    payeeFirstName,
    payeeLastName,
    payeeCompany,
    payeeAddress,
    payeeCity,
    payeeState,
    payeeZip,
    payeeCardCVV,
    consentConfirmed,
    northDakotaDisclosure,
  } = state.form.application.values;
  const isNotExpired = cc.expirationDate(
    `${payeeCardExpMon}${payeeCardExpYr}`
  ).isValid;

  const isCCFieldsValid =
    !!payeeCardName &&
    !Boolean(creditCardNumber(payeeCardNum)) &&
    !Boolean(creditCardCvv(payeeCardCVV, state.form.application.values)) &&
    !!payeeCardExpMon &&
    !!payeeCardExpYr &&
    isNotExpired;

  let isBillingInfoValid;

  if (payeeSame === FORM_CONTROL_YES) {
    isBillingInfoValid = true;
  } else {
    const isPayeeAddressValid = !!(
      !!payeeAddress &&
      !!payeeCity &&
      !!payeeState &&
      !Boolean(zipCode(payeeZip)) &&
      !Boolean(required(payeeZip))
    );
    if (payeeRenter === ENTITY_PERSON) {
      isBillingInfoValid =
        !!(payeeFirstName && payeeLastName) && isPayeeAddressValid;
    }

    if (payeeRenter === ENTITY_COMPANY) {
      isBillingInfoValid = !!payeeCompany && isPayeeAddressValid;
    }
  }

  let northDakotaDisclosureValid = true;
  if (isVenueInNorthDakota(state) && !northDakotaDisclosure) {
    northDakotaDisclosureValid = false;
  }

  return (
    isCCFieldsValid &&
    isBillingInfoValid &&
    consentConfirmed &&
    northDakotaDisclosureValid
  );
};

export const isVenueInNorthDakota = (state) => {
  return state.formPage.venue?.selectedPlaceAddressComponents?.state === "ND";
};

export const insuredPaymentHasErrors = (state) =>
  !!(
    getTouchedFieldError("insuredFirstName")(state) ||
    getTouchedFieldError("insuredCompany")(state) ||
    getTouchedFieldError("insuredLastName")(state) ||
    getTouchedFieldError("insuredAddress")(state) ||
    getTouchedFieldError("insuredCity")(state) ||
    getTouchedFieldError("insuredState")(state) ||
    getTouchedFieldError("insuredZip")(state) ||
    getTouchedFieldError("contactPhone")(state) ||
    getTouchedFieldError("contactEmail")(state) ||
    getTouchedFieldError("payeeCardNum")(state) ||
    getTouchedFieldError("payeeCardExpMon")(state) ||
    getTouchedFieldError("payeeCardExpYr")(state) ||
    getTouchedFieldError("payeeCardCVV")(state) ||
    getTouchedFieldError("payeeCardCVV")(state) ||
    getTouchedFieldError("payeeCardName")(state) ||
    getTouchedFieldError("consentConfirmed")(state) ||
    getTouchedFieldError("contactFirstName")(state) ||
    getTouchedFieldError("contactLastName")(state) ||
    getTouchedFieldError("northDakotaDisclosure")(state) ||
    getTouchedFieldError("payeeFirstName")(state) ||
    getTouchedFieldError("payeeCompany")(state) ||
    getTouchedFieldError("payeeLastName")(state) ||
    getTouchedFieldError("payeeAddress")(state) ||
    getTouchedFieldError("payeeCity")(state) ||
    getTouchedFieldError("payeeState")(state) ||
    getTouchedFieldError("payeeZip")(state)
  );
