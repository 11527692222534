// Ask the server for a list of all underwriting questions
class UnderwritingQuestionsService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getUnderwritingQuestions() {
    return this.api.get({ location: "/underwriting-questions" });
  }
}

export default UnderwritingQuestionsService;
