import { createGlobalStyle } from "styled-components";
import { getFont } from "@gbli-events/common/src/Helpers/themes";

const GlobalStyles = createGlobalStyle`
    body {
        font-family: ${({ theme }) => getFont(theme)}
    }
    a,
    a:hover {
        color: inherit;
        text-decoration: none;
        font-weight: 400;
    }
    h1 {
        ${({ theme }) => theme.typography.h1}
    }
    h2 {
        ${({ theme }) => theme.typography.h2}
    }
    h3 {
        ${({ theme }) => theme.typography.h3}
    }
    h4 {
        ${({ theme }) => theme.typography.h4}
    }
    h5 {
        ${({ theme }) => theme.typography.h5}
    }
    h6 {
        ${({ theme }) => theme.typography.h6}
    }
    ${({ theme }) => theme.globalStyles}
`;

export default GlobalStyles;
