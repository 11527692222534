import {
  dayIsInOverallValidRange,
  dayIsValidForStartAndEndDate,
} from "@gbli-events/common/src/Helpers/DateHelpers";
import { subMinutes } from "date-fns";

export const disabledDatesForDateRangePicker =
  ({ venueUtcOffset, startDate }) =>
  (focusedInput) =>
  (day) => {
    // Set available dates based on venue location date-time
    const utcOffsetDiff = new Date().getTimezoneOffset() * -1 - venueUtcOffset;
    const venueCurrentDateTime = subMinutes(new Date(), utcOffsetDiff);

    if (focusedInput === "startDate") {
      return !dayIsInOverallValidRange(day, venueCurrentDateTime);
    }

    if (focusedInput === "endDate") {
      return (
        !dayIsInOverallValidRange(day, venueCurrentDateTime) ||
        !dayIsValidForStartAndEndDate(day, startDate)
      );
    }

    return false;
  };
