// Ask the server for the name of a facility from the facility code
class ProducerService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getProducerByCommissionId(commissionId) {
    return this.api.get({
      location: `/producers/byCommission/${commissionId}`,
    });
  }

  getProducerByCode(producerCode) {
    return this.api.get({
      location: `/producers/byCode/${producerCode}`,
    });
  }
}

export default ProducerService;
