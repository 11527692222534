import { touch } from "redux-form";
import { formName } from "src/Constants/constants";
import { touchRegistered } from "./actions";

export const confirmInsuredAndPaymentForm = () => (dispatch) => {
  dispatch(
    touch(
      formName,
      "insuredFirstName",
      "insuredLastName",
      "insuredAddress",
      "insuredCity",
      "insuredState",
      "insuredZip",
      "contactPhone",
      "contactEmail",
      "payeeCardNum",
      "payeeCardExpMon",
      "payeeCardExpYr",
      "payeeCardCVV",
      "payeeCardName",
      "consentConfirmed"
    )
  );
  dispatch(
    touchRegistered(
      formName,
      "contactFirstName",
      "contactLastName",
      "insuredCompany",
      "northDakotaDisclosure",
      "payeeFirstName",
      "payeeLastName",
      "payeeCompany",
      "payeeAddress",
      "payeeCity",
      "payeeState",
      "payeeZip"
    )
  );
};
