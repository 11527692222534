/**
 *
 * Middleware that gets initial data from servers and dispatches events (ie to add them to a store, etc)
 * Used to get event types from the server
 *
 */
import { ConnectedRouter } from "@jauntin/react-ui";
import UnderwritingQuestionsService from "../Helpers/UnderwritingQuestionsService";
import API from "../Helpers/API";
import {
  fetchUnderwritingQuestionsFailure,
  setUnderwritingQuestions,
} from "../Actions/underwritingQuestions";
import { errorResponse } from "src/Actions/actions";
// Storage.setItemLifetime('eventTypes', Storage.minutes(30));
import { getUnderwritingQuestionsLoaded } from "src/Selectors/UnderwritingQuestions";

export default (store) => (next) => (action) => {
  if (
    action.type === ConnectedRouter.LOCATION_CHANGE &&
    !getUnderwritingQuestionsLoaded(store.getState())
  ) {
    return new UnderwritingQuestionsService(new API())
      .getUnderwritingQuestions()
      .then(
        ({ data }) => {
          store.dispatch(setUnderwritingQuestions(data));
          return next(action);
        },
        (err) => {
          store.dispatch(fetchUnderwritingQuestionsFailure());
          store.dispatch(errorResponse(err));
        }
      );
  }
  return next(action);
};
