import { UPDATE_SYNC_ERRORS } from "redux-form/lib/actionTypes";
import { ConnectedRouter } from "@jauntin/react-ui";
import deepmerge from "deepmerge";
import { POLICY_CANCELLED, CANCEL_FORM_SET_WAITING } from "../Actions/actions";
import RouteParser from "../Helpers/RouteParser";
import refundCancelPageInitialState from "../Constants/refundCancelInitialState";

const isFormValid = (state, errors) => {
  const updated = { ...state };
  updated.disabled =
    !errors.cancelPolicyNumber &&
    !errors.cancelCreditCard &&
    !errors.cancelZip &&
    !errors.cancelAccept;
  return updated;
};

const policyCancelled = (state, data) => {
  const updated = { ...state };
  updated.policyCancelled = true;
  updated.referenceNumber = data.referenceNumber;
  updated.policyNumber = data.policyNumber;
  updated.contactEmail = data.email;
  return updated;
};

const handleLocationChange = (state, { location }) => {
  const routeParser = RouteParser.create(location.pathname);
  const updated = { ...state };
  if (routeParser.isRefundsCancellationsPage()) {
    return deepmerge(updated, refundCancelPageInitialState());
  }
  return updated;
};

const setWaiting = (state, value) => {
  const updated = { ...state };
  updated.waiting = value;
  return updated;
};

export default (state = refundCancelPageInitialState(), action) => {
  if (action.type === ConnectedRouter.LOCATION_CHANGE) {
    return { ...handleLocationChange(state, { ...action.payload }) };
  }
  if (action.type === UPDATE_SYNC_ERRORS) {
    return { ...isFormValid(state, action.payload.syncErrors) };
  }
  if (action.type === POLICY_CANCELLED) {
    return { ...policyCancelled(state, action.payload) };
  }
  if (action.type === CANCEL_FORM_SET_WAITING) {
    return { ...setWaiting(state, action.payload) };
  }
  return state;
};
