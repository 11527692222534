import React, { useEffect, useRef } from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  display: flex;
  height: 300px;
  width: 100%;
  justify-content: center;
  margin-bottom: 1rem;
`;

const MapContainer = styled.div`
  flex: 1;
`;
const Map = ({ address, placeId, google }) => {
  const mapRef = useRef(null);
  useEffect(() => {
    if (!google || !mapRef.current || !address || !placeId) {
      return;
    }
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId: placeId }).then(({ results }) => {
      if (results[0]) {
        const location = results[0].geometry.location;
        const map = new google.maps.Map(mapRef.current, {
          center: location,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          zoom: 15,
        });
        const marker = new google.maps.Marker({
          map,
          position: location,
        });
        const infoWindow = new google.maps.InfoWindow();
        infoWindow.setContent(address);
        infoWindow.open(map, marker);
      }
    });
  }, [mapRef, address, placeId, google]);

  return (
    <Wrapper>
      <MapContainer ref={mapRef} />
    </Wrapper>
  );
};
export default React.memo(Map);
