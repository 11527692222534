export const toTitleCase = (str) => {
  str = str.toLowerCase();
  return toTitleCaseWithSeparator(
    toTitleCaseWithSeparator(toTitleCaseWithSeparator(str, "-"), " "),
    " - "
  );
};

export const toTitleCaseWithSeparator = (str, separator) => {
  return str
    .split(separator)
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(separator);
};
