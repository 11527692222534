import PropTypes from "prop-types";

export const emptyEventType = {
  identifier: null,
  name: null,
  headerName: null,
  subtitle: null,
  eventCoverageNote: null,
  lossClaims: null,
  security: null,
  promoter: null,
  riskClass: null,
  ACTerrorism: null,
  ACDamageToRentedProperty: null,
  ACPersonalProperty: null,
  ACHostLiquor: null,
  ACPersonalAndAdvertisingLimit: null,
  ACProductsAndCompletedOperations: null,
  ACSellingLiquor: null,
  ACCancellation: null,
};

export const eventPropType = PropTypes.shape({
  identifier: PropTypes.string,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  eventCoverageNote: PropTypes.string,
  lossClaims: PropTypes.bool,
  security: PropTypes.bool,
  promoter: PropTypes.bool,
  riskClass: PropTypes.number,
  ACTerrorism: PropTypes.bool,
  ACDamageToRentedProperty: PropTypes.bool,
  ACPersonalProperty: PropTypes.bool,
  ACHostLiquor: PropTypes.bool,
  ACPersonalAndAdvertisingLimit: PropTypes.bool,
  ACProductsAndCompletedOperations: PropTypes.bool,
  ACSellingLiquor: PropTypes.bool,
  ACCancellation: PropTypes.bool,
});
