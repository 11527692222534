import PriceService from "src/Helpers/PriceService";
import API from "src/Helpers/API";
import { errorResponse } from "./actions";
// Ask the server for a new quote
// Dispatches UPDATE_QUOTE and API_ERROR

export const LOAD_QUOTE = "LOAD_QUOTE";
export const loadQuote = (quote) => {
  return { type: LOAD_QUOTE, payload: quote };
};

export const FETCH_QUOTE = "FETCH_QUOTE";
export const fetchQuote = () => {
  return { type: FETCH_QUOTE };
};

export const FETCH_QUOTE_SUCCESS = "FETCH_QUOTE_SUCCESS";
export const updateQuote = (values) => {
  return { type: FETCH_QUOTE_SUCCESS, payload: values };
};

export const FETCH_QUOTE_ERROR = "FETCH_QUOTE_ERROR";
export const fetchQuoteError = (error) => {
  return { type: FETCH_QUOTE_ERROR, payload: error };
};

export const requestQuote = (quoteValues) => {
  return (dispatch) => {
    dispatch(fetchQuote());
    new PriceService(new API())
      .getQuote(quoteValues)
      .then((response) => {
        dispatch(updateQuote(response.data));
      })
      .catch((err) => {
        dispatch(errorResponse(err));
        dispatch(fetchQuoteError(err.response.data));
      });
  };
};
