/**
 *
 * Middleware that gets eventTypes from servers and dispatches events (ie to add them to a store, etc)
 * Used to get event types from the server
 *
 */
import { ConnectedRouter } from "@jauntin/react-ui";
import EventTypeService from "../Helpers/EventTypeService";
import API from "../Helpers/API";
import {
  setEventTypes,
  fetchEventTypesFailure,
  errorResponse,
} from "../Actions/actions";
// Storage.setItemLifetime('eventTypes', Storage.minutes(30));

export default (store) => (next) => (action) => {
  if (
    action.type === ConnectedRouter.LOCATION_CHANGE &&
    store.getState().formPage.eventTypes.length === 0
  ) {
    return new EventTypeService(new API()).getEventTypes().then(
      ({ data }) => {
        store.dispatch(setEventTypes(data));
        return next(action);
      },
      (err) => {
        store.dispatch(fetchEventTypesFailure());
        store.dispatch(errorResponse(err));
      }
    );
  }
  return next(action);
};
