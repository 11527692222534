import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change, reset } from "redux-form";
import { Modal } from "react-bootstrap";
import Button from "@gbli-events/common/src/Components/Themed/Button";
import AsyncButton from "@gbli-events/common/src/Components/AsyncButton";
import PropTypes from "prop-types";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";
import PriceService from "src/Helpers/PriceService";
import API from "src/Helpers/API";
import { saveQuoteFormName, formName } from "src/Constants/constants";
import EmailField from "@gbli-events/common/src/Components/FormElements/EmailField";
import { getSaveQuotePayload } from "src/Selectors/SavingQuote";
import Alert from "@gbli-events/common/src/Components/Themed/Alert";
import {
  GBLI_EMAIL_EVENTS,
  GBLI_PHONE_EVENTS_TITLE,
} from "@gbli-events/common/src/Constants/shared";
import { validators, normalizers } from "@jauntin/utilities";
import { saveUserEntryData } from "src/Actions/userEntryData";
import { getUserEntryDataPayload } from "src/Selectors/UserEntryData";
const { required, email } = validators;
const { normalizeEmail } = normalizers;

const ModalSaveQuote = ({ show, handleClose, pristine, invalid }) => {
  const dispatch = useDispatch();
  const data = useSelector(getSaveQuotePayload);
  const userEntryData = useSelector(getUserEntryDataPayload);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [apiError, setApiError] = useState(false);

  const submitForm = async (data) => {
    setSubmitting(true);
    try {
      await new PriceService(new API()).saveQuote(data);
      setSubmitting(false);
      setSubmitted(true);
      setApiError(false);
    } catch (e) {
      setApiError(true);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (show) {
      dispatch(reset(saveQuoteFormName));
      setSubmitted(false);
    }
  }, [show, dispatch]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      {submitted ? (
        <>
          <Modal.Body className="pb-4">
            <Modal.Header className="w-100 border-0 pb-0" />
            <div className="mt-4 text-center">
              A link for the quote has been sent to your email.
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pb-4">
            <Button type="button" onClick={handleClose}>
              Done
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Body className="pb-4">
            <Modal.Header className="w-100 border-0 pb-0" />
            <div className="mt-4">
              A link for the quote will be sent to your email.
            </div>
            <Field
              name="email"
              component={EmailField}
              validate={[required, email]}
              normalize={normalizeEmail}
              inputClassName="form-control-lg"
              className="mb-0"
            />
            {apiError && (
              <Alert variant="error" className="mt-3">
                <i className="far fa-exclamation-circle alert__icon" />
                <div className="alert__text">
                  Sorry, we were unable to process your request. Please try
                  again or contact us at{" "}
                  <span className="text-nowrap">{GBLI_PHONE_EVENTS_TITLE}</span>{" "}
                  or {GBLI_EMAIL_EVENTS} for further assistance.{" "}
                </div>
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer className="border-0 pb-4">
            <Button
              type="button"
              variant="secondary"
              outline
              onClick={handleClose}
            >
              Cancel
            </Button>
            <AsyncButton
              type="button"
              onClick={() => {
                submitForm(data).then((r) => {
                  dispatch(change(formName, "quoteSaved", true));
                  saveUserEntryData({
                    data: {
                      ...userEntryData.data,
                      quoteSaved: true,
                    },
                    formUniqId: userEntryData.formUniqId,
                  });
                });
              }}
              spinning={submitting}
              spinningText="Saving..."
              disabled={pristine || invalid}
            >
              Save Quote
            </AsyncButton>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

ModalSaveQuote.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

ModalSaveQuote.defaultProps = {
  show: false,
};

export default compose(reduxForm({ form: saveQuoteFormName }))(ModalSaveQuote);
