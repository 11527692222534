import styled from "styled-components";
import { CardCVVInput } from "@jauntin/react-ui";

const CardCVVField = styled(CardCVVInput)`
  input {
    ${({ theme }) => theme.components.Input?.customCss}
  }
`;

export default CardCVVField;
