import PropTypes from "prop-types";

export const DATA_TYPE_BOOLEAN = "boolean";
export const DATA_TYPE_NUMBER = "number";
export const DATA_TYPE_TEXT = "text";

export const questionTypePropType = PropTypes.oneOf([
  DATA_TYPE_BOOLEAN,
  DATA_TYPE_NUMBER,
  DATA_TYPE_TEXT,
]);

export const underwritingQuestionPropType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: questionTypePropType.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
});
