import React from "react";
import PropTypes from "prop-types";
import { eventPropType } from "src/Models/EventTypeModel";
import ButtonStyledDiv from "@gbli-events/common/src/Components/Themed/ButtonStyledDiv";

const EventCard = ({ eventType, cardTitle, image, selected, onClick }) => (
  <button
    type="button"
    className={`event-card ${selected ? "event-card--selected" : ""}`}
    onClick={onClick}
  >
    <div
      className="event-card__image"
      style={{ backgroundImage: "url(" + image + ")" }}
    ></div>
    <div className="event-card__copy">
      <div className="event-card__title">
        <p className="font-weight-bold">{cardTitle}</p>
      </div>
      <div className="event-card__divider"></div>
      <div className="event-card__sub-title">
        <p>{eventType.subtitle}</p>
      </div>
      {selected ? (
        <ButtonStyledDiv
          type="button"
          className="event-card__select-btn event-card__select-btn--selected"
          variant="secondary"
        >
          Selected
        </ButtonStyledDiv>
      ) : (
        <ButtonStyledDiv
          type="button"
          className="event-card__select-btn"
          variant="secondary"
          outline
        >
          Select
        </ButtonStyledDiv>
      )}
    </div>
  </button>
);

EventCard.propTypes = {
  eventType: eventPropType,
  cardTitle: PropTypes.string.isRequired,
  image: PropTypes.string,
  selected: PropTypes.bool,
  onCardClick: PropTypes.func,
};

EventCard.defaultProps = {
  image: "",
  selected: false,
  onClick: () => {},
};

export default EventCard;
