/**
 * Makes a request to API server for list of US States
 *
 */
class StatesService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getStates({ country = "US" }) {
    return this.api.get({
      location: "/location/state",
      params: {
        country,
      },
    });
  }
}

export default StatesService;
