import StyledAppErrorMessage from "./StyledAppErrorMessage";

const AppErrorMessage = () => (
  <StyledAppErrorMessage className="container">
    <h1>
      Whoops. Something went wrong. Please{" "}
      <button
        className="undecorate-btn"
        onClick={() => {
          window.location.reload();
        }}
      >
        click here
      </button>
      to try again.
    </h1>
  </StyledAppErrorMessage>
);

export default AppErrorMessage;
