// General debouncer
// sample use:
// function myAsyncCall() {
//   new Debounce({ period: 1500 }).do(() => dispatch(someAction));
// }
class Debounce {
  timeOut = null;

  callback = null;

  args = null;

  period = 2000;

  constructor({ period }) {
    this.period = period;
  }

  do(callback, ...args) {
    this.callback = callback;
    this.args = args;
    if (this.timeOut) return;
    this.timeOut = window.setTimeout(() => {
      this.callback(...this.args);
      this.timeOut = null;
    }, this.period);
  }
}
export default Debounce;
