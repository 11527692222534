import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import StyledCheckboxField from "@gbli-events/common/src/Components/FormElements/CheckboxField/StyledCheckboxField";

const WeddingQuestions = ({ className }) => (
  <div
    className={
      className ? `${className}__wedding-questions` : "wedding-questions"
    }
  >
    <Field
      name="weddingRehearsal"
      component={StyledCheckboxField}
      label="Include coverage for a rehearsal dinner the evening before? (Coverage extension for time that you are conducting a wedding
        rehearsal before your wedding)"
    />
    <Field
      name="weddingBrunch"
      component={StyledCheckboxField}
      label="Include coverage for breakfast/brunch the following morning? (Coverage extension for time you are holding a breakfast or
        brunch after your wedding)"
    />
  </div>
);

WeddingQuestions.propTypes = {
  className: PropTypes.string,
};

export default WeddingQuestions;
