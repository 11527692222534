import { formStepsConfig } from "src/Helpers/FormStepsConfig";
import { ADDITIONAL_INSURED_URL } from "src/Constants/constants";

export const getUsStates = (state) => state.formPage.usStates;

export const getFilteredFormStepsConfig = (state) => {
  const hasCertHolders = !!state.formPage.summary.additionalCertHolders.length;
  const result = {
    ...formStepsConfig,
  };

  if (!hasCertHolders) delete result[ADDITIONAL_INSURED_URL];

  return result;
};

export const getIsEmbed = (state) => state.formPage.isEmbed;

export const getIsAppDataLoaded = (state) => {
  return !!state.formPage.usStates.length;
};

export const getHasAppDataError = (state) => {
  return state.formPage.appDataError;
};

export const getReferralQueryParams = (state) => {
  const { facilityCode, venueCode, producerCode } = state.formPage.referral;

  const queryParams = {
    prop: facilityCode && venueCode ? `${facilityCode}-${venueCode}` : null,
    pro: producerCode,
  };

  const filteredKeys = Object.keys(queryParams).filter(
    (key) => queryParams[key]
  );

  if (!filteredKeys.length) return "";

  const filteredQueryParams = filteredKeys.reduce((params, key) => {
    params[key] = queryParams[key];
    return params;
  }, {});

  return `?${new URLSearchParams(filteredQueryParams).toString()}`;
};

export const getValidatingReferralCodes = (state) => {
  const { fetchingVenuePresets, fetchingProducerCode } =
    state.formPage.referral;

  return fetchingVenuePresets || fetchingProducerCode;
};

export const getStatesByInsuranceCompany =
  (company, availableStates, withAnd = false) =>
  (state) => {
    const companies = state.formPage.insuranceCompanies.filter(
      (item) => availableStates.includes(item.state) && item.company === company
    );
    const states = companies.map((company) => company.state);
    if (withAnd) {
      states[states.length - 1] = `and ${states[states.length - 1]}`;
    }
    return states;
  };

export const getShowAgentsLink = (state) =>
  state.formPage.referral.producerCode === null;

export const getReferralLogo = (state) => {
  if (state.formPage.venue.facilityLogoUrl) {
    return {
      url: state.formPage.venue.facilityLogoUrl,
      alt: "Organization Logo",
    };
  }

  if (state.formPage.referral.facilityLogoUrl) {
    return {
      url: state.formPage.referral.facilityLogoUrl,
      alt: "Organization Logo",
    };
  }

  if (state.formPage.referral.producerLogoUrl) {
    return {
      url: state.formPage.referral.producerLogoUrl,
      alt: "Producer Logo",
    };
  }

  return null;
};
