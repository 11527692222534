export const isRequiredCondition =
  (underwritingQuestions) => (underwritingQuestion) =>
    !(
      ["sellsAlcohol", "hasAlcoholProfit", "hasProfessionalBartender"].includes(
        underwritingQuestion.code
      ) &&
      underwritingQuestions.find(
        (underwritingQuestion) =>
          underwritingQuestion.code === "willServeAlcohol"
      )?.answer !== "Yes"
    );
