import {
  ENTITY_PERSON,
  ENTITY_COMPANY,
} from "@gbli-events/common/src/Constants/entityTypes";
import { zipCode, required } from "@gbli-events/common/src/Helpers/validators";

export const getIsInsuredInformationPageValid = (state) => {
  const {
    insuredRenter,
    insuredFirstName,
    insuredLastName,
    insuredCompany,
    insuredCompanyType,
    insuredAddress,
    insuredCity,
    insuredState,
    insuredZip,
  } = state.form.application.values;

  const isPerson = insuredRenter === ENTITY_PERSON;

  const isPersonNameValid = !!(insuredFirstName && insuredLastName);

  const isCompanyInfoValid = !!(insuredCompany && insuredCompanyType);

  const addressValid = !!(
    insuredAddress &&
    insuredCity &&
    insuredState &&
    !zipCode(insuredZip) &&
    !required(insuredZip)
  );

  const isValid =
    addressValid && (isPerson ? isPersonNameValid : isCompanyInfoValid);

  return isValid;
};

export const getDashedInsuredName = (state) => {
  const { insuredCompany, insuredFirstName, insuredLastName, insuredRenter } =
    state.form.application.values;

  let insuredName;

  switch (insuredRenter) {
    case ENTITY_COMPANY:
      insuredName = insuredCompany;
      break;
    case ENTITY_PERSON:
    default:
      insuredName = `${insuredFirstName}-${insuredLastName}`;
  }

  return insuredName.split(" ").join("-");
};
