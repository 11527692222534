import React from "react";
import PropTypes from "prop-types";
import { FieldInputProps } from "@gbli-events/common/src/Constants/ReduxFormPropTypes";
import { createStateOptions } from "../../Helpers/StateSelectOptions";
import SelectList from "@gbli-events/common/src/Components/FormElements/SelectList";

export const usStatePropType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  })
);

const StateField = ({ input, usStates }) => (
  <div className="col-md-6 px-0">
    <SelectList
      options={createStateOptions(usStates)}
      input={input}
      searchable
      placeholder="State"
    />
  </div>
);

StateField.propTypes = {
  input: FieldInputProps.isRequired,
  usStates: usStatePropType.isRequired,
};

export default StateField;
