import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { pageNotFound } from "../Actions/shared";
import PropTypes from "prop-types";

const propTypes = {
  notFoundRedirectUrl: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatchPageNotFound: () => dispatch(pageNotFound()),
});

const PageNotFoundRedirect = ({
  notFoundRedirectUrl,
  dispatchPageNotFound,
}) => {
  useEffect(() => {
    dispatchPageNotFound();
  }, [dispatchPageNotFound]);

  return <Redirect to={notFoundRedirectUrl} />;
};

PageNotFoundRedirect.propTypes = propTypes;

PageNotFoundRedirect.defaultProps = {
  notFoundRedirectUrl: "",
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageNotFoundRedirect);
