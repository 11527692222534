import cloneDeep from "lodash/cloneDeep";

const thankyouPageState = {
  completedOrder: {
    orderNumber: null,
    policyNumber: null,
    email: null,
    pdfLink: null,
    downloaded: false,
    insuredName: null,
    timeoutCounter: 1,
    showTimeoutMessage: false,
  },
};

const clone = (o) => cloneDeep(o);

const thankyouPageInitialState = () => clone(thankyouPageState);

export default thankyouPageInitialState;
