import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import SelectListField from "@gbli-events/common/src/Components/FormElements/SelectListField/SelectListField";
import {
  required as requiredValidator,
  zipCode,
} from "@gbli-events/common/src/Helpers/validators";
import { normalizeZip } from "@gbli-events/common/src/Helpers/normalizers";
import { GBLI_THEME } from "../../Constants/themes/gbliTheme";
import themePropType from "../../Models/Theme";

const propTypes = {
  usStatesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  streetAddressControl: PropTypes.string.isRequired,
  addressLine2Control: PropTypes.string.isRequired,
  cityControl: PropTypes.string.isRequired,
  stateControl: PropTypes.string.isRequired,
  zipControl: PropTypes.string.isRequired,
  theme: themePropType,
  required: PropTypes.bool,
};

const AddressFieldGroup = ({
  usStatesOptions,
  streetAddressControl,
  addressLine2Control,
  cityControl,
  stateControl,
  zipControl,
  theme,
  required = true,
}) => {
  return (
    <>
      <Field
        component={TextField}
        label="Street Address"
        placeholder="Street Address"
        ariaLabel="Street Address"
        validate={required ? [requiredValidator] : []}
        required
        name={streetAddressControl}
      />
      <Field
        component={TextField}
        label="Address Line 2 (Suite, Unit, etc.)"
        placeholder="Address Line 2 (Suite, Unit, etc.)"
        ariaLabel="Address Line 2 (Suite, Unit, etc.)"
        name={addressLine2Control}
      />
      <div className="form-row">
        <div className="col-sm">
          <Field
            component={TextField}
            label="City"
            placeholder="City"
            ariaLabel="City"
            validate={required ? [requiredValidator] : []}
            required
            name={cityControl}
          />
        </div>
        <div className="col-sm">
          <Field
            component={SelectListField}
            label="State"
            placeholder="State"
            ariaLabel="State"
            validate={required ? [requiredValidator] : []}
            options={usStatesOptions}
            searchable
            required
            resetValue={required ? undefined : ""}
            name={stateControl}
            theme={theme}
          />
        </div>
        <div className="col-sm">
          <Field
            component={TextField}
            label="Zipcode"
            placeholder="Zipcode"
            ariaLabel="Zipcode"
            validate={(required ? [requiredValidator] : []).concat(zipCode)}
            normalize={normalizeZip}
            inputMode="numeric"
            pattern="\d*"
            required
            name={zipControl}
          />
        </div>
      </div>
    </>
  );
};

AddressFieldGroup.propTypes = propTypes;
AddressFieldGroup.defaultProps = {
  theme: GBLI_THEME,
};

export default AddressFieldGroup;
