import { clone } from "lodash";

const values = {
  cancelPolicyNumber: "",
  cancelCreditCard: "",
  cancelZip: "",
  cancelAccept: false,
};

const refundCancelInitialFormValues = () => clone(values);

export default refundCancelInitialFormValues;
