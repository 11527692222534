import { ConnectedRouter } from "@jauntin/react-ui";
import deepmerge from "deepmerge";
import thankyouPageInitialState from "../Constants/thankyouPageInitialState";
import RouteParser from "../Helpers/RouteParser";
import {
  COMPLETED_ORDER,
  SET_DOWNLOADED,
  SET_INSURED_NAME,
  INCREMENT_COUNTER,
  SET_SHOW_TIMEOUT_MESSAGE,
} from "../Actions/actions";

const completedOrder = (state, payload) => {
  const updated = { ...state };
  updated.completedOrder.orderNumber = payload.orderNumber;
  updated.completedOrder.policyNumber = payload.policyNumber;
  updated.completedOrder.email = payload.email;
  updated.completedOrder.pdfLink = payload.pdfLink;
  return updated;
};

const setInsuredName = (state, payload) => {
  const updated = { ...state };
  updated.completedOrder.insuredName = payload;
  return updated;
};

const handleLocationChange = (state, { location }) => {
  const routeParser = RouteParser.create(location.pathname);
  const updated = { ...state };
  if (routeParser.isEventTypePage()) {
    return deepmerge(updated, thankyouPageInitialState());
  }
  return updated;
};

const setDownloaded = (state, payload) => {
  const updated = { ...state };
  updated.completedOrder.downloaded = payload;
  return updated;
};

const setShowTimeoutMessage = (state, payload) => {
  const updated = { ...state };
  updated.completedOrder.showTimeoutMessage = payload;
  return updated;
};

const incrementCounter = (state, payload) => {
  const updated = { ...state };
  updated.completedOrder.timeoutCounter = payload;
  return updated;
};

export default (state = thankyouPageInitialState(), action) => {
  if (action.type === COMPLETED_ORDER) {
    return { ...completedOrder(state, action.payload) };
  }
  if (action.type === SET_INSURED_NAME) {
    return { ...setInsuredName(state, action.payload) };
  }
  if (action.type === ConnectedRouter.LOCATION_CHANGE) {
    return { ...handleLocationChange(state, { ...action.payload }) };
  }
  if (action.type === SET_DOWNLOADED) {
    return { ...setDownloaded(state, action.payload) };
  }
  if (action.type === SET_SHOW_TIMEOUT_MESSAGE) {
    return { ...setShowTimeoutMessage(state, action.payload) };
  }
  if (action.type === INCREMENT_COUNTER) {
    return { ...incrementCounter(state, action.payload) };
  }
  return state;
};
