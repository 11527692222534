import styled from "styled-components";
import { CardNumberInput } from "@jauntin/react-ui";

const StyledCardNumberInput = styled(CardNumberInput)`
  input {
    ${({ theme }) => theme.components.Input?.customCss}
  }
`;

export default StyledCardNumberInput;
