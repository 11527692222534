import { isValidAddress } from "@gbli-events/common/src/Helpers/AddressTypes";
import { uniqBy } from "lodash";

export const areOptionalVenuesValid = (state) => {
  const { optionalVenues } = state.form.application.values;

  return optionalVenues.every((venue) => {
    const valid =
      !venue.selectedPlaceId ||
      (!venue.isBlocked &&
        isValidState(venue.selectedPlaceAddressComponents.state, state) &&
        isValidAddress(
          venue.selectedPlaceAddressComponents.address1,
          venue.selectedPlaceAddressComponents.city,
          venue.selectedPlaceAddressComponents.state || "",
          venue.selectedPlaceAddressComponents.country,
          venue.selectedPlaceAddressComponents.zip,
          venue.selectedPlaceAddressComponents.addressType
        ) &&
        isUniqueOptionalVenue(state, venue));

    return valid;
  });
};

export const isUniqueOptionalVenue = (state, venue) => {
  const { optionalVenues } = state.form.application.values;
  const { venue: primaryVenue } = state.formPage;

  if (!primaryVenue.selectedPlaceId || !venue.selectedPlaceId) return true;

  if (primaryVenue.selectedPlaceId === venue.selectedPlaceId) return false;

  return uniqBy(optionalVenues, "selectedPlaceId");
};

export const isValidState = (countryState, state) => {
  let isValidState = false;
  state.formPage.usStates.some((e) => {
    if (e.code === countryState && !e.blocked) {
      isValidState = true;
    }
    return e.code === countryState;
  });

  return isValidState;
};

export const getIsKnownVenue = (state) => {
  return !!state.form.application.values.venueCode;
};

export const isVenuePageValid = (state) => {
  const { isGettingCertHolders } = state.formPage.summary;

  return (
    getVenueStatesMatch(state) &&
    !isGettingCertHolders &&
    areOptionalVenuesValid(state) &&
    isMainVenueValid(state) &&
    isKentuckyStateValid(state)
  );
};

export const isMainVenueValid = (state) => {
  const venueState =
    state.formPage.venue.selectedPlaceAddressComponents.state || "";

  const addressParams = [
    state.formPage.venue.selectedPlaceAddressComponents.address1,
    state.formPage.venue.selectedPlaceAddressComponents.city,
    venueState,
    state.formPage.venue.selectedPlaceAddressComponents.country,
    state.formPage.venue.selectedPlaceAddressComponents.zip,
    state.formPage.venue.selectedPlaceAddressComponents.addressType,
  ];

  return (
    !!getIsKnownVenue(state) ||
    (state.formPage.venue?.validVenue &&
      !!state.formPage.venue.selectedPlaceId &&
      isValidAddress(...addressParams) &&
      isValidState(venueState, state))
  );
};

export const getMainVenue = (state) => state.formPage.venue;

export const getOptionalVenues = (state) =>
  state.form.application.values.optionalVenues;

export const getVenueStatesMatch = (state) => {
  const {
    selectedPlaceAddressComponents: { state: mainVenueState },
  } = getMainVenue(state);

  if (!mainVenueState) return true;

  return getOptionalVenues(state).every(
    ({ selectedPlaceAddressComponents: { state: optionalVenueState } }) =>
      !Boolean(optionalVenueState) || optionalVenueState === mainVenueState
  );
};

export const getVenueStateCannabisErrorMessage = (state) => {
  const {
    selectedPlaceAddressComponents: { state: mainVenueState },
    isCannabisPrevented,
  } = getMainVenue(state);
  const mainVenueStateName = getMainVenueStateName(state);
  const cannabisIncluded = state.form.application.values.cannabis === "1";
  const cannabisAllowed = state.formPage.usStates.find(
    (usState) => usState.code === mainVenueState
  )?.cannabisAllowed;

  if (mainVenueState && cannabisIncluded && !cannabisAllowed) {
    return `Cannabis coverage is not available in the venue state of ${mainVenueStateName}`;
  }

  return mainVenueState && cannabisIncluded && isCannabisPrevented
    ? "Cannabis coverage is not available for this venue."
    : "";
};

export const getMainVenueStateName = (state) => {
  const {
    selectedPlaceAddressComponents: { state: mainVenueState },
  } = getMainVenue(state);

  return state.formPage.usStates.find(
    (usState) => usState.code === mainVenueState
  )?.name;
};

export const getAvailableStateCodes = (state) =>
  state.formPage.usStates
    .filter((usState) => !usState.blocked)
    .map((usState) => usState.code);

export const getBlockedStateCodes = (state) =>
  state.formPage.usStates
    .filter((usState) => usState.blocked)
    .map((usState) => usState.code);

export const isKentuckyStateValid = (state) => {
  const venue = state.formPage.venue;
  const venueMunicipalityCode =
    state.form.application.values.venueMunicipalityCode;

  return Boolean(
    !venue.hasRegionalTax || (venue.hasRegionalTax && venueMunicipalityCode)
  );
};

export const getVenueState = (state) => {
  return state.formPage.venue.selectedPlaceAddressComponents.state;
};
