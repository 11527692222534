import { LOAD_QUOTE } from "src/Actions/quote";
import { formName } from "src/Constants/constants";

export default (state, action) => {
  switch (action?.type) {
    case LOAD_QUOTE:
      return {
        ...state,
        formPage: {
          ...state.formPage,
          ...action.payload.formPage,
        },
        form: {
          ...state.form,
          [formName]: {
            ...state.form[formName],
            ...action.payload.form[formName],
            values: {
              ...state.form[formName].values,
              ...action.payload.form[formName].values,
            },
          },
        },
      };
    default:
      return state;
  }
};
