// Helper functions that can identify or filter event types

// Event types that have icons for
const iconEventTypes = (eventTypes) =>
  eventTypes.filter((m) => m.selectionIcon);

// Event types that are in the dropdown list (don't have icons)
const listEventTypes = (eventTypes) =>
  eventTypes.filter((m) => !m.selectionIcon);

// From all eventTypes, get the one with id
const getById = (id, eventTypes) => eventTypes.find((m) => m.identifier === id);

export { iconEventTypes, listEventTypes, getById };
