import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getInsuranceCompanies } from "src/Actions/insuranceCompany";

export const useInsuranceCompanies = () => {
  const { insuranceCompanies, insuranceCompaniesLoading } = useSelector(
    (state) => state.formPage
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!insuranceCompanies.length && !insuranceCompaniesLoading) {
      dispatch(getInsuranceCompanies());
    }
  }, [insuranceCompanies, insuranceCompaniesLoading, dispatch]);

  return insuranceCompanies;
};
