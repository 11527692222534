import React, { useState } from "react";
import Select from "react-select";
import { getSelectStyles } from "@gbli-events/common/src/Helpers/ReactSelectStyle";
import { SPECIAL_INSURANCE_THEME } from "@gbli-events/common/src/Constants/themes/specialInsuranceTheme";

const EventTypeSelect = ({ input, options, meta }) => {
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);

  return (
    <>
      <p
        className={`text-center ${
          meta.touched && meta.error ? "text-danger" : "mb-3"
        }`}
      >
        Select from either the dropdown or from the most common event list
        below.
      </p>
      {meta.touched && meta.error && (
        <div className="form-error text-center">{meta.error}</div>
      )}
      <div className="event-selection__select-menu-container">
        <Select
          {...input}
          styles={getSelectStyles(SPECIAL_INSURANCE_THEME)}
          options={options}
          onMenuOpen={() => setIsSelectMenuOpen(true)}
          onMenuClose={() => setIsSelectMenuOpen(false)}
          placeholder="Select other event types here"
          onChange={(event) => {
            if (input.onChange && event !== null) {
              input.onChange(event);
            } else if (input.onChange) {
              input.onChange(null);
            }
          }}
          value={input.value}
          onBlur={() => input.onBlur()}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => (
              <i
                style={{ color: "#333" }}
                className={`fa fa-chevron-${
                  isSelectMenuOpen ? "up" : "down"
                } mr-3 ${input.value ? "d-none" : ""}`}
              ></i>
            ),
            NoOptionsMessage: () => (
              <div className="text-center">No event types found.</div>
            ),
          }}
          isClearable
        />
      </div>
    </>
  );
};

export default EventTypeSelect;
