import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import PhoneField from "./PhoneField";
import EmailField from "./EmailField";
import { required, phoneNumber, email } from "../../Helpers/validators";
import { normalizePhone, normalizeEmail } from "../../Helpers/normalizers";

const propTypes = {
  phoneControl: PropTypes.string.isRequired,
  emailControl: PropTypes.string.isRequired,
};

//TODO: Paramaratize required into a prop
export const EmailPhoneFieldGroup = ({ phoneControl, emailControl }) => {
  return (
    <div className="form-group">
      <div className="form-row">
        <div className="col-sm">
          <Field
            component={PhoneField}
            validate={[required, phoneNumber]}
            name={phoneControl}
            type="text"
            normalize={normalizePhone}
            required
          />
        </div>
        <div className="col-sm">
          <Field
            component={EmailField}
            validate={[required, email]}
            name={emailControl}
            normalize={normalizeEmail}
            required
          />
        </div>
      </div>
    </div>
  );
};

EmailPhoneFieldGroup.propTypes = propTypes;

export default EmailPhoneFieldGroup;
