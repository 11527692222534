import InsuranceCompanyService from "@gbli-events/common/src/Services/InsuranceCompanyService";
import API from "src/Helpers/API";

export const FETCH_INSURANCE_COMPANIES = "FETCH_INSURANCE_COMPANIES";
export const fetchInsuranceCompanies = () => {
  return { type: FETCH_INSURANCE_COMPANIES };
};

export const FETCH_INSURANCE_COMPANIES_SUCCESS =
  "FETCH_INSURANCE_COMPANIES_SUCCESS";
export const fetchInsuranceCompaniesSuccess = (values) => {
  return { type: FETCH_INSURANCE_COMPANIES_SUCCESS, payload: values };
};

export const FETCH_INSURANCE_COMPANIES_ERROR =
  "FETCH_INSURANCE_COMPANIES_ERROR";
export const fetchInsuranceCompaniesError = (error) => {
  return { type: FETCH_INSURANCE_COMPANIES_ERROR, payload: error };
};

export const getInsuranceCompanies = () => {
  return (dispatch) => {
    dispatch(fetchInsuranceCompanies());
    new InsuranceCompanyService(new API())
      .getInsuranceCompanies()
      .then((response) => {
        dispatch(fetchInsuranceCompaniesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchInsuranceCompaniesError(err.response.data));
      });
  };
};
