import React from "react";
import { useSelector } from "react-redux";
import {
  ENTITY_PERSON,
  ENTITY_COMPANY,
} from "@gbli-events/common/src/Constants/entityTypes";

const InsuranceContactSummaryTable = () => {
  const {
    contactSame,
    contactPhone,
    contactEmail,
    contactFirstName,
    contactLastName,
    contactRenter,
    contactCompany,
    contactCompanyType,
    insuredFirstName,
    insuredLastName,
    insuredRenter,
    insuredCompany,
    insuredCompanyType,
  } = useSelector((state) => state.form.application.values);

  const isContactSame = contactSame === "1";

  return (
    <div className="summary-table summary-table--insurance-contact">
      <h3 className="summary-table__heading text-left mb-3 font-30">
        Contact:
      </h3>
      <div className="row mb-3">
        {insuredRenter === ENTITY_PERSON && (
          <>
            <div className="col-12 col-md-6">
              <div className="summary-table__item">
                <div className="summary-table__item-label">First Name:</div>
                <div className="summary-table__item-value">
                  {isContactSame ? insuredFirstName : contactFirstName}
                </div>
              </div>
              <div className="summary-table__item">
                <div className="summary-table__item-label">Last Name:</div>
                <div className="summary-table__item-value">
                  {isContactSame ? insuredLastName : contactLastName}
                </div>
              </div>
              <div className="summary-table__item">
                <div className="summary-table__item-label">Phone:</div>
                <div className="summary-table__item-value">{contactPhone}</div>
              </div>
              <div className="summary-table__item">
                <div className="summary-table__item-label">Email:</div>
                <div className="summary-table__item-value">{contactEmail}</div>
              </div>
            </div>
          </>
        )}
        {insuredRenter === ENTITY_COMPANY && (
          <div className="col-12 col-md-6">
            <div className="summary-table__item">
              <div className="summary-table__item-label">First Name:</div>
              <div className="summary-table__item-value">
                {contactFirstName}
              </div>
            </div>
            <div className="summary-table__item">
              <div className="summary-table__item-label">Last Name:</div>
              <div className="summary-table__item-value">{contactLastName}</div>
            </div>
            <div className="summary-table__item">
              <div className="summary-table__item-label">Phone:</div>
              <div className="summary-table__item-value">{contactPhone}</div>
            </div>
            <div className="summary-table__item">
              <div className="summary-table__item-label">Email:</div>
              <div className="summary-table__item-value">{contactEmail}</div>
            </div>
          </div>
        )}
        {isContactSame && insuredRenter === ENTITY_COMPANY && (
          <>
            <div className="col-12 col-md-6 summary-table__item">
              <div className="summary-table__item-label">
                Company/Organization Name:
              </div>
              <div className="summary-table__item-value">{insuredCompany}</div>
            </div>
            <div className="col-12 col-md-6 summary-table__item">
              <div className="summary-table__item-label">Type of Company:</div>
              <div className="summary-table__item-value">
                {insuredCompanyType}
              </div>
            </div>
          </>
        )}
        {!isContactSame && contactRenter === ENTITY_COMPANY && (
          <>
            <div className="col-12 col-md-6 summary-table__item">
              <div className="summary-table__item-label">
                Company/Organization Name:
              </div>
              <div className="summary-table__item-value">{contactCompany}</div>
            </div>
            <div className="col-12 col-md-6 summary-table__item">
              <div className="summary-table__item-label">Type of Company:</div>
              <div className="summary-table__item-value">
                {contactCompanyType}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InsuranceContactSummaryTable;
