import styled from "styled-components";
import { ButtonStyles } from "../Components/Themed/Button";
import { AsyncButton as CommonAsyncButton } from "@jauntin/react-ui";

const AsyncButton = styled(CommonAsyncButton)((props) => ButtonStyles(props));

AsyncButton.defaultProps = {
  variant: "primary",
};

export default AsyncButton;
