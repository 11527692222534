import styled from "styled-components";
import { getMediaBreakpointDown } from "../../Helpers/themes";
import { getThemeColor } from "../../Helpers/themes";

const Alert = styled.div`
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  display: flex;
  vertical-align: middle;
  color: ${({ theme, variant }) => getThemeColor(theme, variant)};
  background-color: ${({ theme, variant }) =>
    getThemeColor(theme, variant, "light")};
  border: 1px solid transparent;
  border-color: ${({ theme, variant }) => getThemeColor(theme, variant)};
  border-radius: 3px;
  ${({ theme }) => getMediaBreakpointDown(theme.breakpoints.xs)} {
    padding: 0.75rem;
  }
  .alert__icon {
    font-size: 14px;
    min-width: 14px;
    display: block;
    margin-top: 5px;
    margin-right: 0.5rem;

    ${({ theme }) => getMediaBreakpointDown(theme.breakpoints.sm)} {
      margin-right: 1rem;
    }
  }

  ${({ theme }) => theme.components.Alert?.customCss}
`;

export default Alert;
