function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const stateA = a.name.toUpperCase();
  const stateB = b.name.toUpperCase();

  let comparison = 0;
  if (stateA > stateB) {
    comparison = 1;
  } else if (stateA < stateB) {
    comparison = -1;
  }
  return comparison;
}

export const venueStateOptions = (usStates) => {
  usStates.sort(compare);
  const stateOptions = usStates.map((usState) => ({
    value: usState.code,
    label: usState.name,
    blocked: usState.blocked,
  }));
  return stateOptions;
};

export const createStateOptions = (usStates) => {
  usStates.sort(compare);
  const stateOptions = usStates.reduce((result, { code, name }) => {
    result = result.concat({
      value: code,
      label: name,
    });
    return result;
  }, []);

  return stateOptions;
};
