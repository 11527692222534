import API from "../../../frontend/src/Helpers/API";
import ThemesService from "../Services/ThemesService";
import { errorResponse } from "src/Actions/actions";

export const SET_THEME = "SET_THEME";

export const setTheme = (theme) => ({
  type: SET_THEME,
  payload: theme,
});

export const FETCH_THEME = "SET_THEME";

export const fetchTheme = (themeSlug) => {
  return (dispatch) => {
    new ThemesService(new API())
      .getTheme(themeSlug)
      .then(
        (theme) => {
          dispatch(setTheme(theme));
        },
        (defaultTheme) => {
          dispatch(setTheme(defaultTheme));
        }
      )
      .catch((err) => dispatch(errorResponse(err)));
  };
};
