import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { getMainVenue, getOptionalVenues } from "src/Selectors/Venue";

const VenueSummaryTable = () => {
  const mainVenue = useSelector(getMainVenue);

  const optionalVenues = useSelector(getOptionalVenues);
  return (
    <div className="summary-table summary-table--venue-details">
      <h3 className="summary-table__heading text-left mb-3 font-30">
        Venue Details:
      </h3>
      <div className="row mb-3">
        <div className="col-12 col-md-6 summary-table__item">
          <div className="summary-table__item-label">Venue Name & Address:</div>
          <div className="summary-table__item-value">
            <div>{mainVenue.selectedPlaceAddressComponents.companyName}</div>
            <div>{mainVenue.selectedPlaceAddressComponents.address1}</div>
            {mainVenue.selectedPlaceAddressComponents.address2 && (
              <div>{mainVenue.selectedPlaceAddressComponents.address2}</div>
            )}
            <div>
              {mainVenue.selectedPlaceAddressComponents.city},{" "}
              {mainVenue.selectedPlaceAddressComponents.state}
            </div>
            <div>{mainVenue.selectedPlaceAddressComponents.zip}</div>
          </div>
        </div>
        {optionalVenues.map(
          ({ selectedPlaceId, selectedPlaceAddressComponents }) =>
            selectedPlaceId ? (
              <div
                key={selectedPlaceId}
                className="col-12 col-md-6 summary-table__item"
              >
                <div className="summary-table__item-label">
                  2nd Venue (optional):
                </div>
                <div className="summary-table__item-value">
                  <div>{selectedPlaceAddressComponents.companyName}</div>
                  <div>{selectedPlaceAddressComponents.address1}</div>
                  {selectedPlaceAddressComponents.address2 && (
                    <div>{selectedPlaceAddressComponents.address2}</div>
                  )}
                  <div>
                    {selectedPlaceAddressComponents.city},{" "}
                    {selectedPlaceAddressComponents.state}
                  </div>
                  <div>{selectedPlaceAddressComponents.zip}</div>
                </div>
              </div>
            ) : (
              <Fragment key={selectedPlaceId}></Fragment>
            )
        )}
      </div>
    </div>
  );
};

export default VenueSummaryTable;
