import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@gbli-events/common/src/Components/Themed/Button";
import { getFormChildPath } from "src/Helpers/URLParser";
import NavBackButton from "./NavBackButton";
import { resetApplicationForm } from "src/Actions/actions";
import { getIsEmbed } from "src/Selectors/Shared";
import { VENUE_SELECTION_URL } from "src/Constants/constants";

const FormStepNavigation = () => {
  const dispatch = useDispatch();
  const isEmbed = useSelector(getIsEmbed);
  const history = useHistory();

  return (
    <div className="d-flex form-step-nav align-items-center">
      <NavBackButton />
      {isEmbed && (
        <Button
          type="button"
          className="form-step-nav__start-over ml-auto"
          variant="secondary"
          outline
          onClick={() => {
            history.push(getFormChildPath(VENUE_SELECTION_URL, isEmbed));
            dispatch(resetApplicationForm());
          }}
        >
          Start Over
        </Button>
      )}
    </div>
  );
};

export default FormStepNavigation;
