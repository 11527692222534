import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { ConnectedRouter } from "@jauntin/react-ui";
import formPage from "./formPageReducers";
import formReducers from "./formReducers";
import thankyouPage from "./thankyouPageReducers";
import refundCancelPage from "./refundCancelPageReducers";
import samplePolicyPage from "./samplePolicyPageReducers";
import savedQuoteReducer from "./savedQuoteReducer";

// Reducers are connected router, application-specific reduxer (formPage), and redux form
export default (history) => {
  const rootReducer = combineReducers({
    router: ConnectedRouter.routerReducer,
    formPage,
    thankyouPage,
    refundCancelPage,
    samplePolicyPage,
    form: formReducer.plugin(formReducers),
  });

  return (state, action) =>
    savedQuoteReducer(rootReducer(state, action), action);
};
