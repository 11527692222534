import { formValueSelector } from "redux-form";
import CurrencyHelper from "src/Helpers/CurrencyHelper";
import initialFormValues from "src/Constants/initialFormValues";
import { formName } from "src/Constants/constants";
import { DAMAGE_TO_RENTED_PREMISES } from "@gbli-events/common/src/Constants/additionalCoverages";
import { MEDICAL_LIMIT } from "@gbli-events/common/src/Constants/limits";
import { venuesWithExcludedHostLiquor } from "@gbli-events/common/src/Constants/venues";
import {
  getAdvertisingAmount,
  getPCOAmount,
  getMedicalAmount,
  getHostLiquorAmount,
} from "./Quote";
import {
  getPCODescription,
  getPersonalAdLimitDescription,
  getMedicalDescription,
  getDmgToRentedPremisesDescription,
  getHostLiquorDescription,
  getTerrorismDescription,
  getPrimaryNoncontributoryEndorsementDescription,
  getWaiverOfSubrogationDescription,
} from "@gbli-events/common/src/Selectors/Limits";
import { getTouchedFieldError } from "./Form";

const formValues = formValueSelector(formName);

const isDefaultGl = (state) => {
  const {
    generalLiabilityCoverage: { occurrence: defaultOcc, aggregate: defaultAgg },
  } = initialFormValues();
  const { occurrence, aggregate } =
    state.form.application.values.generalLiabilityCoverage;
  return occurrence === defaultOcc && aggregate === defaultAgg;
};

export const getIsLimitsPageValid = (state) => {
  const {
    ACDamageToRentedProperty,
    ACTerrorism,
    terrorismCoverageAgreement,
    generalLiabilityCoverage,
  } = state.form.application.values;
  const { occurrence } = generalLiabilityCoverage;

  return !!(
    occurrence &&
    ACDamageToRentedProperty &&
    ACTerrorism &&
    terrorismCoverageAgreement
  );
};

export const getVenueGlLimits = (state) => state.formPage.basicCoverage.options;

export const getOccurrenceGl = (state) =>
  formValues(state, "generalLiabilityCoverage")?.occurrence || 0;

export const getGLItem = (state) => {
  const { occurrence, aggregate } =
    state.form.application.values.generalLiabilityCoverage;

  const { quote } = state.formPage.quote;

  return {
    coverageDescription: isDefaultGl(state)
      ? "TBD"
      : `${new CurrencyHelper(occurrence).dollars()}/${new CurrencyHelper(
          aggregate
        ).dollars()}`,
    limitName: "General Liability Coverage (Occurrence/Aggregate)",
    priceDescription:
      quote?.glTotal?.amount &&
      new CurrencyHelper(quote.glTotal.amount).convertFromInt().formatDollars(),
  };
};

export const getPcoItem = (state) => {
  const { cannabis } = state.form.application.values;

  const { occurrence } = state.form.application.values.generalLiabilityCoverage;

  return getPCODescription({
    isCannabisIncluded: cannabis === "1",
    isDefaultGl: isDefaultGl(state),
    PCOAmount: getPCOAmount(state),
    occurrence,
  });
};

export const getPersonalAdLimitItem = (state) => {
  const { occurrence } = state.form.application.values.generalLiabilityCoverage;

  return getPersonalAdLimitDescription({
    isDefaultGl: isDefaultGl(state),
    advertisingAmount: getAdvertisingAmount(state),
    occurrence,
  });
};

export const getDmgToRentedPremisesItem = (state) => {
  const { ACDamageToRentedProperty } = state.form.application.values;
  const { ACDamageToRentedProperty: defaultValue } = initialFormValues();

  const coverage = state.formPage.quote.quote?.additionalCoverages?.find(
    (coverage) => coverage.type === DAMAGE_TO_RENTED_PREMISES
  )?.amount?.amount;

  return getDmgToRentedPremisesDescription({
    isCoverageUnknown: ACDamageToRentedProperty === defaultValue,
    coverageAmount: coverage,
    coverageLimit: ACDamageToRentedProperty,
  });
};

export const getMedicalItem = (state) => {
  return getMedicalDescription({
    isDefaultGl: isDefaultGl(state),
    medicalLimit: MEDICAL_LIMIT,
    medicalAmount: getMedicalAmount(state),
  });
};

export const getHostLiquorItem = (state) => {
  const venueState = state.formPage.venue.selectedPlaceAddressComponents.state;
  const { eventDailyGuests } = state.form.application.values;

  return getHostLiquorDescription({
    hostLiquorExcluded: venuesWithExcludedHostLiquor.includes(venueState),
    eventDailyGuestsUnknown:
      eventDailyGuests === initialFormValues().eventDailyGuests,
    hostLiquorAmount: getHostLiquorAmount(state),
  });
};

export const getTerrorismItem = (state) => {
  const { ACTerrorism } = state.form.application.values;
  const defaultTerrorism = ACTerrorism === initialFormValues().ACTerrorism;

  const terrorismAmount = state.formPage.quote.quote?.additionalCoverages?.find(
    (coverage) => coverage.type === "terrorism"
  )?.amount?.amount;

  return getTerrorismDescription({
    isPristine: defaultTerrorism,
    isSelected: ACTerrorism === "1",
    terrorismAmount,
    terrorismRate: state.formPage.quote.quote?.terrorismRate?.amount,
  });
};

export const checkIsInAdmittedState = (state) => {
  const usStates = state.formPage.usStates;
  const currentVenueState =
    state.formPage.venue.selectedPlaceAddressComponents.state || "";

  return currentVenueState.length === 0
    ? false
    : !usStates.find((usState) => usState.code === currentVenueState)
        ?.cannabisAllowed;
};

export const getPrimaryNoncontributoryEndorsementItem = () => {
  return getPrimaryNoncontributoryEndorsementDescription();
};

export const getWaiverOfSubrogationItem = (state) => {
  const ACWaiverOfTransferRights =
    state.form.application.values.ACWaiverOfTransferRights;
  const isInAdmittedState = checkIsInAdmittedState(state);

  return getWaiverOfSubrogationDescription(
    ACWaiverOfTransferRights,
    isInAdmittedState
  );
};

export const limitsHasErrors = (state) =>
  getTouchedFieldError("ACTerrorism")(state) ||
  getTouchedFieldError("terrorismCoverageAgreement")(state) ||
  getTouchedFieldError("ACDamageToRentedProperty")(state) ||
  getTouchedFieldError("generalLiabilityCoverage")(state);
