const additionalInsuredVenueAddressType = "venue_address";
const additionalInsuredFacilityType = "facility";
const additionalInsuredVenueType = "venue";

const additionalInsuredTypes = [
  additionalInsuredVenueAddressType,
  additionalInsuredFacilityType,
  additionalInsuredVenueType,
];

export {
  additionalInsuredVenueAddressType,
  additionalInsuredFacilityType,
  additionalInsuredVenueType,
  additionalInsuredTypes,
};
