import {
  addYears,
  addDays,
  subDays,
  isWithinInterval,
  startOfDay,
} from "date-fns";

const firstValidDay = (today) => addDays(today, 1);
const lastValidDay = (today) => addDays(today, 548);

const dayIsInOverallValidRange = (day, start) => {
  const dayMidnight = startOfDay(day);
  const startMidnight = startOfDay(start);

  return isWithinInterval(dayMidnight, {
    start: firstValidDay(startMidnight),
    end: lastValidDay(startMidnight),
  });
};

const dayIsValidForStartAndEndDate = (day, start, end) => {
  if (!start) {
    return true;
  }

  let valid = isWithinInterval(day, { start, end: addDays(start, 2) });
  if (end) {
    valid = valid || isWithinInterval(day, { start: subDays(end, 2), end });
  }
  return valid;
};

const numberedMonths = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const getFutureYears = (numOfYears) => {
  let years = [];

  for (let i = 0; i < numOfYears; i++) {
    let year = addYears(new Date(), i);
    years = years.concat(year);
  }

  return years;
};

export {
  firstValidDay,
  lastValidDay,
  dayIsInOverallValidRange,
  dayIsValidForStartAndEndDate,
  numberedMonths,
  getFutureYears,
};
