class PaymentService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getFlexKey() {
    return this.api.get({
      location: "/payment/generateKey",
      params: {
        encryptionType: "None",
      },
    });
  }

  createCoverage(paymentInfo) {
    return this.api.post({
      location: "/coverage",
      body: JSON.stringify(paymentInfo),
    });
  }

  getPolicyDocs(url) {
    return this.api.getDownload({
      location: url,
      config: {
        responseType: "blob",
      },
    });
  }

  getDocumentsStatus(coverageOrder) {
    return this.api.get({
      location: "/policyDocumentsStatus",
      params: { coverageOrder },
    });
  }
}

export default PaymentService;
