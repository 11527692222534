import React from "react";
import { useSelector } from "react-redux";
import {
  getGLItem,
  getPcoItem,
  getPersonalAdLimitItem,
  getDmgToRentedPremisesItem,
  getMedicalItem,
  getHostLiquorItem,
  getTerrorismItem,
  getPrimaryNoncontributoryEndorsementItem,
  getWaiverOfSubrogationItem,
} from "../Selectors/Limits";
import { getTaxItems } from "../Selectors/Taxes";

const PriceSummaryTable = () => {
  const limitItems = useSelector((state) => [
    getGLItem(state),
    getPcoItem(state),
    getPersonalAdLimitItem(state),
    getDmgToRentedPremisesItem(state),
    getMedicalItem(state),
    getHostLiquorItem(state),
    getPrimaryNoncontributoryEndorsementItem(),
    getWaiverOfSubrogationItem(state),
    getTerrorismItem(state),
  ]);

  const taxItems = useSelector(getTaxItems);

  return (
    <div className="row price-summary-table summary-table">
      <div className="col-12 summary-table__item summary-table__item--md-75-25">
        <div className="summary-table__item-label">
          <h3 className="text-left mb-3 font-30">Premium Details:</h3>
        </div>
        <div className="summary-table__item-value d-none d-md-block">
          <h3 className="text-left mb-0 font-30">Premium:</h3>
        </div>
      </div>
      {limitItems.map((item) => (
        <div
          key={item.limitName}
          className="col-12 summary-table__item summary-table__item--md-75-25"
        >
          <div className="summary-table__item-label">
            {`${item.limitName}${
              item.coverageDescription ? `: ${item.coverageDescription}` : ""
            }`}
          </div>
          <div className="summary-table__item-value">
            {item.priceDescription}
          </div>
          {item.additionalInfo && (
            <div className="col-12 summary-table__item-additional-info">
              {item.additionalInfo}
            </div>
          )}
        </div>
      ))}
      {taxItems.map((item) => (
        <div
          key={item.label}
          className="col-12 summary-table__item summary-table__item--md-75-25"
        >
          <div className="summary-table__item-label">{item.label}</div>
          <div className="summary-table__item-value">{item.value}</div>
        </div>
      ))}
    </div>
  );
};

export default PriceSummaryTable;
