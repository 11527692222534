import PropTypes from "prop-types";

const manualAddressPropType = PropTypes.shape({
  name: PropTypes.string,
  address1: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  country: PropTypes.string,
});

export default manualAddressPropType;
