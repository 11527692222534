import { saveAdditionalInsuredFormName } from "src/Constants/constants";

export const getAdditionalInsuredPayload = (state) => {
  const formData = state.form[saveAdditionalInsuredFormName]?.values;

  return {
    companyName: formData?.companyName || "",
    address1: formData?.address1 || "",
    address2: formData?.address2 || "",
    city: formData?.city || "",
    state: formData?.state || "",
    zip: formData?.zip || "",
    country: "US",
    uniqueKey: formData?.uniqueKey || "",
  };
};
