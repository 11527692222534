class CyberSourceService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  tokenizeCard(creditCard, flexKeyId) {
    const tokenizeCard = {
      keyId: flexKeyId,
      cardInfo: creditCard,
    };
    return this.api.post({
      location: "/tokens",
      body: JSON.stringify(tokenizeCard),
    });
  }
}

export default CyberSourceService;
