import { reset, change, untouch, touch } from "redux-form";
import cc from "card-validator";
import {
  formName,
  VENUE_CODE_PARAM,
  PRODUCER_CODE_PARAM,
  FACILITY_CODE_PARAM,
} from "../Constants/constants";
import {
  extractMonthYear,
  normalizeYear,
} from "@gbli-events/common/src/Helpers/normalizers";
import API from "../Helpers/API";
import CyberSourceService from "../Helpers/CyberSourceService";
import PaymentService from "../Helpers/PaymentService";
import FacilityService from "../Helpers/FacilityService";
import VenuePresetsService from "../Helpers/VenuePresetsService";
import { cyberSourceIdFromCardTypeName } from "../Helpers/CyberSourceCardTypes";
import StateTaxService from "../Helpers/StateTaxService";
import initialFormValues from "../Constants/initialFormValues";
import RefundCancelService from "../Helpers/RefundCancelService";
import SamplePolicyService from "../Helpers/SamplePolicyService";
import RouteParser from "../Helpers/RouteParser";
import ProducerService from "src/Helpers/ProducerService";
import { isValidState } from "src/Selectors/Venue";
import { setHasLookedUpTax, setIsKentuckyCity } from "./venue";
import {
  additionalInsuredFacilityType,
  additionalInsuredVenueAddressType,
} from "../Constants/additionalInsuredTypes";

export const EVENT_TYPE_FORM = "EVENT_TYPE_FORM";
export const EVENT_SELECT_FORM = "EVENT_SELECT_FORM";
export const EVENT_SELECT_SECONDARY_FORM = "EVENT_SELECT_SECONDARY_FORM";
export const VENUE_FORM = "VENUE_FORM";
export const BASIC_COVERAGE_FORM = "BASIC_COVERAGE_FORM";
export const EVENT_FORM = "EVENT_FORM";
export const ADDITIONAL_COVERAGES_FORM = "ADDITIONAL_COVERAGES_FORM";
export const INSURED_FORM = "INSURED_FORM";
export const INSURANCE_CONTACT_FORM = "INSURANCE_CONTACT_FORM";
export const PAYMENT_INFO_FORM = "PAYMENT_INFO_FORM";

export const RESET_APPLICATION_FORM = "RESET_APPLICATION_FORM";
export const resetApplicationForm = () => ({ type: RESET_APPLICATION_FORM });

// The user has clicked the 'Edit' button on a form part that
// was previously confirmed
// form is the name of the form part e.g. EVENT_FORM
export const EDIT_FORM = "EDIT_FORM";
export const editForm = (form) => ({
  type: EDIT_FORM,
  form,
});

// The user has clicked the confirm button on a form part
// form is the name of the form page e.g. EVENT_FORM
export const CONFIRM_FORM = "CONFIRM_FORM";
export const confirmForm = (form) => ({
  type: CONFIRM_FORM,
  form,
});

// The user has clicked back on a form
export const BACK_FORM = "BACK_FORM";
export const backForm = (form) => ({
  type: BACK_FORM,
  form,
});
// The user has clicked back on a form
// Resets the redux form and also dispatches BACK_FORM
export function backReset(form) {
  return (dispatch) => {
    dispatch(reset(formName));
    dispatch(backForm(form));
    return Promise.resolve();
  };
}

// The user has selected a place from the venue Google Autocomplete part
export const SELECT_PLACE = "SELECT_PLACE";
export const selectPlace = (
  placeId,
  address,
  addressComponents,
  utcOffset,
  knownVenueData
) => ({
  type: SELECT_PLACE,
  placeId,
  address,
  addressComponents,
  utcOffset,
  knownVenueData,
});

// The user has clicked the toggle button on the summary part
export const SUMMARY_TOGGLE = "SUMMARY_TOGGLE";
export const toggleSummary = () => {
  return { type: SUMMARY_TOGGLE };
};

// The user has swiped up or down to show/hide summary
export const SUMMARY_MOBILE_TOGGLE = "SUMMARY_MOBILE_TOGGLE";
export const toggleSummaryMobile = (direction) => {
  return { type: SUMMARY_MOBILE_TOGGLE, show: direction };
};

// The user has clicked the toggle button on the summary part
export const SUMMARY_HEIGHT = "SUMMARY_HEIGHT";
export const summaryHeight = (height) => {
  return { type: SUMMARY_HEIGHT, sHeight: height };
};

// The screen size has changed
export const SCREEN_SIZE = "SCREEN_SIZE";
export const screenResize = (width) => {
  return { type: SCREEN_SIZE, screenWidth: width };
};

export const UPDATE_CERT_HOLDERS = "UPDATE_CERT_HOLDERS";
export const updateCertHolders = (value) => {
  return { type: UPDATE_CERT_HOLDERS, payload: value };
};

export const CREDITCARD_TOKEN_RESPONSE = "CREDITCARD_TOKEN_RESPONSE";
export const creditCardTokenResponse = (values, form) => {
  return { type: CREDITCARD_TOKEN_RESPONSE, payload: values, form };
};

// An error has been returned from the server
export const API_ERROR = "API_ERROR";
export const errorResponse = (err, form) => {
  return { type: API_ERROR, error: err, form };
};

// Update venue isBlocked status
export const UPDATE_BLOCKED_VENUE = "UPDATE_BLOCKED_VENUE";
export const updateBlockedVenue = (value) => {
  return { type: UPDATE_BLOCKED_VENUE, payload: value };
};

// Update genericServerError status
export const UPDATE_GENERIC_SERVER_ERROR = "UPDATE_GENERIC_SERVER_ERROR";
export const updateGenericServerError = (value) => {
  return { type: UPDATE_GENERIC_SERVER_ERROR, payload: value };
};

// An order has successfully completed
export const COMPLETED_ORDER = "COMPLETED_ORDER";
export const completedOrder = (values) => {
  return { type: COMPLETED_ORDER, payload: values };
};

export const SET_INSURED_NAME = "SET_INSURED_NAME";
export const setInsuredName = (values) => {
  return { type: SET_INSURED_NAME, payload: values };
};

export const START_LOADING = "START_LOADING";
export const startLoading = (form) => ({
  type: START_LOADING,
  form,
});

export const END_LOADING = "END_LOADING";
export const endLoading = (form) => ({
  type: END_LOADING,
  form,
});

// EventTypes have been received from the server
export const SET_EVENT_TYPES = "SET_EVENT_TYPES";
export const setEventTypes = (data) => {
  return { type: SET_EVENT_TYPES, payload: data };
};
export const FETCH_EVENT_TYPES_FAILURE = "FETCH_EVENT_TYPES_FAILURE";
export const fetchEventTypesFailure = () => {
  return { type: FETCH_EVENT_TYPES_FAILURE };
};

// List of US States have been received from the server
export const SET_US_STATES = "SET_US_STATES";
export const setUSStates = (data) => {
  return { type: SET_US_STATES, payload: data };
};

export const SET_AUTH_USER = "SET_AUTH_USER";
export const setAuthUser = (data) => {
  return { type: SET_AUTH_USER, payload: data };
};

export const FETCH_US_STATES_FAILURE = "FETCH_US_STATES_FAILURE";
export const fetchUsStatesFailure = () => {
  return { type: FETCH_US_STATES_FAILURE };
};

export const SET_FETCHING_VENUE_PRESETS = "SET_FETCHING_VENUE_PRESETS";
export const setFetchingVenuePresets = (data) => {
  return { type: SET_FETCHING_VENUE_PRESETS, payload: data };
};

export const SET_VENUE_PRESETS = "SET_VENUE_PRESETS";
export const setVenuePresets = (data) => {
  return { type: SET_VENUE_PRESETS, payload: data };
};

export const SET_VALID_VENUE_ADDRESS_AND_STATE =
  "SET_VALID_VENUE_ADDRESS_AND_STATE";
export const setValidVenueAddressAndState = (data) => {
  return { type: SET_VALID_VENUE_ADDRESS_AND_STATE, payload: data };
};

export const UPDATE_MANUAL_ADDRESS = "UPDATE_MANUAL_ADDRESS";
export const updateManualAddress = (address) => {
  return { type: UPDATE_MANUAL_ADDRESS, payload: address };
};

export const UPDATE_MANUAL_ADDRESS_CITY = "UPDATE_MANUAL_ADDRESS_CITY";
export const updateManualAddressCity = (city) => {
  return { type: UPDATE_MANUAL_ADDRESS_CITY, payload: city };
};

export const SET_SELECTED_VENUE_CODE = "SET_SELECTED_VENUE_CODE";
export const setSelectedVenueCode = (data) => {
  return { type: SET_SELECTED_VENUE_CODE, payload: data };
};

export const SET_ADDITIONAL_COVERAGES_PRESETS =
  "SET_ADDITIONAL_COVERAGES_PRESETS";
export const setAdditionalCoveragesPresets = (data) => {
  return { type: SET_ADDITIONAL_COVERAGES_PRESETS, payload: data };
};

export const DEFAULT_BASIC_COVERAGE_OPTIONS = "DEFAULT_BASIC_COVERAGE_OPTIONS";
export const defaultBasicCoverageOptions = () => {
  return { type: DEFAULT_BASIC_COVERAGE_OPTIONS };
};

export const DEFAULT_ADDITIONAL_COVERAGES_STATUS =
  "DEFAULT_ADDITIONAL_COVERAGES_STATUS";
export const defaultAdditionalCoverageStatus = () => {
  return { type: DEFAULT_ADDITIONAL_COVERAGES_STATUS };
};

export const DEFAULT_ADDITIONAL_COVERAGES_FORM =
  "DEFAULT_ADDITIONAL_COVERAGES_FORM";
export const defaultAdditionalCoveragesForm = () => {
  return { type: DEFAULT_ADDITIONAL_COVERAGES_FORM };
};

export const SET_TAX_REGIONS = "SET_TAX_REGIONS";
export const setTaxRegions = (hasRegionalTax, taxRegions) => {
  return { type: SET_TAX_REGIONS, payload: { hasRegionalTax, taxRegions } };
};

export const VENUE_CODE_REFERRAL_OVERRIDE = "VENUE_CODE_REFERRAL_OVERRIDE";
export const venueCodeReferralOverride = (data) => {
  return { type: VENUE_CODE_REFERRAL_OVERRIDE, payload: data };
};

export const DELETE_DATE = "DELETE_DATE";
export const deleteDate = (date) => ({ type: DELETE_DATE, payload: { date } });

export const UPDATE_VENUE_CODE_VALIDITY = "UPDATE_VENUE_CODE_VALIDITY";
export const updateVenueCodeValidity = (data) => {
  return { type: UPDATE_VENUE_CODE_VALIDITY, payload: data };
};

export const SELECT_PLACE_BY_VENUE_CODE = "SELECT_PLACE_BY_VENUE_CODE";
export const selectPlaceByVenueCode = (data) => {
  return { type: SELECT_PLACE_BY_VENUE_CODE, payload: data };
};

export const RESET_SELECTED_PLACE = "RESET_SELECTED_PLACE";
export const resetSelectedPlace = () => {
  return { type: RESET_SELECTED_PLACE };
};

export const SET_UTC_OFFSET = "SET_UTC_OFFSET";
export const setUtcOffset = (data) => {
  return { type: SET_UTC_OFFSET, payload: data };
};

export const RESET_VENUE_CODE_VALIDATION = "RESET_VENUE_CODE_VALIDATION";
export const resetVenueCodeValidation = () => {
  return { type: RESET_VENUE_CODE_VALIDATION };
};

export const CLEAR_SELECT_PLACE = "CLEAR_SELECT_PLACE";
export const clearSelectPlace = () => {
  return { type: CLEAR_SELECT_PLACE };
};

export const RESET_SPECIAL_TAX_FORM_FLAGS = "RESET_SPECIAL_TAX_FORM_FLAGS";
export const resetSpecialTaxFormFlags = () => {
  return { type: RESET_SPECIAL_TAX_FORM_FLAGS };
};

export const POLICY_CANCELLED = "POLICY_CANCELLED";
export const policyCancelled = (data) => {
  return { type: POLICY_CANCELLED, payload: data };
};

export const PRESERVE_HAS_REGIONAL_TAX = "PRESERVE_HAS_REGIONAL_TAX";
export const preserveHasRegionalTax = (data) => {
  return { type: PRESERVE_HAS_REGIONAL_TAX, payload: data };
};

export const SET_DOWNLOAD_LINK = "SET_DOWNLOAD_LINK";
export const setDownloadLink = (data) => {
  return { type: SET_DOWNLOAD_LINK, payload: data };
};

export const CLEAR_SAMPLE_POLICY_LINK = "CLEAR_SAMPLE_POLICY_LINK";
export const clearSamplePolicyLink = () => {
  return { type: CLEAR_SAMPLE_POLICY_LINK };
};

export const SET_SHOW_TIMEOUT_MESSAGE = "SET_SHOW_TIMEOUT_MESSAGE";
export const setShowTimeoutMessage = (data) => {
  return { type: SET_SHOW_TIMEOUT_MESSAGE, payload: data };
};

export const INCREMENT_COUNTER = "INCREMENT_COUNTER";
export const incrementCounter = (data) => {
  return { type: INCREMENT_COUNTER, payload: data };
};

export const HANDLE_LOCATION_SEARCH = "HANDLE_LOCATION_SEARCH";
export const handleLocationSearch = (data) => {
  return { type: HANDLE_LOCATION_SEARCH, payload: data };
};

export const UPDATE_IS_CANNABIS_PREVENTED = "UPDATE_IS_CANNABIS_PREVENTED";
export const updateIsCannabisPrevented = (data) => {
  return { type: UPDATE_IS_CANNABIS_PREVENTED, payload: data };
};

export const GET_ADDITIONAL_CERT_HOLDERS = "GET_ADDITIONAL_CERT_HOLDERS";
export const getAdditionalCertHolders = (code) => {
  return async (dispatch) => {
    const venueApi = new VenuePresetsService(new API());
    let certData = [];
    try {
      if (code) {
        const additionalCertHolders = await venueApi.getAdditionalCertHolders(
          code
        );
        certData = additionalCertHolders.data;
      }
      dispatch(updateCertHolders(certData));
    } catch (err) {
      dispatch(errorResponse(err));
    }
    dispatch(endLoading(GET_ADDITIONAL_CERT_HOLDERS));
  };
};

// The user has clicked the purchase button

export const requestCreditCardToken = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    const cardNumber = state.form.application.values.payeeCardNum;
    const { month, year } = extractMonthYear(
      state.form.application.values.payeeCardExp
    );
    const fourDigitYear = normalizeYear(year);
    const cardValidator = cc.number(cardNumber);
    const cardValidatorCard = cardValidator.card || { type: null };
    const cardType = cyberSourceIdFromCardTypeName(cardValidatorCard.type);
    const creditCard = {
      cardNumber,
      cardExpirationMonth: month,
      cardExpirationYear: fourDigitYear,
      cardType,
    };

    new CyberSourceService(
      new API(`${process.env.REACT_APP_CYBERSOURCE_FLEX_API}`)
    )
      .tokenizeCard(creditCard, data.keyId)
      .then((response) =>
        dispatch(creditCardTokenResponse(response.data, PAYMENT_INFO_FORM))
      )
      .catch((err) => dispatch(errorResponse(err, PAYMENT_INFO_FORM)))
      .finally(() => dispatch(endLoading(PAYMENT_INFO_FORM)));
  };
};

export const confirmPayment = () => {
  return (dispatch) => {
    new PaymentService(new API())
      .getFlexKey()
      .then((response) => dispatch(requestCreditCardToken(response.data)))
      .catch((err) => dispatch(errorResponse(err, PAYMENT_INFO_FORM)));
  };
};

export const updateVenueInputSelect = (selected) => {
  return (dispatch) =>
    dispatch(change(formName, "venueSearchTypeRadio", selected));
};

export const clearRegionsList = () => {
  return (dispatch) => {
    dispatch(setTaxRegions(false, []));
    dispatch(change(formName, "venueMunicipalityCode", null));
  };
};

export const FETCHING_TAX_INFO = "FETCHING_TAX_INFO";
export const fetchTaxInfo = () => ({
  type: FETCHING_TAX_INFO,
});

export const FETCHING_TAX_INFO_SUCCESS = "FETCHING_TAX_INFO_SUCCESS";
export const fetchTaxInfoSuccess = () => ({
  type: FETCHING_TAX_INFO_SUCCESS,
});

export const FETCHING_TAX_INFO_ERROR = "FETCHING_TAX_INFO_ERROR";
export const fetchTaxInfoError = () => ({
  type: FETCHING_TAX_INFO_ERROR,
});

export const shouldLookupTax = (
  state,
  city,
  setIsKentuckyCity,
  setHasLookedUpTax,
  clear = true
) => {
  return async (dispatch) => {
    dispatch(fetchTaxInfo());
    const api = new StateTaxService(new API());
    try {
      const confirmRegionalTaxResponse = await api.confirmRegionalTax(state);
      if (state !== "KY") {
        setIsKentuckyCity(true);
        setHasLookedUpTax(true);
        dispatch(fetchTaxInfoSuccess());
      }
      if (confirmRegionalTaxResponse.data.shouldLookupTax) {
        const getTaxRegionsResponse = await api.getTaxRegions(state, city);
        dispatch(fetchTaxInfoSuccess());
        setHasLookedUpTax(true);
        const regions = getTaxRegionsResponse.data;
        if (regions.length > 0) {
          dispatch(setTaxRegions(true, regions));
          setIsKentuckyCity(true);
          if (clear) {
            regions.forEach((region) => {
              if (region.name.toLowerCase() === city.toLowerCase()) {
                dispatch(
                  change(formName, "venueMunicipalityCode", region.code)
                );
              }
            });
          }
        } else {
          dispatch(fetchTaxInfoSuccess());
          setIsKentuckyCity(false);
        }
      }
    } catch (err) {
      dispatch(fetchTaxInfoError());
      setIsKentuckyCity(true);
      setHasLookedUpTax(false);
      dispatch(clearRegionsList());
      dispatch(errorResponse(err));
    }
  };
};

export const venuePresets = (
  placeId,
  address,
  addressComponents,
  utcOffset,
  setIsKentuckyCity,
  setHasLookedUpTax,
  setVenueSearchReadOnly
) => {
  return (dispatch, getState) => {
    dispatch(updateBlockedVenue(false));
    dispatch(updateGenericServerError(false));
    const state = getState();
    new VenuePresetsService(new API())
      .getPresets(placeId)
      .then((response) => {
        const { data } = response;
        const { facility, venue } = data;

        if (!isValidState(venue.state, state)) {
          throw Object.assign(new Error(), {
            response: {
              inValidState: true,
            },
          });
        }

        dispatch(
          getAdditionalCertHolders(`${facility.code}-${venue.venueCode}`)
        );

        dispatch(
          setVenueCode({
            facilityCode: facility.code.toUpperCase(),
            venueCode: venue.venueCode,
          })
        );

        dispatch(
          selectPlace(placeId, address, addressComponents, utcOffset, data)
        );

        if (data.venue.blockedAt) {
          dispatch(updateBlockedVenue(true));
        } else {
          dispatch(clearRegionsList());
          setVenueSearchReadOnly(true);

          if (
            addressComponents.address1 &&
            addressComponents.city &&
            addressComponents.state &&
            addressComponents.country &&
            addressComponents.zip
          ) {
            dispatch(
              shouldLookupTax(
                addressComponents.state,
                addressComponents.city,
                setIsKentuckyCity,
                setHasLookedUpTax
              )
            );
          }

          dispatch(setVenuePresets(data));
          dispatch(
            change(
              formName,
              "generalLiabilityCoverage",
              initialFormValues().generalLiabilityCoverage
            )
          );
          dispatch(
            change(
              formName,
              "terrorismCoverageAgreement",
              initialFormValues().terrorismCoverageAgreement
            )
          );
          dispatch(setAdditionalCoveragesPresets(data));
          dispatch(setValidVenueAddressAndState());
          dispatch(updateIsCannabisPrevented(data.venue.isCannabisPrevented));
        }
      })
      .catch((err) => {
        if (!err.response) {
          dispatch(updateGenericServerError(true));
        } else {
          if (err.response.status === 404 || err.response.inValidState) {
            dispatch(clearRegionsList());
            dispatch(
              selectPlace(placeId, address, addressComponents, utcOffset)
            );
            setVenueSearchReadOnly(true);
            if (
              addressComponents.address1 &&
              addressComponents.city &&
              addressComponents.state &&
              addressComponents.country &&
              addressComponents.zip
            ) {
              const certHolders = [
                state.formPage.summary.additionalCertHolders.find(
                  ({ type }) => type === additionalInsuredFacilityType
                ),
                {
                  type: additionalInsuredVenueAddressType,
                  address: addressComponents,
                },
              ].filter((a) => a);
              dispatch(
                shouldLookupTax(
                  addressComponents.state,
                  addressComponents.city,
                  setIsKentuckyCity,
                  setHasLookedUpTax
                )
              );
              dispatch(updateCertHolders(certHolders));
              dispatch(endLoading(GET_ADDITIONAL_CERT_HOLDERS));
            }
            dispatch(setSelectedVenueCode());
          }
          // Set defaults for venues without specific presets
          dispatch(defaultBasicCoverageOptions());
          dispatch(defaultAdditionalCoverageStatus());
          dispatch(defaultAdditionalCoveragesForm());
          dispatch(
            change(
              formName,
              "generalLiabilityCoverage",
              initialFormValues().generalLiabilityCoverage
            )
          );
          dispatch(setValidVenueAddressAndState());
          dispatch(venueCodeReferralOverride(true));
          if (err.response && err.response.status !== 404) {
            dispatch(errorResponse(err));
          }
          dispatch(
            change(
              formName,
              "ACWaiverOfTransferRights",
              initialFormValues().ACWaiverOfTransferRights
            )
          );
          dispatch(
            change(
              formName,
              "terrorismCoverageAgreement",
              initialFormValues().terrorismCoverageAgreement
            )
          );
          dispatch(updateIsCannabisPrevented(false));
        }
      });
  };
};

export const getUtcOffset = (placeId) => {
  return (dispatch) => {
    const { google } = window;
    const map = new google.maps.Map(document.createElement("div"));

    const request = {
      placeId,
      fields: ["utc_offset_minutes"],
    };

    function callback(place, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK)
        dispatch(setUtcOffset(place.utc_offset_minutes));
      else dispatch(setUtcOffset());
    }

    const service = new google.maps.places.PlacesService(map);
    service.getDetails(request, callback);
  };
};

export const getCoordsForUtc = (zip) => {
  return (dispatch) => {
    // get placeId from google
    new VenuePresetsService(new API())
      .getCoordinates(zip)
      .then((response) => {
        const placeId = response.data.results[0].place_id;
        if (placeId) dispatch(getUtcOffset(placeId));
      })
      .catch((err) => {
        dispatch(setUtcOffset());
        dispatch(errorResponse(err));
      });
  };
};

export const searchByVenueCode = ({
  facilityCode,
  venueCode,
  validVenueCallback = () => {},
}) => {
  return (dispatch, getState) => {
    dispatch(updateBlockedVenue(false));
    updateGenericServerError(false);
    dispatch(setFetchingVenuePresets(true));

    new VenuePresetsService(new API())
      .getPresetsByVenueCode(facilityCode, venueCode)
      .then((response) => {
        dispatch(setFetchingVenuePresets(false));
        const { data } = response;

        const state = getState();

        if (!isValidState(data.venue.state, state)) {
          throw Object.assign(new Error(), {
            response: {
              inValidState: true,
            },
          });
        }

        dispatch(
          setVenueCode({
            facilityCode: facilityCode.toUpperCase(),
            venueCode,
          })
        );

        dispatch(
          setReferralVenue({
            facilityLogoUrl: data.facility.facilityLogo,
            facilityCode: facilityCode.toUpperCase(),
            venueCode,
          })
        );

        if (Object.keys(data).length) {
          dispatch(selectPlaceByVenueCode(data));
        }

        dispatch(clearSpecialTaxForms());

        if (data.venue && data.venue.blockedAt) {
          dispatch(updateBlockedVenue(true));
        } else {
          dispatch(
            shouldLookupTax(
              data.venue.state,
              data.venue.city,
              (isKentuckyCity) => dispatch(setIsKentuckyCity(isKentuckyCity)),
              (hasLookedUpTax) => dispatch(setHasLookedUpTax(hasLookedUpTax))
            )
          );
          dispatch(updateVenueCodeValidity(!!data));
          dispatch(updateIsCannabisPrevented(data.venue.isCannabisPrevented));

          dispatch(startLoading(GET_ADDITIONAL_CERT_HOLDERS));
          dispatch(getAdditionalCertHolders(`${facilityCode}-${venueCode}`));

          if (Object.keys(data).length) {
            dispatch(setVenuePresets(data));
            dispatch(
              change(
                formName,
                "generalLiabilityCoverage",
                initialFormValues().generalLiabilityCoverage
              )
            );
            dispatch(setAdditionalCoveragesPresets(data));
            dispatch(
              setSelectedVenueCode(
                `${data.facility.code}-${data.venue.venueCode}`
              )
            );
            dispatch(setValidVenueAddressAndState());
            validVenueCallback();

            if (data.places.length) {
              dispatch(getUtcOffset(data.places[0].placeId));
            } else {
              dispatch(getCoordsForUtc(data.venue.zip));
            }
          } else {
            dispatch(resetSelectedPlace());
          }
        }
      })
      .catch((err) => {
        dispatch(setFetchingVenuePresets(false));

        if (!err.response) {
          dispatch(updateGenericServerError(true));
        }
        dispatch(errorResponse(err));
      });
  };
};

export const clearVenueSearchForm = () => {
  return (dispatch) => {
    const fieldList = [
      "facilityCode",
      "venueCode",
      "manualVenueName",
      "manualVenueAddress1",
      "manualVenueCity",
      "manualVenueState",
      "manualVenueZip",
    ];

    fieldList.forEach((field) => {
      dispatch(change(formName, field, initialFormValues()[field]));
      dispatch(untouch(formName, field));
    });

    dispatch(change(formName, "venue", ""));
    dispatch(resetVenueCodeValidation());
    dispatch(setSelectedVenueCode());
    dispatch(venueCodeReferralOverride(false));
  };
};

export const clearSpecialTaxForms = () => {
  return (dispatch) => {
    dispatch(change(formName, "federalEntity", ""));
    dispatch(change(formName, "kentuckyStateEntity", ""));
    dispatch(resetSpecialTaxFormFlags());
  };
};

export const CANCEL_FORM_SET_WAITING = "CANCEL_FORM_SET_WAITING";
export const cancelPolicy = (
  policyNumber,
  creditCardNumber,
  venueZip,
  handleShow
) => {
  return (dispatch) => {
    dispatch({ type: CANCEL_FORM_SET_WAITING, payload: true });
    new RefundCancelService(new API())
      .submitCancelPolicy(policyNumber, creditCardNumber, venueZip)
      .then((response) => {
        if (response.status === 200) dispatch(policyCancelled(response.data));
      })
      .catch((err) => {
        handleShow();
        dispatch(errorResponse(err));
      })
      .finally(() => {
        dispatch({ type: CANCEL_FORM_SET_WAITING, payload: false });
      });
  };
};

export const setKentuckyCity = (city, federalEntity, kentuckyStateEntity) => {
  return (dispatch) => {
    dispatch(change(formName, "manualVenueCity", city));
    dispatch(change(formName, "federalEntity", federalEntity));
    dispatch(change(formName, "kentuckyStateEntity", kentuckyStateEntity));
    dispatch(preserveHasRegionalTax(true));
  };
};

export const getSamplePolicy = (venueState, setShowLink, setThinking) => {
  return (dispatch) => {
    new SamplePolicyService(new API())
      .getSamplePolicy(venueState)
      .then((response) => {
        dispatch(setDownloadLink(response.data));
        setShowLink(true);
        setThinking(false);
      })
      .catch((err) => {
        setShowLink(false);
        setThinking(true);
        dispatch(errorResponse(err));
      });
  };
};

export const SET_DOWNLOADED = "SET_DOWNLOADED";
export const setDownloaded = (data) => {
  return { type: SET_DOWNLOADED, payload: data };
};

export const FETCH_REFERRAL_FACILITY = "FETCH_REFERRAL_FACILITY";
export const fetchReferralFacility = () => {
  return { type: FETCH_REFERRAL_FACILITY };
};

export const FETCH_REFERRAL_PRODUCER = "FETCH_REFERRAL_PRODUCER";
export const fetchReferralProducer = () => {
  return { type: FETCH_REFERRAL_PRODUCER };
};

export const SET_REFERRAL_FACILITY = "SET_REFERRAL_FACILITY";
export const setReferralFacility = (data) => {
  return { type: SET_REFERRAL_FACILITY, payload: data };
};

export const SET_REFERRAL_PRODUCER = "SET_REFERRAL_PRODUCER";
export const setReferralProducer = (data) => {
  return { type: SET_REFERRAL_PRODUCER, payload: data };
};

export const SET_REFERRAL_VENUE = "SET_REFERRAL_VENUE";
export const setReferralVenue = (data) => {
  return { type: SET_REFERRAL_VENUE, payload: data };
};

export const CLEAR_VENUE_REFERRAL = "CLEAR_VENUE_REFERRAL";
export const clearVenueReferral = () => ({ type: CLEAR_VENUE_REFERRAL });

export const getReferralFacility = (data) => {
  return (dispatch) => {
    dispatch(fetchReferralFacility());
    new FacilityService(new API())
      .getReferralFacility(data)
      .then((response) => {
        dispatch(setReferralFacility(response.data));
        dispatch(startLoading(GET_ADDITIONAL_CERT_HOLDERS));
        dispatch(getAdditionalCertHolders(response?.data?.code));
      })
      .catch(() => {
        dispatch(
          setReferralFacility({
            facilityName: "",
          })
        );
      });
  };
};

export const getProducerCode = (producerCode) => {
  return (dispatch) => {
    dispatch(fetchReferralProducer());
    new ProducerService(new API())
      .getProducerByCode(producerCode)
      .then((response) => dispatch(setReferralProducer(response.data)))
      .catch(() => {
        dispatch(
          setReferralProducer({
            commissionId: "",
            subProducerCode: "",
          })
        );
      });
  };
};

export const SET_VENUE_CODE = "SET_VENUE_CODE";
export const setVenueCode = (data) => {
  return { type: SET_VENUE_CODE, payload: data };
};
export const SET_VENUE_LINK = "SET_VENUE_LINK";
export const setVenueLink = (data) => {
  return { type: SET_VENUE_LINK, payload: data };
};

export const setAndSearchVenueCode = (params) => {
  return (dispatch) => {
    const rawParams = params.toLowerCase();
    const urlParams = new URLSearchParams(rawParams);
    const venueCodeParam = urlParams.get(VENUE_CODE_PARAM);

    if (RouteParser.isValidVenueCode(venueCodeParam)) {
      const [facilityCode, venueCode] = venueCodeParam.split("-");

      dispatch(
        searchByVenueCode({
          facilityCode,
          venueCode,
          validVenueCallback: () => {
            const route = RouteParser.create(window.location.pathname);
            if (!route.isApplicationPage()) {
              dispatch(confirmForm(VENUE_FORM));
            }
          },
        })
      );
      dispatch(setVenueLink(window.location.href));
    }
  };
};

export const setAndSearchProducerCode = (params) => {
  return (dispatch) => {
    const rawParams = params.toLowerCase();
    const urlParams = new URLSearchParams(rawParams);
    const producerCodeParam = urlParams.get(PRODUCER_CODE_PARAM);

    if (RouteParser.isValidProducerCode(producerCodeParam)) {
      dispatch(getProducerCode(producerCodeParam));
    }
  };
};

export const setAndSearchFacilityCode = (params) => {
  return (dispatch) => {
    const rawParams = params.toLowerCase();
    const urlParams = new URLSearchParams(rawParams);
    const facilityCodeParam = urlParams.get(FACILITY_CODE_PARAM);

    if (RouteParser.isValidFacilityCode(facilityCodeParam)) {
      dispatch(getReferralFacility(facilityCodeParam));
    }
  };
};

export const TRACK_CONVERSION = "TRACK_CONVERSION";
export const trackConversion = ({ orderNumber }) => {
  if (window.trackConversion) {
    window.trackConversion(orderNumber);
  }
  return { type: TRACK_CONVERSION, payload: { orderNumber } };
};

export const touchRegistered =
  (formName, ...fields) =>
  (dispatch, getState) => {
    const state = getState();
    const registeredFields = state.form[formName]?.registeredFields || {};

    dispatch(
      touch(formName, ...fields.filter((field) => !!registeredFields[field]))
    );
  };
