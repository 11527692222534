import { formValueSelector } from "redux-form";
import { formName } from "../Constants/constants";
import { contactTypeOther, contactTypePerson } from "../Constants/contactTypes";
import { getTotalAmount } from "src/Selectors/Quote";
import { getOccurrenceGl } from "src/Selectors/Limits";
import { getHostLiquorItem } from "src/Selectors/Limits";
import { isWeddingTypeEventSelected } from "src/Selectors/Event";
import { isRequiredCondition } from "@gbli-events/common/src/Helpers/UnderwritingQuestions";
import { isVenueInNorthDakota } from "../Selectors/Payment";
import { dateHelpers } from "@jauntin/utilities";

const formValues = formValueSelector(formName);

function getAdditionalCoverages(state) {
  const hostLiquorItem = getHostLiquorItem(state);
  const personalAndAdvertisingLimit = getOccurrenceGl(state);
  const productsAndCompletedOperations = getOccurrenceGl(state);
  const terrorism = formValues(state, "ACTerrorism");
  const damageToRentedProperty = formValues(state, "ACDamageToRentedProperty");
  const waiverOfTransferRights = formValues(state, "ACWaiverOfTransferRights");
  const medical = 5000;
  const cannabis = formValues(state, "cannabis");

  const additionalCoverageTypes = [
    {
      type: "host-liquor",
      value: hostLiquorItem.coverageDescription === "Included",
    },

    {
      type: "personal-and-advertising-limit",
      value: personalAndAdvertisingLimit,
    },

    {
      type: "products-and-completed-operations",
      value: cannabis !== "1" ? productsAndCompletedOperations : null,
    },

    {
      type: "terrorism",
      value: terrorism === "1",
    },

    {
      type: "damage-to-rented-premises",
      value: damageToRentedProperty,
    },

    {
      type: "waiver-of-transfer-rights",
      value: waiverOfTransferRights,
    },

    {
      type: "medical",
      value: medical,
    },

    {
      type: "cannabis",
      value: cannabis === "1",
    },
  ];

  return additionalCoverageTypes;
}

function getRenterContactType(state) {
  const indivOrCompany = formValues(state, "insuredRenter");
  if (indivOrCompany === contactTypePerson) {
    return indivOrCompany;
  }
  return formValues(state, "insuredCompanyType");
}

function getPaymentBody(state) {
  const renterAddress = {
    address1: formValues(state, "insuredAddress"),
    address2: formValues(state, "insuredAddress2"),
    city: formValues(state, "insuredCity"),
    state: formValues(state, "insuredState"),
    country: formValues(state, "insuredCountry"),
    zip: formValues(state, "insuredZip"),
  };

  renterAddress.contactType = getRenterContactType(state);
  if (renterAddress.contactType === contactTypePerson) {
    renterAddress.contactFirstName = formValues(state, "insuredFirstName");
    renterAddress.contactLastName = formValues(state, "insuredLastName");
  } else {
    renterAddress.companyName = formValues(state, "insuredCompany");
    renterAddress.companyType = formValues(state, "insuredRenterType");
  }

  const insuranceContactAddress = {
    contactFirstName:
      renterAddress.contactType === contactTypePerson
        ? formValues(state, "insuredFirstName")
        : formValues(state, "contactFirstName"),
    contactLastName:
      renterAddress.contactType === contactTypePerson
        ? formValues(state, "insuredLastName")
        : formValues(state, "contactLastName"),
    companyName: renterAddress.companyName,
    address1: renterAddress.address1,
    address2: renterAddress.address2,
    city: renterAddress.city,
    state: renterAddress.state,
    country: renterAddress.country,
    zip: renterAddress.zip,
    contactType: renterAddress.contactType,
  };

  if (!state.formPage.insuranceContact.contactSame) {
    insuranceContactAddress.contactFirstName = formValues(
      state,
      "contactFirstName"
    );
    insuranceContactAddress.contactLastName = formValues(
      state,
      "contactLastName"
    );
    insuranceContactAddress.address1 = formValues(state, "insuredAddress");
    insuranceContactAddress.address2 = formValues(state, "insuredAddress2");
    insuranceContactAddress.city = formValues(state, "insuredCity");
    insuranceContactAddress.state = formValues(state, "insuredState");
    insuranceContactAddress.country = formValues(state, "insuredCountry");
    insuranceContactAddress.zip = formValues(state, "insuredZip");
    insuranceContactAddress.contactType = contactTypePerson;
  }

  // Billing - send nothing if the address is the same, but otherwise,
  // get form values from 'payee' section
  let billingAddress = null;
  if (!state.formPage.paymentInfo.payeeSame) {
    billingAddress = {
      address1: formValues(state, "payeeAddress"),
      address2: formValues(state, "payeeAddress2"),
      city: formValues(state, "payeeCity"),
      state: formValues(state, "payeeState"),
      zip: formValues(state, "payeeZip"),
      country: formValues(state, "payeeCountry"),
    };
    if (state.formPage.paymentInfo.payeeRenter) {
      billingAddress.contactType = contactTypeOther;
      billingAddress.companyName = formValues(state, "payeeCompany");
      billingAddress.contactFirstName = formValues(state, "contactFirstName");
      billingAddress.contactLastName = formValues(state, "contactLastName");
    } else {
      billingAddress.contactType = contactTypePerson;
      billingAddress.contactFirstName = formValues(state, "payeeFirstName");
      billingAddress.contactLastName = formValues(state, "payeeLastName");
    }
  }

  const cardInfo = {
    token: state.formPage.paymentInfo.token,
    cardType: state.formPage.paymentInfo.cardType,
    lastFour: state.formPage.paymentInfo.lastFour,
    nameOnCard: formValues(state, "payeeCardName"),
    amountDetails: {
      totalAmount: getTotalAmount(state),
      currency: "USD",
    },
  };

  const formQuestions = formValues(state, "underwritingQuestions") || [];
  const underwritingQuestions = formQuestions.map((question) => ({
    code: question.code,
    answer: isRequiredCondition(formQuestions)(question)
      ? question.answer
      : null,
  }));

  const formOptionalVenues = formValues(state, "optionalVenues") || [];
  const optionalVenues = formOptionalVenues
    .filter((venue) => !!venue.selectedPlaceId)
    .map((venue) => ({
      placeId: venue.selectedPlaceId || "",
      companyName: venue.selectedPlaceAddressComponents.companyName || "",
      address1: venue.selectedPlaceAddressComponents.address1 || "",
      city: venue.selectedPlaceAddressComponents.city || "",
      state: venue.selectedPlaceAddressComponents.state || "",
      country: venue.selectedPlaceAddressComponents.country || "",
      zip: venue.selectedPlaceAddressComponents.zip || "",
      utcOffset: venue.selectedPlaceUtcOffset || 0,
      municipalTaxCode: null,
      facilityCode: null,
      venueCode: null,
    }));

  const setUpDates = (formValues(state, "eventSetupDates") || [])
    .filter(({ selected }) => selected)
    .map(({ key: date }) => dateHelpers.dateOnlyStringFormat(date));

  const tearDownDates = (formValues(state, "eventTeardownDates") || [])
    .filter(({ selected }) => selected)
    .map(({ key: date }) => dateHelpers.dateOnlyStringFormat(date));

  const includeRehearsalDinner = isWeddingTypeEventSelected(state)
    ? !!formValues(state, "weddingRehearsal")
    : null;

  const includeBreakfastAfterWedding = isWeddingTypeEventSelected(state)
    ? !!formValues(state, "weddingBrunch")
    : null;

  const northDakotaDisclosure = isVenueInNorthDakota(state)
    ? !!formValues(state, "northDakotaDisclosure")
    : null;

  const values = {
    venueSearchType: formValues(state, "venueSearchTypeRadio"),
    isKentuckyEntity: formValues(state, "kentuckyStateEntity") === "yes",
    isFederalEntity: formValues(state, "federalEntity") === "yes",
    eventType: state.form.application.values.eventType?.value?.identifier,
    includeRehearsalDinner,
    includeBreakfastAfterWedding,
    northDakotaDisclosure,
    gll: formValues(state, "generalLiabilityCoverage"),
    name: formValues(state, "eventName"),
    dates: formValues(state, "eventDates").map((date) =>
      dateHelpers.dateOnlyStringFormat(date)
    ),
    setUpDates,
    tearDownDates,
    eventFrequency: formValues(state, "eventFrequencyField"),
    averageDailyAttendance: formValues(state, "eventDailyGuests"),
    additionalCoverage: getAdditionalCoverages(state),
    renterAddress: [renterAddress],
    cardInfo: [cardInfo],
    venueAddress: [
      {
        placeId: state.formPage.venue.selectedPlaceId || "",
        facilityCode: formValues(state, "facilityCode") || "",
        venueCode: formValues(state, "venueCode") || "",
        companyName:
          state.formPage.venue.selectedPlaceAddressComponents.companyName ||
          formValues(state, "manualVenueName"),
        address1:
          state.formPage.venue.selectedPlaceAddressComponents.address1 ||
          formValues(state, "manualVenueAddress1"),
        city:
          state.formPage.venue.selectedPlaceAddressComponents.city ||
          formValues(state, "manualVenueCity"),
        state:
          state.formPage.venue.selectedPlaceAddressComponents.state ||
          formValues(state, "manualVenueState"),
        country:
          state.formPage.venue.selectedPlaceAddressComponents.country ||
          formValues(state, "manualVenueCountry"),
        zip:
          state.formPage.venue.selectedPlaceAddressComponents.zip ||
          formValues(state, "manualVenueZip"),
        utcOffset: state.formPage.venue.selectedPlaceUtcOffset || 0,
        municipalTaxCode: formValues(state, "venueMunicipalityCode"),
      },
      ...optionalVenues,
    ],
    insuranceContactPhone: formValues(state, "contactPhone"),
    insuranceContactEmail: formValues(state, "contactEmail"),
    consentConfirmed: formValues(state, "consentConfirmed"),
    excludedActivitiesConfirmed: true,
    sourceCode: state.formPage.referral.sourceCode,
    producerCode: state.formPage.referral.producerCode,
    subProducerCode: state.formPage.referral.subProducerCode,
    facilityCode: state.formPage.referral.facilityCode,
    underwritingQuestions: underwritingQuestions,
    waiverOfTransferRights: formValues(state, "ACWaiverOfTransferRights"),
    boilerplate: state.formPage.referral.boilerplate,
    additionalInsureds: state.formPage.summary.additionalCertHolders,
  };

  if (billingAddress) {
    values.billingAddress = [billingAddress];
  }

  values.insuranceContactAddress = [insuranceContactAddress];

  return values;
}

export default getPaymentBody;
