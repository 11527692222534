import { useState } from "react";
import { useSelector } from "react-redux";
import { Navbar, Modal, Nav } from "react-bootstrap";
import { getValidatingReferralCodes } from "src/Selectors/Shared";
import {
  getReferralQueryParams,
  getShowAgentsLink,
} from "src/Selectors/Shared";
import { getReferralLogo } from "src/Selectors/Shared";

const Header = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const items = [
    {
      label: "For Agents",
      url: "https://specialinsurance.com/specialinsurance/for-agents/",
    },
    {
      label: "For Venues",
      url: "https://specialinsurance.com/specialinsurance/for-venues/",
    },
    {
      label: "FAQ",
      url: "https://specialinsurance.com/specialinsurance/faq/",
    },
    {
      label: "About",
      url: "https://specialinsurance.com/specialinsurance/about-us/",
    },
    {
      label: "Contact",
      url: "https://specialinsurance.com/specialinsurance/contact/",
    },
  ];
  const referralQueryParams = useSelector(getReferralQueryParams);
  const validatingReferralCodes = useSelector(getValidatingReferralCodes);
  const showAgentsLink = useSelector(getShowAgentsLink);
  const logo = useSelector(getReferralLogo);

  const navItems = (
    <Nav className="header__navigation">
      {items &&
        !validatingReferralCodes &&
        items
          .filter((item) => !(item.label === "For Agents" && !showAgentsLink))
          .map(({ label, url, target }, index) => (
            <Nav.Item className="header__navigation-item" key={index}>
              <a
                href={url + referralQueryParams}
                target={target || "_self"}
                rel="noopener"
              >
                {label}
              </a>
            </Nav.Item>
          ))}
    </Nav>
  );

  const hideMobileNav = () => setShowMobileNav(false);
  return (
    <>
      <div className="header">
        <div className="container">
          <Navbar className="py-2" expand="lg">
            <Navbar.Brand>
              <button
                className="undecorate-btn"
                onClick={() => {
                  window.location.assign(
                    `https://specialinsurance.com/${referralQueryParams}`
                  );
                  hideMobileNav();
                }}
              >
                {(logo && (
                  <img className="customLogo" src={logo.url} alt={logo.alt} />
                )) || (
                  <img
                    className="defaultLogo"
                    src="/logos/special-insurance-logo.png"
                    alt="Logo"
                  />
                )}
              </button>
            </Navbar.Brand>
            <Navbar.Toggle
              className="border-0 p-0"
              onClick={() => {
                setShowMobileNav(!showMobileNav);
              }}
              aria-controls="responsive-navbar-nav"
            >
              {showMobileNav ? (
                <i className="fal fa-times" />
              ) : (
                <i className="fas fa-bars header__bars"></i>
              )}
            </Navbar.Toggle>
            <div className="d-none d-lg-block ml-auto">{navItems}</div>
          </Navbar>
        </div>
      </div>
      <Modal
        className="header__side-modal"
        backdropClassName="header__side-modal-backdrop"
        dialogClassName="header__dialog"
        show={showMobileNav}
        onHide={hideMobileNav}
      >
        <div className="px-4">{navItems}</div>
      </Modal>
    </>
  );
};

export default Header;
