import getPaymentBody from "src/Helpers/ExtractFormState";
import { formValueSelector } from "redux-form";
import { formName } from "src/Constants/constants";

const formValues = formValueSelector(formName);

const includeKeys = (obj, keys) =>
  Object.entries(obj)
    .filter(([key]) => keys.includes(key))
    .reduce((result, [key, value]) => {
      result[key] = value;
      return result;
    }, {});

export const getDataPayload = (state) => {
  return includeKeys(
    {
      ...getPaymentBody(state),
      contactSame: state.formPage.insuranceContact.contactSame,
      quoteSaved: !!formValues(state, "quoteSaved"),
      policyPurchased: !!formValues(state, "policyPurchased"),
      venueLink: formValues(state, "venueLink"),
    },
    [
      "eventType",
      "name",
      "dates",
      "averageDailyAttendance",
      "additionalCoverage",
      "renterAddress",
      "venueAddress",
      "insuranceContactPhone",
      "insuranceContactEmail",
      "insuranceContactAddress",
      "sourceCode",
      "producerCode",
      "subProducerCode",
      "facilityCode",
      "underwritingQuestions",
      "additionalInsureds",
      "contactSame",
      "quoteSaved",
      "policyPurchased",
      "gll",
      "venueLink",
    ]
  );
};

export const getUserEntryDataPayload = (state) => {
  const data = getDataPayload(state);
  const formUniqId = state.formPage.formUniqId;

  return {
    data,
    formUniqId,
  };
};
