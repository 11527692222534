const cyberSourceIds = ["001", "002", "003", "004", "005", "007"];

const cardNames = [
  "visa",
  "mastercard",
  "american-express",
  "discover",
  "diners-club",
  "jcb",
];

const cybersouceTestCards = [
  "4000056655665556",
  "4012888888881881",
  "4111111111111111",
  "4622943127013705",
  "4622943127013713",
  "4622943127013721",
  "4622943127013739",
  "4622943127013747",
  "30569309025904",
  "4242424242424242",
  "5454545454545454",
  "2221000000000009",
  "2223016768739313",
  "2223000048400011",
  "2223003122003222",
  "2222420000001113",
  "2222630000001125",
  "5555555555554444",
  "5200828282828210",
  "5105105105105100",
  "378734493671000",
  "378282246310005",
  "371449635398431",
  "6011111111111117",
  "6011000990139424",
  "3566111111111113",
  "3566002020360505",
  "3530111333300000",
  "50339619890917",
  "586824160825533338",
  "6759411100000008",
  "6759560045005727054",
  "5641821111166669",
  "135412345678911",
  "36227206271667",
  "3056930009020004",
];

const cardTypeNameFromCyberSourceId = (id) => {
  const index = cyberSourceIds.findIndex((m) => m === id);
  return index > -1 ? cardNames[index] : null;
};
const cyberSourceIdFromCardTypeName = (name) => {
  const index = cardNames.findIndex((m) => m === name);
  return index > -1 ? cyberSourceIds[index] : null;
};

export {
  cardTypeNameFromCyberSourceId,
  cyberSourceIdFromCardTypeName,
  cybersouceTestCards,
};
