import PropTypes from "prop-types";
/**
 *
 * Model used to represent a `FormPageVenue`. This file includes
 *
 * 1. An empty model with default strings and values
 * 2. Prop Types for the model
 *
 */

/**
 *
 * EMPTY OBJECTS - use to prefill. For example initial state
 *
 */
export const emptyVenueForm = {
  venueSearch: "",
  selectedPlaceId: "",
  selectedPlaceAddress: "",
  selectedPlaceAddressComponents: {
    companyName: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    country: null,
    zip: null,
    addressType: null,
  },
  selectedPlaceUtcOffset: "",
  enabled: false,
  confirmed: false,
  validVenue: false,
  validVenueCode: false,
  validManualAddress: false,
  validVenueAddressAndState: false,
  hasRegionalTax: false,
  taxRegions: [],
  isFederalEntity: true,
  isKentuckyEntity: true,
  kentuckyCity: "",
  venueCodeChecked: false,
  isBlocked: false,
  genericServerError: false,
  venueCode: "",
  manualAddressComponents: {
    companyName: null,
    address1: null,
    city: null,
    state: null,
    country: null,
    zip: null,
  },
  venueCodeReferralOverride: false,
  producer: null,
  needOptionalVenue: false,
  eventTypes: [],
  isCannabisPrevented: false,
};

/**
 *
 * PROP TYPES
 *
 */
export const venueFormPropType = PropTypes.shape({
  venueSearch: PropTypes.string,
  selectedPlaceId: PropTypes.string,
  selectedPlaceAddress: PropTypes.string,
  selectedPlaceAddressComponents: PropTypes.shape({
    companyName: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.string,
    addressType: PropTypes.arrayOf(PropTypes.string),
  }),
  selectedPlaceUtcOffset: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  enabled: PropTypes.bool,
  confirmed: PropTypes.bool,
  validVenue: PropTypes.bool,
  validVenueCode: PropTypes.bool,
  validManualAddress: PropTypes.bool,
  validVenueAddressAndState: PropTypes.bool,
  hasRegionalTax: PropTypes.bool,
  taxRegions: PropTypes.arrayOf(PropTypes.any),
  isFederalEntity: PropTypes.bool,
  isKentuckyEntity: PropTypes.bool,
  kentuckyCity: PropTypes.string,
  venueCodeChecked: PropTypes.bool,
  isBlocked: PropTypes.bool,
  genericServerError: PropTypes.bool,
  venueCode: PropTypes.string,
  manualAddressComponents: PropTypes.shape({
    companyName: PropTypes.string,
    address1: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.string,
  }),
  venueCodeReferralOverride: PropTypes.bool,
  isCannabisPrevented: PropTypes.bool,
});
