export const SET_IS_KENTUCKY_CITY = "SET_IS_KENTUCKY_CITY";
export const setIsKentuckyCity = (isKentuckyCity) => {
  return { type: SET_IS_KENTUCKY_CITY, payload: { isKentuckyCity } };
};

export const SET_KENTUCKY_CITY = "SET_KENTUCKY_CITY";
export const setKentuckyCity = (kentuckyCity) => {
  return { type: SET_KENTUCKY_CITY, payload: { kentuckyCity } };
};

export const SET_HAS_LOOKED_UP_TAX = "SET_HAS_LOOKED_UP_TAX";
export const setHasLookedUpTax = (hasLookedUpTax) => {
  return { type: SET_HAS_LOOKED_UP_TAX, payload: { hasLookedUpTax } };
};
