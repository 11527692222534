import { isWeddingTypeEventSelected } from "./Event";
import { dateHelpers } from "@jauntin/utilities";
import { subDays, addDays } from "date-fns";

export const getEventSummary = (state) => {
  const { values } = state.form.application;

  let detailItems = [
    {
      label: "Event Type",
      value: values.eventType?.label || "TBD",
    },
    {
      label: "Event Name",
      value: values.eventName || "TBD",
    },
    {
      label: "Cannabis coverage selected",
      value:
        values.cannabis === null
          ? "TBD"
          : values.cannabis === "1"
          ? "Yes"
          : "No",
    },
    {
      label: "Number of attendees per day",
      value: values.eventDailyGuests || "TBD",
    },
    {
      label: "Event start/first day",
      value: values.eventDateRange.startDate
        ? dateHelpers.slashSeparated(values.eventDateRange.startDate)
        : "TBD",
    },
    {
      label: "Event end/last day",
      value: values.eventDateRange.endDate
        ? dateHelpers.slashSeparated(values.eventDateRange.endDate)
        : "TBD",
    },
  ];

  if (isWeddingTypeEventSelected(state)) {
    detailItems = detailItems.concat([
      {
        label: "Wedding rehearsal dinner",
        value: values.weddingRehearsal
          ? values.eventDateRange.startDate
            ? dateHelpers.slashSeparated(
                subDays(new Date(values.eventDateRange.startDate), 1)
              )
            : "TBD"
          : "Not Chosen",
      },
    ]);

    detailItems = detailItems.concat([
      {
        label: "Wedding breakfast/brunch",
        value: values.weddingBrunch
          ? values.eventDateRange.endDate
            ? dateHelpers.slashSeparated(
                addDays(new Date(values.eventDateRange.endDate), 1)
              )
            : "TBD"
          : "Not Chosen",
      },
    ]);
  }

  if (values.eventSetupDates.filter(({ selected }) => selected).length) {
    detailItems = detailItems.concat([
      {
        label: "Setup days",
        value: values.eventSetupDates
          .filter(({ selected }) => selected)
          .map(({ key: date }) => dateHelpers.slashSeparated(date))
          .join("\n"),
      },
    ]);
  }

  if (values.eventTeardownDates.filter(({ selected }) => selected).length) {
    detailItems = detailItems.concat([
      {
        label: "Teardown days",
        value: values.eventTeardownDates
          .filter(({ selected }) => selected)
          .map(({ key: date }) => dateHelpers.slashSeparated(date))
          .join("\n"),
      },
    ]);
  }

  return { detailItems };
};
