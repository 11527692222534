import React from "react";
import PropTypes from "prop-types";
import UnderwritingQuestionField from "./UnderwritingQuestionField";
import { underwritingQuestionPropType } from "src/Models/UnderwritingQuestionModel";
import { isRequiredCondition } from "@gbli-events/common/src/Helpers/UnderwritingQuestions";
import { FieldsProps } from "@gbli-events/common/src/Constants/ReduxFormPropTypes";

const propTypes = {
  fields: FieldsProps.isRequired,
  underwritingQuestions: PropTypes.arrayOf(underwritingQuestionPropType)
    .isRequired,
};

const UnderwritingQuestionFields = ({ fields, underwritingQuestions }) => {
  return fields.map((item, index) => {
    const underwritingQuestion = underwritingQuestions[index];
    const shouldRender = isRequiredCondition(underwritingQuestions)(
      underwritingQuestion
    );

    return (
      <UnderwritingQuestionField
        key={item}
        name={`${item}.answer`}
        underwritingQuestion={underwritingQuestion}
        shouldRender={shouldRender}
      />
    );
  });
};

UnderwritingQuestionFields.propTypes = propTypes;

export default UnderwritingQuestionFields;
