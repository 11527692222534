// Underwriting Questsions have been received from the server
export const SET_UNDERWRITING_QUESTIONS = "SET_UNDERWRITING_QUESTIONS";
export const setUnderwritingQuestions = (data) => ({
  type: SET_UNDERWRITING_QUESTIONS,
  payload: data,
});

export const FETCH_UNDERWRITING_QUESTIONS_FAILURE =
  "FETCH_UNDERWRITING_QUESTIONS_FAILURE";
export const fetchUnderwritingQuestionsFailure = () => {
  return { type: FETCH_UNDERWRITING_QUESTIONS_FAILURE };
};

export const initializeForm = () => (dispatch, getState) => {
  const state = getState();
  const { underwritingQuestions: underwritingQuestionsList } = state.formPage;
  const { underwritingQuestions } = state.form.application.values;

  if (!underwritingQuestions.length) {
    dispatch(setUnderwritingQuestions(underwritingQuestionsList));
  }
};
