import React from "react";
import { useSelector } from "react-redux";
import CurrencyHelper from "../Helpers/CurrencyHelper";
import { getHasEventTypeError } from "src/Selectors/Event";
import { getVenueStateCannabisErrorMessage } from "src/Selectors/Venue";
import { getTotalAmount } from "src/Selectors/Quote";

const TotalQuote = () => {
  const totalAmount = useSelector(getTotalAmount);

  const hasEventTypeError = useSelector(getHasEventTypeError);
  const venueStateCannabisErrorMessage = useSelector(
    getVenueStateCannabisErrorMessage
  );

  return totalAmount &&
    !hasEventTypeError &&
    venueStateCannabisErrorMessage.length === 0 ? (
    new CurrencyHelper(totalAmount).convertFromInt().formatDollars()
  ) : (
    <i className="far fa-exclamation-circle alert__icon m-0 responsive-font-size" />
  );
};

export default TotalQuote;
