import { useSelector } from "react-redux";
import { Nav } from "react-bootstrap";
import { format } from "date-fns";
import {
  getValidatingReferralCodes,
  getReferralQueryParams,
  getStatesByInsuranceCompany,
} from "src/Selectors/Shared";
import { getAvailableStateCodes } from "../../Selectors/Venue";
import { useInsuranceCompanies } from "src/Hooks/InsuranceCompanies";

const Footer = () => {
  const items = [
    {
      label: `T. +800-310-3351 option 2`,
      url: `tel:8003103351`,
      target: "_self",
    },
    {
      label: "Legal & Privacy",
      url: "https://specialinsurance.com/specialinsurance/legal-privacy/",
    },
    {
      label: "Terms & Conditions",
      url: "https://specialinsurance.com/specialinsurance/terms-conditions/",
    },
    {
      label: "Sample Policy and Certificates",
      url: "https://specialinsurance.com/sample-policy-and-certificates/",
    },
    {
      label: "Refunds & Cancellations",
      url: "https://specialinsurance.com/specialinsurance/refunds-cancellations/",
    },
    {
      label: "Blog",
      url: "https://specialinsurance.com/blog/",
    },
    {
      label: "Site Map",
      url: "https://specialinsurance.com/specialinsurance/site-map/",
    },
  ];
  const referralQueryParams = useSelector(getReferralQueryParams);
  const validatingReferralCodes = useSelector(getValidatingReferralCodes);
  const availableStates = useSelector(getAvailableStateCodes);
  const unitedNationalInsuranceCompany = useSelector(
    getStatesByInsuranceCompany(
      "United National Insurance Company",
      availableStates,
      true
    )
  );
  const pennPatriotInsuranceCompany = useSelector(
    getStatesByInsuranceCompany(
      "Penn Patriot Insurance Company",
      availableStates
    )
  );
  const diamondStateInsuranceCompany = useSelector(
    getStatesByInsuranceCompany(
      "Diamond State Insurance Company",
      availableStates
    )
  );

  useInsuranceCompanies();

  const navItems = (
    <Nav className="footer__navigation">
      {items &&
        !validatingReferralCodes &&
        items.map(({ label, url, target }, index) => (
          <Nav.Item className="footer__navigation-item" key={index}>
            <a
              href={url + referralQueryParams}
              target={target || "_blank"}
              rel="noopener"
            >
              {label}
            </a>
          </Nav.Item>
        ))}
    </Nav>
  );
  return (
    <footer>
      <div className="container">
        <div className="wrap">
          {navItems}
          <div className="footer__legal">
            Coverage is underwritten by United National Insurance Company® in{" "}
            {unitedNationalInsuranceCompany.join(", ")}, which has a group
            rating of “A” (Excellent) by AM Best and a GBLI | Global Indemnity
            company.
            <br />
            Coverage is underwritten by Penn Patriot Insurance Company® in{" "}
            {pennPatriotInsuranceCompany.join(", ")}, which has a group rating
            of “A” (Excellent) by AM Best and a GBLI | Global Indemnity company.
            <br />
            Coverage is underwritten by Diamond State Insurance Company®,{" "}
            {diamondStateInsuranceCompany.join(", ")}, which has a group rating
            of “A” (Excellent) by AM Best and a GBLI | Global Indemnity company.
          </div>
          <div className="footer__copyright">
            Copyright © {format(new Date(), "yyyy")} Global Indemnity Group. All
            rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
