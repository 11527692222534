import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import Button from "@gbli-events/common/src/Components/Themed/Button";
import { additionalInsuredTypes } from "src/Constants/additionalInsuredTypes";

const ModalDeleteAdditionalInsured = ({
  show,
  type,
  onRemove,
  handleClose,
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Body className="pb-4">
        <Modal.Header className="w-100 border-0 pb-0" />
        <div className="mt-4 text-center">
          Are you sure you want to remove this additional insured?
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 pb-4">
        <Button type="button" variant="secondary" outline onClick={handleClose}>
          No
        </Button>
        <Button
          type="button"
          onClick={() => {
            onRemove(type);
            handleClose();
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalDeleteAdditionalInsured.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.oneOf(additionalInsuredTypes).isRequired,
  handleClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

ModalDeleteAdditionalInsured.defaultProps = {
  show: false,
};

export default ModalDeleteAdditionalInsured;
