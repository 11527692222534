/**
 * Makes a request to API server for a quote
 *
 * EXAMPLE OF HOW TO USE:
 * const priceService = new PriceService(new API());
 * priceService
 * .getQuote(myData)
 * .then(response => {
 *   console.log(response);
 * })
 * .catch(error => {
 *   console.log(error);
 * });
 */
class PriceService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getQuote(params) {
    return this.api.get({
      location: "/quote/calculate",
      params,
    });
  }

  getSavedQuote(id) {
    return this.api.get({
      location: `/quote/progress/${id}`,
    });
  }

  saveQuote(data) {
    return this.api.post({
      location: "/quote/progress",
      body: data,
    });
  }
}

export default PriceService;
