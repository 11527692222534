// Redux form main form initial values

import cloneDeep from "lodash/cloneDeep";
import { BY_SEARCH } from "./venueSearchTypes";
import { emptyVenueForm } from "src/Models/VenueFormModel";
import { companyTypeOptionsDict } from "@gbli-events/common/src/Constants/companyTypeOptions";
import { FORM_CONTROL_YES } from "@gbli-events/common/src/Constants/formControls";
import { ENTITY_PERSON } from "@gbli-events/common/src/Constants/entityTypes";

export const insuredInfoInitialValues = {
  insuredRenter: ENTITY_PERSON,
  contactSame: "1",
  payeeSame: FORM_CONTROL_YES,
  payeeRenter: ENTITY_PERSON,
  insuredFirstName: "",
  insuredLastName: "",
  insuredCompany: "",
  insuredCompanyType: companyTypeOptionsDict.individual.value,
  insuredAddress: "",
  insuredAddress2: "",
  insuredCity: "",
  insuredState: "",
  insuredZip: "",
  insuredCountry: "US",

  contactPhone: "",
  contactEmail: "",
  contactRenter: ENTITY_PERSON,
  contactFirstName: "",
  contactLastName: "",

  payeeCardNum: "",
  payeeCardName: "",
  payeeCardCVV: "",
  payeeCardExpYr: "",
  payeeCardExpMon: "",
  payeeFirstName: "",
  payeeLastName: "",
  payeeCompany: "",
  payeeAddress: "",
  payeeAddress2: "",
  payeeCity: "",
  payeeState: "",
  payeeZip: "",
  payeeCountry: "US",

  consentConfirmed: false,
};

const values = {
  venue: "",
  optionalVenues: [cloneDeep(emptyVenueForm)],
  venueSearchTypeRadio: BY_SEARCH,
  facilityCode: "",
  venueCode: "",
  manualVenueName: "",
  manualVenueAddress1: "",
  manualVenueCity: "",
  manualVenueState: "",
  manualVenueZip: "",
  manualVenueCountry: "US",
  eventTypeRadio: "",
  eventTypeSelect: null,
  eventName: "",
  eventFrequencyField: "continuous",
  eventDates: [],
  daysOfWeekField: [],
  eventDateRange: {
    startDate: null,
    endDate: null,
  },
  eventSetupDates: [],
  eventTeardownDates: [],
  venueMunicipalityCode: "",
  federalEntity: "no",
  kentuckyStateEntity: "no",
  generalLiabilityCoverage: { occurrence: null, aggregate: null },
  eventDailyGuests: "",
  eventPerformers: "no",
  eventPerformersCount: "0",
  eventGoods: "0",
  eventFood: "0",
  eventExhibition: "0",
  performersList: null,
  goodsVendorsList: null,
  foodVendorsList: null,
  exhibitorsList: null,
  performersFrequency: "",
  goodsVendorsFrequency: "",
  foodVendorsFrequency: "",
  exhibitorsFrequency: "",

  ...insuredInfoInitialValues,

  ACPersonalProperty: false,
  ACHostLiquor: null,
  ACPersonalAndAdvertisingLimit: true,
  ACProductsAndCompletedOperations: true,
  ACSellingLiquor: false,
  ACTerrorism: null,
  ACDamageToRentedProperty: null,
  ACWaiverOfTransferRights: false,
  cannabis: null,
  medical: true,
  weddingRehearsal: false,
  weddingBrunch: false,

  terrorismCoverageAgreement: false,
  consentConfirmed: false,
  northDakotaDisclosure: false,

  underwritingQuestions: [],

  quoteSaved: false,
  policyPurchased: false,
  venueLink: null,
};

const initialFormValues = () => cloneDeep(values);

export default initialFormValues;
