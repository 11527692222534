import React from "react";
import { useSelector } from "react-redux";
import { getEventSummary } from "src/Selectors/EventSummary";

const EventSummaryTable = () => {
  const eventSummary = useSelector(getEventSummary);

  return (
    <div className="summary-table summary-table--event-details">
      <h3 className="summary-table__heading text-left mb-3 font-30">
        Event Details:
      </h3>
      <div className="row mb-3">
        {eventSummary.detailItems.map((item) => (
          <div key={item.label} className="col-12 col-md-6 summary-table__item">
            <div className="summary-table__item-label">{item.label}:</div>
            <div className="summary-table__item-value">{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventSummaryTable;
