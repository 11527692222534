/**
 *
 * Middleware that gets auth user info (if user exists) from server and
 * dispatches events (ie to add them to a store, etc.)
 *
 */
import { ConnectedRouter } from "@jauntin/react-ui";
import API from "../Helpers/API";
import AuthService from "@gbli-events/common/src/Helpers/AuthService";
import { setAuthUser } from "../Actions/actions";

export default (store) => (next) => (action) => {
  if (
    action.type === ConnectedRouter.LOCATION_CHANGE &&
    store.getState().formPage.authUser.length === 0
  ) {
    return new AuthService(new API()).authLogin().then(
      (response) => {
        store.dispatch(setAuthUser(response.data));
        return next(action);
      },
      (err) => {
        store.dispatch(setAuthUser([]));
        return next(action);
      }
    );
  }
  return next(action);
};
