/**
 * Makes a request to API server to save user data entry
 *
 * EXAMPLE OF HOW TO USE:
 * const userEntryDataService = new UserEntryDataService(new API());
 * userEntryDataService
 * .saveEntryData(myData)
 * .then((response) => {
 *   console.log(response);
 * })
 * .catch((error) => {
 *   console.log(error);
 * });
 */
class UserEntryDataService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  saveEntryData(data) {
    return this.api.post({
      location: "/user-entry-data",
      body: data,
    });
  }
}

export default UserEntryDataService;
