import React from "react";
import PropTypes from "prop-types";
import {
  FieldInputProps,
  FieldMetaProps,
} from "@gbli-events/common/src/Constants/ReduxFormPropTypes";
import UnderwritingQuestionAlert from "./UnderwritingQuestionAlert";
import { SELECTION_REQUIRED } from "../../Constants/errorMessages";

const propTypes = {
  input: FieldInputProps,
  meta: FieldMetaProps,
  description: PropTypes.string.isRequired,
};

const YesNoQuestionField = ({ input, meta, description }) => (
  <div className="underwriting-questions__row">
    <div className="d-flex justify-content-between flex-nowrap underwriting-questions__field">
      <p className="underwriting-questions__text">{description}</p>
      <div className="underwriting-questions__controls">
        {meta.touched && meta.error === SELECTION_REQUIRED && (
          <div className="form-error">{meta.error}</div>
        )}
        <div className="d-flex">
          <div className="col-auto p-0">
            <div className="custom-control custom-radio">
              <input
                {...input}
                value="Yes"
                checked={input.value === "Yes"}
                id={`${input.name}Yes`}
                component="input"
                type="radio"
                className="custom-control-input"
              />
              <label
                className={`btn btn-outline-secondary underwriting-questions__radio ${
                  (meta.touched || meta.active) && meta.error
                    ? "btn--error"
                    : ""
                }`}
                htmlFor={`${input.name}Yes`}
              >
                Yes
              </label>
            </div>
          </div>
          <div className="col-auto p-0">
            <div className="custom-control custom-radio">
              <input
                {...input}
                value="No"
                checked={input.value === "No"}
                id={`${input.name}No`}
                type="radio"
                className="custom-control-input"
              />
              <label
                className={`btn btn-outline-secondary underwriting-questions__radio ${
                  (meta.touched || meta.active) && meta.error
                    ? "btn--error"
                    : ""
                }`}
                htmlFor={`${input.name}No`}
              >
                No
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    {meta.error && meta.error !== SELECTION_REQUIRED && (
      <UnderwritingQuestionAlert />
    )}
  </div>
);

YesNoQuestionField.propTypes = propTypes;

export default YesNoQuestionField;
