import PropTypes from "prop-types";

const additionalInsuredPropType = PropTypes.shape({
  companyName: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  country: PropTypes.string,
});

export default additionalInsuredPropType;
