class VenuePresetsService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getPresets(placeId) {
    return this.api.get({
      location: "/venue/byPlace",
      params: {
        id: placeId,
      },
    });
  }

  getPresetsByVenueCode(facilityCode, venueCode) {
    return this.api.get({
      location: "/venue/byVenueCode",
      params: {
        facilityCode,
        venueCode,
      },
    });
  }

  getCoordinates(zip) {
    return this.api.get({
      location: "https://maps.googleapis.com/maps/api/geocode/json",
      params: {
        address: zip,
        key: process.env.REACT_APP_GOOGLE_API_KEY,
      },
    });
  }

  getAdditionalCertHolders(code) {
    return this.api.get({
      location: "/certificateHolders",
      params: {
        code,
      },
    });
  }
}

export default VenuePresetsService;
