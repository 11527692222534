import { getFormMeta, getFormSyncErrors } from "redux-form";
import { formName } from "src/Constants/constants";

export const getFormValues = (state) => state.form.application.values;

export const getIsInMaintenanceMode = (state) => state.formPage.maintenanceMode;

export const isFieldTouched = (fieldName) => (state) =>
  getFormMeta(formName)(state)[fieldName]?.touched;

export const getFieldError = (fieldName) => (state) =>
  getFormSyncErrors(formName)(state)[fieldName];

export const getTouchedFieldError = (fieldName) => (state) => {
  const isTouched = isFieldTouched(fieldName)(state);
  const error = getFieldError(fieldName)(state);

  return error && isTouched;
};

export const getFieldMeta = (fieldName) => (state) => ({
  touched: isFieldTouched(fieldName)(state),
  error: getFieldError(fieldName)(state),
});
