import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { formName } from "../Constants/constants";
import initialFormValues from "../Constants/initialFormValues";
import Form from "../Components/Form/Form";
import {
  providersValidation,
  underwritingQuestionsValidation,
} from "@gbli-events/common/src/Helpers/validators";
import {
  setAndSearchVenueCode,
  setAndSearchProducerCode,
  setAndSearchFacilityCode,
} from "src/Actions/actions";
import { checkBoilerplateReferral } from "src/Actions/boilerplate";

const FormContainer = reduxForm({
  form: formName,
  initialValues: initialFormValues(),
  validate: (values) => {
    let errors = {};
    const providers = providersValidation(values);
    if (providers) errors = { ...providers };
    const underwritingQuestions = underwritingQuestionsValidation(values);
    if (underwritingQuestions) errors = { ...errors, ...underwritingQuestions };

    if (errors) return errors;

    return Object.values(errors).length ? errors : undefined;
  },
  shouldError: () => true,
})(Form);

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setAndSearchVenueCode: (search) => {
    dispatch(setAndSearchVenueCode(search));
  },
  setAndSearchProducerCode: (search) => {
    dispatch(setAndSearchProducerCode(search));
  },
  setAndSearchFacilityCode: (search) => {
    dispatch(setAndSearchFacilityCode(search));
  },
  checkBoilerplateReferral: (search) => {
    dispatch(checkBoilerplateReferral(search));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
