import { getFormSyncErrors } from "redux-form";
import { formName } from "src/Constants/constants";

export const getUnderwritingQuestionsLoaded = (state) =>
  Boolean(state.formPage.underwritingQuestions.length);

export const getUnderwritingQuestionFields = (state) =>
  state?.form?.application?.values?.underwritingQuestions || [];

export const isUnderwritingQuestionsPageValid = (state) => {
  const { underwritingQuestions: errors } = getFormSyncErrors(formName)(state);

  return errors ? Object.values(errors).every((error) => !error) : true;
};
