import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getIsEmbed } from "src/Selectors/Shared";
import { getFormChildPath } from "src/Helpers/URLParser";

export const useGoToFormPath = () => {
  const history = useHistory();
  const isEmbed = useSelector(getIsEmbed);

  return (path) => {
    history.push(getFormChildPath(path, isEmbed));
  };
};

export const useGetFormPath = (path) => {
  const isEmbed = useSelector(getIsEmbed);

  return getFormChildPath(path, isEmbed);
};
