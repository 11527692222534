import React, { useEffect } from "react";
import FormStepNavigation from "src/Components/FormStepNavigation";
import { useSelector, useDispatch } from "react-redux";
import { Field } from "redux-form";
import { DEDUCTIBLE_LIMIT_AMOUNT } from "@gbli-events/common/src/Constants/limits";
import {
  getVenueGlLimits,
  getIsLimitsPageValid,
  getGLItem,
  getPcoItem,
  getPersonalAdLimitItem,
  getDmgToRentedPremisesItem,
  getMedicalItem,
  getHostLiquorItem,
  getTerrorismItem,
  limitsHasErrors,
} from "src/Selectors/Limits";
import { preSelectDefaultLimits, confirmLimits } from "src/Actions/limits";
import { getVenueState } from "src/Selectors/Venue";
import { getDrpLimits } from "src/Selectors/AdditionalCoverages";
import {
  accepted,
  selectionRequired,
} from "@gbli-events/common/src/Helpers/validators";
import CurrencyHelper from "src/Helpers/CurrencyHelper";
import GeneralLiabilityField from "src/Components/Limits/GeneralLiabilityField";
import DamageToRentedPremisesField from "src/Components/Limits/DamageToRentedPremisesField";
import StyledCheckboxField from "@gbli-events/common/src/Components/FormElements/CheckboxField/StyledCheckboxField";
import RadioFieldGroup from "@gbli-events/common/src/Components/FormElements/RadioFieldGroup/RadioFieldGroup";
import Alert from "@gbli-events/common/src/Components/Themed/Alert";
import NavContinueButton from "src/Components/NavContinueButton";
import { saveUserEntryData } from "src/Actions/userEntryData";
import { getUserEntryDataPayload } from "src/Selectors/UserEntryData";

const Limits = () => {
  const limits = useSelector(getVenueGlLimits);
  const glItem = useSelector(getGLItem);
  const pcoItem = useSelector(getPcoItem);
  const personalAdLimitItem = useSelector(getPersonalAdLimitItem);
  const dmgToRentedPremiseItem = useSelector(getDmgToRentedPremisesItem);
  const medicalItem = useSelector(getMedicalItem);
  const hostLiquorItem = useSelector(getHostLiquorItem);
  const terrorismItem = useSelector(getTerrorismItem);
  const isLimitsPageValid = useSelector(getIsLimitsPageValid);
  const { ACTerrorism } = useSelector((state) => state.form.application.values);
  const drpValues = useSelector(getDrpLimits);
  const venueState = useSelector(getVenueState);
  const formHasErrors = useSelector(limitsHasErrors);
  const userEntryData = useSelector(getUserEntryDataPayload);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(preSelectDefaultLimits());
  }, [dispatch]);

  return (
    <div className="limits container">
      <FormStepNavigation />
      <div className="page-heading">
        <h2>Limits</h2>
      </div>

      <div className="limits__form-container">
        <div className="limits__form-row row">
          <div className="col-md-2 mb-xs-2 mb-md-0">
            <div className="font-20">{glItem.priceDescription}</div>
          </div>
          <div className="col-md-6 col-lg-7 mb-xs-3 mb-md-0">
            <div className="font-20 line-height-1 font-weight-bold">
              General Liability Coverage (Occurrence & Aggregate)
            </div>
            <div className="font-20 line-height-1">
              Coverage for claims related to bodily injury or property damage at
              your event
            </div>
          </div>
          <div className="limits__col-limit col-md-4 col-lg-3">
            <div className="limits__limit">
              <GeneralLiabilityField limits={limits} />
            </div>
          </div>
        </div>

        <div className="limits__form-row row">
          <div className="col-md-2 mb-xs-2 mb-md-0">
            <div className="font-20">{pcoItem.priceDescription}</div>
          </div>
          <div className="col-md-6 col-lg-7 mb-xs-3 mb-md-0">
            <div className="font-20 line-height-1 font-weight-bold">
              Product & Completed Operations
            </div>
            <div className="font-20 line-height-1">
              Coverage for claims related to product liability and operations
              away from the premises, such as food poisoning
            </div>
          </div>
          <div className="limits__col-limit col-md-4 col-lg-3">
            <div className="limits__limit">
              <div className="font-20 limits__limit-info">
                {pcoItem.coverageDescription}
              </div>
            </div>
          </div>
        </div>

        <div className="limits__form-row row">
          <div className="col-md-2 mb-xs-2 mb-md-0">
            <div className="font-20">
              {personalAdLimitItem.priceDescription}
            </div>
          </div>
          <div className="col-md-6 col-lg-7 mb-xs-3 mb-md-0">
            <div className="font-20 line-height-1 font-weight-bold">
              Personal Advertising
            </div>
            <div className="font-20 line-height-1">
              Coverage for infringement on personal or intellectual rights, such
              as claims for libel or slander
            </div>
          </div>
          <div className="limits__col-limit col-md-4 col-lg-3">
            <div className="limits__limit">
              <div className="font-20 limits__limit-info">
                {personalAdLimitItem.coverageDescription}
              </div>
            </div>
          </div>
        </div>

        <div className="limits__form-row row">
          <div className="col-md-2 mb-xs-2 mb-md-0">
            <div className="font-20">
              {dmgToRentedPremiseItem.priceDescription}
            </div>
          </div>
          <div className="col-md-6 col-lg-7 mb-xs-3 mb-md-0">
            <div className="font-20 line-height-1 font-weight-bold">
              Damage to Rented Premises
            </div>
            <div className="font-20 line-height-1">
              Coverage for unintentional property damage to the venue during
              your event
            </div>
          </div>
          <div className="limits__col-limit col-md-4 col-lg-3">
            <div className="limits__limit">
              <DamageToRentedPremisesField drpValues={drpValues} />
            </div>
          </div>
        </div>

        <div className="limits__form-row row">
          <div className="col-md-2 mb-xs-2 mb-md-0">
            <div className="font-20">{medicalItem.priceDescription}</div>
          </div>
          <div className="col-md-6 col-lg-7 mb-xs-3 mb-md-0">
            <div className="font-20 line-height-1 font-weight-bold">
              Medical Payments
            </div>
            <div className="font-20 line-height-1">
              Coverage for medical expenses due to injury at your event
            </div>
          </div>
          <div className="limits__col-limit col-md-4 col-lg-3">
            <div className="limits__limit">
              <div className="font-20 limits__limit-info">
                {medicalItem.coverageDescription}
              </div>
            </div>
          </div>
        </div>

        <div className="limits__form-row row">
          <div className="col-md-2 mb-xs-2 mb-md-0">
            <div className="font-20">{hostLiquorItem.priceDescription}</div>
          </div>
          <div className="col-md-6 col-lg-7 mb-xs-3 mb-md-0">
            <div className="font-20 line-height-1 font-weight-bold">
              Host Liquor
            </div>
            <div className="font-20 line-height-1">
              Coverage for liquor liability for the host of the event should you
              be brought into a lawsuit
            </div>
          </div>
          <div className="limits__col-limit col-md-4 col-lg-3">
            <div className="limits__limit">
              <div className="font-20 limits__limit-info">
                {hostLiquorItem.coverageDescription}
              </div>
            </div>
          </div>
        </div>

        <div className="limits__form-row row">
          <div className="col-md-2 mb-xs-2 mb-md-0"></div>
          <div className="col-md-6 col-lg-7 mb-xs-3 mb-md-0">
            <div className="font-20 line-height-1 font-weight-bold">
              Deductible
            </div>
            <div className="font-20 line-height-1">
              Out of pocket cost prior to payment of a claim
            </div>
          </div>
          <div className="limits__col-limit col-md-4 col-lg-3">
            <div className="limits__limit">
              <div className="font-20 limits__limit-info">
                {new CurrencyHelper(DEDUCTIBLE_LIMIT_AMOUNT)
                  .convertFromInt()
                  .formatDollars()}
              </div>
            </div>
          </div>
        </div>

        <div className="limits__form-row row">
          <div className="col-md-2 mb-xs-2 mb-md-0">
            <div className="font-20">
              {terrorismItem.terrorismRate || terrorismItem.priceDescription}
            </div>
          </div>
          <div className="col-md-10 col-lg-10">
            <div className="font-20 line-height-1 font-weight-bold">
              Terrorism (affects premium price)
            </div>
            <div className="font-20 line-height-1 mb-3">
              Coverage for acts of terrorism during your event
            </div>
            <div className="limits__terrorism-controls mb-xs-3 mb-lg-0">
              <RadioFieldGroup
                controlName="ACTerrorism"
                validators={[selectionRequired]}
                options={[
                  { label: "Accept Coverage", value: "1" },
                  { label: "Decline Coverage", value: "0" },
                ]}
              />
            </div>
            <div className="limits__terrorism-disclosure">
              {venueState === "OH" ? (
                <>
                  <>
                    <p className="label">
                      POLICYHOLDER DISCLOSURE NOTICE OF ACTS OF TERRORISM
                      COVERAGE
                    </p>
                    <p className="mb-3">
                      You are hereby notified that under the Terrorism Risk
                      Insurance Act, as amended, you have a right to purchase
                      insurance coverage for losses resulting from acts of
                      terrorism. As defined in Section 102(1) of the Act: The
                      term “act of terrorism” means any act or acts that are
                      certified by the Secretary of the Treasury — in
                      consultation with the Secretary of Homeland Security, and
                      the Attorney General of the United States — to be an act
                      of terrorism; to be a violent act or an act that is
                      dangerous to human life, property, or infrastructure; to
                      have resulted in damage within the United States, or
                      outside the United States in the case of certain air
                      carriers or vessels or the premises of a United States
                      mission; and to have been committed by an individual or
                      individuals as part of an effort to coerce the civilian
                      population of the United States or to influence the policy
                      or affect the conduct of the United States Government by
                      coercion.
                      <br /> You should know that where coverage is provided by
                      this policy for any losses resulting from certified acts
                      of terrorism, such losses may be partially reimbursed by
                      the United States Government under a formula established
                      by the Terrorism Risk Insurance Act, as amended. However,
                      your policy may contain other exclusions which might
                      affect your coverage, such as an exclusion for nuclear
                      events. Under the formula, the United States Government
                      generally reimburses 80% beginning on January 1, 2020, of
                      covered terrorism losses exceeding the statutorily
                      established deductible paid by the insurance company
                      providing the coverage. You should also know that the
                      Terrorism Risk Insurance Act, as amended, contains a $100
                      billion cap that limits U.S. Government reimbursement as
                      well as insurers’ liability for losses resulting from
                      certified acts of terrorism when the amount of such losses
                      exceeds $100 billion in any one calendar year. If the
                      aggregate insured losses for all insurers exceed $100
                      billion, your coverage may be reduced.
                    </p>
                  </>
                </>
              ) : (
                ACTerrorism !== null && (
                  <>
                    {ACTerrorism === "1" ? (
                      <>
                        <p className="label">
                          POLICYHOLDER DISCLOSURE NOTICE OF ACTS OF TERRORISM
                          COVERAGE
                        </p>
                        <p className="mb-3">
                          Coverage for acts of terrorism is included in your
                          policy. You are hereby notified that under the
                          Terrorism Risk Insurance Act, as amended in 2015, the
                          definition of act of terrorism has changed. As defined
                          in Section 102(1) of the Act: The term “act of
                          terrorism” means any act or acts that are certified by
                          the Secretary of the Treasury—in consultation with the
                          Secretary of Homeland Security, and the Attorney
                          General of the United States—to be an act of
                          terrorism; to be a violent act or an act that is
                          dangerous to human life, property, or infrastructure;
                          to have resulted in damage within the United States,
                          or outside the United States in the case of certain
                          air carriers or vessels or the premises of a United
                          States mission; and to have been committed by an
                          individual or individuals as part of an effort to
                          coerce the civilian population of the United States or
                          to influence the policy or affect the conduct of the
                          United States Government by coercion. You should know
                          that where coverage is provided by this policy for any
                          losses resulting from certified acts of terrorism,
                          such losses may be partially reimbursed by the United
                          States Government under a formula established by the
                          Terrorism Risk Insurance Act, as amended. However,
                          your policy may contain other exclusions which might
                          affect your coverage, such as an exclusion for nuclear
                          events. Under the formula, the United States
                          Government generally reimburses 80% beginning on
                          January 1, 2020, of covered terrorism losses exceeding
                          the statutorily established deductible paid by the
                          insurance company providing the coverage. You should
                          also know that the Terrorism Risk Insurance Act, as
                          amended, contains a $100 billion cap that limits U.S.
                          Government reimbursement as well as insurers’
                          liability for losses resulting from certified acts of
                          terrorism when the amount of such losses exceeds $100
                          billion in any one calendar year. If the aggregate
                          insured losses for all insurers exceed $100 billion,
                          your coverage may be reduced. The portion of your
                          annual premium that is attributable to coverage for
                          acts of terrorism does not include any charges for the
                          portion of losses covered by the United States
                          government under the Act.
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="label">
                          POLICYHOLDER DISCLOSURE NOTICE OF TERRORISM EXCLUSION
                        </p>
                        <p className="mb-3">
                          You were notified that under the Terrorism Risk
                          Insurance Act, as amended, you had a right to purchase
                          insurance coverage for losses arising out of acts of
                          terrorism. You opted not to purchase this coverage.
                          The War or Terrorism Exclusions that are a part of
                          this policy are therefore in full force and effect.
                          Even though you chose not to purchase this coverage,
                          we must alert you that as defined in Section 102(1) of
                          the Act: The term “act of terrorism” means any act or
                          acts that are certified by the Secretary of the
                          Treasury—in consultation with the Secretary of
                          Homeland Security, and the Attorney General of the
                          United States—to be an act of terrorism; to be a
                          violent act or an act that is dangerous to human life,
                          property, or infrastructure; to have resulted in
                          damage within the United States, or outside the United
                          States in the case of certain air carriers or vessels
                          or the premises of a United States mission; and to
                          have been committed by an individual or individuals as
                          part of an effort to coerce the civilian population of
                          the United States or to influence the policy or affect
                          the conduct of the United States Government by
                          coercion. You should know that any coverage for losses
                          resulting from certified acts of terrorism may be
                          partially reimbursed by the United States Government
                          under a formula established by the Terrorism Risk
                          Insurance Act, as amended. Under the formula, the
                          United States Government generally reimburses 80%
                          beginning on January 1, 2020, of covered terrorism
                          losses exceeding the statutorily established
                          deductible paid by the insurance company providing the
                          coverage. The premium charged for this coverage may
                          vary and does not include any charges for the portion
                          of loss covered by the federal government under the
                          Act. Coverage for “insured losses”, as defined in the
                          Act, is subject to the coverage terms, conditions,
                          amounts and limits in this policy applicable to losses
                          arising from events other than acts of terrorism. The
                          Terrorism Risk Insurance Act, as amended, contains a
                          $100 billion cap that limits U.S. Government
                          reimbursement as well as insurers’ liability for
                          losses resulting from certified acts of terrorism when
                          the amount of such losses exceeds $100 billion in any
                          one calendar year. If the aggregate insured losses for
                          all insurers exceed $100 billion, your coverage may be
                          reduced.
                        </p>
                      </>
                    )}
                  </>
                )
              )}
            </div>
            {ACTerrorism !== null && (
              <Field
                validate={accepted}
                name="terrorismCoverageAgreement"
                component={StyledCheckboxField}
                className="limits__terrorism-agreement"
                label="I have read the above disclosure and agree to proceed."
              />
            )}
          </div>
        </div>

        {formHasErrors && (
          <Alert variant="danger" className="mt--40">
            <i className="far fa-exclamation-circle alert__icon" />
            <div className="alert__text">Please correct the errors above.</div>
          </Alert>
        )}
      </div>

      <NavContinueButton
        disabled={formHasErrors}
        onClick={() => {
          dispatch(confirmLimits());
          if (isLimitsPageValid) saveUserEntryData(userEntryData);

          return isLimitsPageValid;
        }}
      />
    </div>
  );
};

export default Limits;
