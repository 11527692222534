import React from "react";
import useScript from "src/Helpers/Hooks";
import FormContainer from "src/Containers/FormContainer";

const Embed = () => {
  useScript(
    "https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.4/iframeResizer.contentWindow.min.js"
  );

  return (
    <>
      <FormContainer />
    </>
  );
};

export default Embed;
