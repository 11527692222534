import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@gbli-events/common/src/Components/Themed/Button";
import { useHistory } from "react-router-dom";
import AsyncButton from "@gbli-events/common/src/Components/AsyncButton";
import API from "../../Helpers/API";
import PaymentService from "../../Helpers/PaymentService";
import { DOCUMENT_DOWNLOAD_WINDOW } from "../../Constants/constants";
import {
  GBLI_EMAIL_EVENTS,
  GBLI_PHONE_EVENTS_TITLE,
} from "@gbli-events/common/src/Constants/shared";
import { getFormChildPath } from "src/Helpers/URLParser";
import { VENUE_SELECTION_URL } from "../../Constants/constants";
import { usePollDocuments } from "@jauntin/react-ui";
import download from "downloadjs";

const ThankYou = ({
  orderNumber,
  policyNumber,
  email,
  pdfLink,
  downloaded,
  setDownloaded,
  insuredName,
  timeoutCounter,
  incrementCounter,
  showTimeoutMessage,
  setShowTimeoutMessage,
  isEmbed,
  themeSlug,
}) => {
  const history = useHistory();

  const [downloadedAlert, setDownloadedAlert] = useState(false);

  const stopCounter = useCallback(
    () => incrementCounter(0),
    [incrementCounter]
  );

  const onDownloaded = () => {
    stopCounter();
    setDownloaded(true);
  };

  const onDownloadError = () => {
    stopCounter();
    setDownloaded(false);
  };

  const { downloading, startDownload } = usePollDocuments({
    getDocumentsStatus: () =>
      new PaymentService(new API()).getDocumentsStatus(orderNumber),
    getDocuments: () => new PaymentService(new API()).getPolicyDocs(pdfLink),
    downloadFn: download,
    fileDownloadName: `${insuredName}-Event-Insurance-Documents.zip`,
    onDownloaded,
    onError: onDownloadError,
  });

  useEffect(() => {
    let timer;
    if (timeoutCounter >= DOCUMENT_DOWNLOAD_WINDOW) {
      stopCounter();
      setShowTimeoutMessage();
    }
    if (timeoutCounter) {
      timer = setTimeout(() => {
        incrementCounter(timeoutCounter + 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [timeoutCounter, incrementCounter, stopCounter, setShowTimeoutMessage]);

  return (
    <div className="thank-you">
      {!showTimeoutMessage ? (
        <>
          <div className="container">
            {orderNumber && email ? (
              <>
                <div className="page-heading">
                  <h2>Thank you!</h2>
                  <h3>
                    Your event coverage was processed successfully. <br /> Your
                    policy number is {policyNumber}
                  </h3>
                </div>
                <p className="text-center mw--700 mx-auto mb-5">
                  You will receive an email with your policy documents within 30
                  minutes. You can also download your policy documents by
                  clicking the button below.
                </p>
                <div className="d-flex justify-content-center mb-5">
                  {!downloaded && (
                    <AsyncButton
                      spinning={downloading}
                      spinningText="Downloading"
                      onClick={startDownload}
                    >
                      Download Policy Documents
                    </AsyncButton>
                  )}
                  {downloaded && (
                    <div className="d-flex flex-column align-items-center">
                      <Button
                        type="button"
                        onClick={() => setDownloadedAlert(true)}
                      >
                        <>
                          <i className="fal fa-check-circle font-20 mr-2" />
                          Download Successful!
                        </>
                      </Button>
                      <div>
                        {downloadedAlert ? (
                          <div className="text-danger mt-3">
                            You have already downloaded your policy document.
                          </div>
                        ) : (
                          <div className="mt-3">
                            Can&apos;t find your policy download on your
                            computer? Check your downloads folder.
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <hr className="border-color-blue-secondary mb-5" />
                <p className="mw--435 text-center mb-3 mx-auto">
                  If you have any questions, please contact us at{" "}
                  {GBLI_EMAIL_EVENTS} or {GBLI_PHONE_EVENTS_TITLE}.
                </p>
                <p className="mw--435 text-center mx-auto">
                  All the best with your event!
                </p>
              </>
            ) : (
              <div className="component text-center">
                <h2 className="pt-3">Oops!</h2>
                <h6 className="h7">Are you looking for an expired page?</h6>
                <p className="mt-4">
                  If you recently purchased insurance, check your email for your
                  policy document, otherwise, click here to purchase insurance
                  for your event:
                </p>

                <Button
                  onClick={() => {
                    history.push(
                      getFormChildPath(VENUE_SELECTION_URL, isEmbed, themeSlug)
                    );
                  }}
                  className="my-3"
                >
                  Get Insurance!
                </Button>

                <h3 className="h3 mt-5">Questions?</h3>
                <p>
                  If you have any questions please call us at{" "}
                  {GBLI_PHONE_EVENTS_TITLE}.
                </p>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="downloadExpired__content">
          <img
            src="/images/expired-icon.svg"
            alt="computer"
            className="downloadExpired__img"
          />
          <h1 className="downloadExpired__title">
            Your download link has expired.
          </h1>
          <p className="text-dark">
            Good news, though! Your policy documents have been
          </p>
          <p className="text-dark">emailed to the address you have provided.</p>
        </div>
      )}
    </div>
  );
};

ThankYou.propTypes = {
  orderNumber: PropTypes.string,
  policyNumber: PropTypes.string,
  email: PropTypes.string,
  pdfLink: PropTypes.string,
  downloaded: PropTypes.bool.isRequired,
  setDownloaded: PropTypes.func.isRequired,
  timeoutCounter: PropTypes.number.isRequired,
  incrementCounter: PropTypes.func.isRequired,
  showTimeoutMessage: PropTypes.bool.isRequired,
  setShowTimeoutMessage: PropTypes.func.isRequired,
  isEmbed: PropTypes.bool.isRequired,
  themeSlug: PropTypes.string,
};

ThankYou.defaultProps = {
  orderNumber: null,
  policyNumber: null,
  email: null,
  pdfLink: null,
};

export default ThankYou;
