/**
 * Middleware used to reset the redux form in some situations, ie return back to home page means the user
 * is starting the application new, so wipe it.
 */
import { UNREGISTER_FIELD } from "redux-form/lib/actionTypes";
import { reset, updateSyncErrors } from "redux-form";
import { formName } from "../Constants/constants";
import Debounce from "../Helpers/Debounce";
import {
  ROUTE_GUARD_BLOCKED,
  PAGE_NOT_FOUND,
} from "@gbli-events/common/src/Actions/shared";
import {
  RESET_APPLICATION_FORM,
  setAndSearchFacilityCode,
  setAndSearchProducerCode,
  setAndSearchVenueCode,
} from "src/Actions/actions";
import { getReferralQueryParams } from "src/Selectors/Shared";

const debounce = new Debounce({ period: 300 });

export default (store) => (next) => (action) => {
  next(action);

  switch (action.type) {
    case ROUTE_GUARD_BLOCKED:
    case PAGE_NOT_FOUND:
    case RESET_APPLICATION_FORM:
      // Preserve the referral
      const referralQueryParams = getReferralQueryParams(store.getState());

      if (referralQueryParams) {
        store.dispatch(setAndSearchProducerCode(referralQueryParams));
        store.dispatch(setAndSearchVenueCode(referralQueryParams));
        store.dispatch(setAndSearchFacilityCode(referralQueryParams));
      }

      store.dispatch(reset(formName));
      break;
    default:
      break;
  }

  if (action.type === UNREGISTER_FIELD) {
    // Sometimes syncErrors don't get updated when fields are unregistered.
    // But we rely on that
    debounce.do(() =>
      store.dispatch(
        updateSyncErrors(formName, store.getState().form[formName].syncErrors)
      )
    );
  }
};
