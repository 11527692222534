/**
 *
 * Middleware that restores the referred venue when form resets
 *
 */
import { RESET_APPLICATION_FORM, searchByVenueCode } from "src/Actions/actions";

export default (store) => (next) => (action) => {
  const { venueCode, facilityCode } = store.getState().formPage.referral;

  if (action.type === RESET_APPLICATION_FORM && venueCode && facilityCode) {
    store.dispatch(searchByVenueCode({ facilityCode, venueCode }));
  }
  return next(action);
};
