class SamplePolicyService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getSamplePolicy(venueState) {
    return this.api.post({
      location: "/sample-policy-documents",
      body: {
        state: venueState,
      },
    });
  }
}

export default SamplePolicyService;
