import {
  FORM_URL,
  FORM_EMBED_URL,
  VENUE_SELECTION_URL,
  VENUE_CODE_PARAM,
  PRODUCER_CODE_PARAM,
} from "src/Constants/constants";

import { isEmpty } from "lodash";

export const getFormParentPath = () => "/";

export const getFormRelativePath = (path) => {
  const relativePath = path.substring(path.lastIndexOf("/") + 1);

  return relativePath === "embed" ? VENUE_SELECTION_URL : relativePath;
};

const getBaseChildPath = (childPath, isEmbed) => {
  const root = isEmbed ? FORM_EMBED_URL : FORM_URL;
  let result;

  if (childPath === VENUE_SELECTION_URL) {
    result = root;
  } else {
    result = `${root === FORM_EMBED_URL ? `${root}/` : root}${childPath}`;
  }

  return result;
};

export const getFormChildPath = (childPath, isEmbed) =>
  getBaseChildPath(childPath, isEmbed);

export const getFormChildPathDefinition = (childPath, isEmbed) =>
  getBaseChildPath(childPath, isEmbed);

// return string with GET parameters pro and prop
// need to add this string into external URL (navigation links)
export const getValidatedQueryString = ({ search }) => {
  const urlParams = new URLSearchParams(search);
  const venueCode = urlParams.get(VENUE_CODE_PARAM);
  const producerCode = urlParams.get(PRODUCER_CODE_PARAM);

  let query = {};
  if (venueCode) {
    query[VENUE_CODE_PARAM] = venueCode;
  }
  if (producerCode) {
    query[PRODUCER_CODE_PARAM] = producerCode;
  }

  return !isEmpty(query) ? `?${new URLSearchParams(query).toString()}` : "";
};
