import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { usStatePropType } from "./StateField";
import SelectListField from "@gbli-events/common/src/Components/FormElements/SelectListField/SelectListField";
import { useSelector } from "react-redux";
import { useTheme } from "styled-components";
import { getUsStates } from "../../Selectors/Shared";
import { createStateOptions } from "../../Helpers/StateSelectOptions";

const SamplePolicyDocument = ({
  pdfLink,
  getSamplePolicyLink,
  clearSamplePolicyLink,
}) => {
  const [showLink, setShowLink] = useState(false);
  const [thinking, setThinking] = useState(null);
  const theme = useTheme();
  const usStates = useSelector(getUsStates).filter((state) => !state.blocked);
  const usStatesOptions = createStateOptions(usStates);

  return (
    <>
      <div>
        <div className="row mr-0">
          <div className="col-lg-8 my-5">
            <div className="card samplePolicy__card">
              <div className="card-body">
                <h5 className="samplePolicy__content">
                  Insured Venue Location
                </h5>

                <Field
                  component={SelectListField}
                  name="venueState"
                  options={usStatesOptions}
                  searchable
                  theme={theme}
                  placeholder="State"
                  onChange={(value) => {
                    setShowLink(false);
                    setThinking(true);
                    if (value) {
                      clearSamplePolicyLink();
                      getSamplePolicyLink(value, setShowLink, setThinking);
                    } else {
                      clearSamplePolicyLink();
                    }
                  }}
                />

                {thinking && (
                  <div className="mt-3">
                    <span>
                      <i className="fas fa-circle-notch fa-spin spinner" />
                    </span>
                    <span className="mx-2">loading documents...</span>
                  </div>
                )}

                {showLink && (
                  <>
                    <hr className="samplePolicy__border" />
                    <h5 className="my-2 samplePolicy__content">
                      View or download policy form
                    </h5>
                    <a href={pdfLink} target="_blank" rel="noopener noreferrer">
                      <div className="d-flex samplePolicy__link">
                        <div>
                          <i className="far fa-file-pdf" />
                        </div>
                        <div className="ml-2">
                          Policy Coverage Specimen Special Insurance Event
                          Insurance
                        </div>
                      </div>
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SamplePolicyDocument.propTypes = {
  usStates: usStatePropType.isRequired,
  pdfLink: PropTypes.string.isRequired,
  getSamplePolicyLink: PropTypes.func.isRequired,
  clearSamplePolicyLink: PropTypes.func.isRequired,
};

export default SamplePolicyDocument;
