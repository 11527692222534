import { touch } from "redux-form";
import { formName } from "src/Constants/constants";

export const SELECT_EVENT_TYPE = "SELECT_EVENT_TYPE";

export const selectEventType = (eventType) => ({
  type: SELECT_EVENT_TYPE,
  payload: eventType,
});

export const FETCH_EVENT_TYPE_VALIDATION_IN_PROGRESS =
  "FETCH_EVENT_TYPE_VALIDATION_IN_PROGRESS";

export const fetchEventTypeValidationInProgress = () => ({
  type: FETCH_EVENT_TYPE_VALIDATION_IN_PROGRESS,
});

export const FETCH_EVENT_TYPE_VALIDATION_SUCCESS =
  "FETCH_EVENT_TYPE_VALIDATION_SUCCESS";

export const fetchEventTypeValidationSuccess = () => ({
  type: FETCH_EVENT_TYPE_VALIDATION_SUCCESS,
});

export const FETCH_EVENT_TYPE_VALIDATION_ERROR =
  "FETCH_EVENT_TYPE_VALIDATION_ERROR";

export const fetchEventTypeValidationError = (error) => ({
  type: FETCH_EVENT_TYPE_VALIDATION_ERROR,
  payload: error,
});

export const confirmVenueEventForm = () => (dispatch) => {
  dispatch(touch(formName, "venue", "eventType", "cannabis"));
};

export const confirmEventAndUQ = () => (dispatch, getState) => {
  const state = getState();
  const fields = ["eventName", "eventDailyGuests", "eventDateRange"];
  state.form.application.values.underwritingQuestions.forEach((_, i) => {
    fields.push(`underwritingQuestions.${i}.answer`);
  });
  dispatch(touch(formName, ...fields));
};
