/**
 * Middleware used to prevent access to checkout page unless form is complete to formpage2
 */
import { ConnectedRouter } from "@jauntin/react-ui";
import RouteParser from "../Helpers/RouteParser";

export default (store) => (next) => (action) => {
  if (action.type === ConnectedRouter.LOCATION_CHANGE) {
    const routeParser = RouteParser.create(action.payload.location.pathname);
    const formPageOneCheck = store.getState().formPage.formPartOne.complete;
    if (routeParser._routeParts.formPart === "checkout" && !formPageOneCheck) {
      return store.dispatch(ConnectedRouter.push("/"));
    }
  }
  return next(action);
};
