import {
  BOILERPLATE_UUID_PARAM,
  BOILERPLATE_TASK_ID_PARAM,
  BOILERPLATE_ASSIGNMENT_ID_PARAM,
  BOILERPLATE_SIGNATURE_PARAM,
} from "src/Constants/constants";

export const SET_BOILERPLATE_REFERRAL = "SET_BOILERPALTE_REFERRAL";
export const setBoilerplateReferral = (data) => {
  return { type: SET_BOILERPLATE_REFERRAL, payload: data };
};

export const checkBoilerplateReferral = (search) => {
  return (dispatch) => {
    const rawParams = search.toLowerCase();
    const urlParams = new URLSearchParams(rawParams);

    const uuid = urlParams.get(BOILERPLATE_UUID_PARAM);
    const aid = urlParams.get(BOILERPLATE_ASSIGNMENT_ID_PARAM);
    const tid = urlParams.get(BOILERPLATE_TASK_ID_PARAM);
    const signature = urlParams.get(BOILERPLATE_SIGNATURE_PARAM);

    if (uuid) {
      dispatch(
        setBoilerplateReferral({
          uuid,
          aid,
          tid,
          signature,
        })
      );
    }
  };
};
