import Storage from "./Storage";
import Debounce from "./Debounce";
import { buildTime } from "../Constants/constants";

const debouncePeriod = 1500;
const itemName = "state";

/**
 * Redux store subscription that saves the current store state in sessionStorage
 *
 * Uses a debouncer
 *
 */
class StateStorage {
  _saveState = (state) => {
    Storage.setItem(itemName, state);
  };

  // Load the state
  // But in a non-dev environment, avoid loading state that was
  // saved in a previous build.
  loadState = (filter = (o) => o) => {
    if (
      process.env.NODE_ENV !== "development" &&
      Storage.getItem("buildTime") !== buildTime
    ) {
      Storage.setItem("buildTime", buildTime);
      return undefined;
    }
    return filter(Storage.getItem(itemName)) || undefined;
  };

  clearState = () => Storage.removeItem(itemName);

  subscribeToStore = (store) => {
    const debounce = new Debounce({ period: debouncePeriod });
    store.subscribe(() => {
      debounce.do(() => {
        const { router, ...rest } = store.getState();
        this._saveState(rest);
      });
    });
  };
}

const instance = new StateStorage();
export default instance;
