import { insuredInfoInitialState } from "src/Constants/formPageInitialState";
import { insuredInfoInitialValues } from "src/Constants/initialFormValues";
import { formName, saveQuoteFormName } from "src/Constants/constants";
import { cloneDeep } from "lodash";
import { getTotalAmount, getSurcharges } from "src/Selectors/Quote";
import getPaymentBody from "src/Helpers/ExtractFormState";

const excludeKeys = (obj, keys) =>
  Object.entries(obj)
    .filter(([key]) => !keys.includes(key))
    .reduce((result, [key, value]) => {
      result[key] = value;
      return result;
    }, {});

const includeKeys = (obj, keys) =>
  Object.entries(obj)
    .filter(([key]) => keys.includes(key))
    .reduce((result, [key, value]) => {
      result[key] = value;
      return result;
    }, {});

export const getQuoteDataPayload = (state) => {
  const quoteTotal = getTotalAmount(state);
  const surcharges = getSurcharges(state);

  const data = includeKeys(
    { ...getPaymentBody(state), quoteTotal, surcharges },
    [
      "quoteTotal",
      "surcharges",
      "venueSearchType",
      "isKentuckyEntity",
      "isFederalEntity",
      "eventType",
      "includeRehearsalDinner",
      "includeBreakfastAfterWedding",
      "gll",
      "name",
      "dates",
      "setUpDates",
      "tearDownDates",
      "eventFrequency",
      "averageDailyAttendance",
      "additionalCoverage",
      "venueAddress",
      "sourceCode",
      "producerCode",
      "subProducerCode",
      "underwritingQuestions",
      "waiverOfTransferRights",
      "facilityCode",
      "additionalInsureds",
    ]
  );

  return data;
};

export const getSaveQuotePayload = (state) => {
  let newState = cloneDeep(state);

  newState = includeKeys(newState, ["form", "formPage"]);

  // Clear sensitive info
  newState.form[formName].values = excludeKeys(
    newState.form[formName].values,
    Object.keys(insuredInfoInitialValues)
  );

  // Clear sensitive info and unnecessary data
  newState.formPage = excludeKeys(
    newState.formPage,
    Object.keys(insuredInfoInitialState).concat([
      "theme",
      "underwritingQuestions",
      "usStates",
      "insuranceCompanies",
      "eventTypes",
    ])
  );

  // Ensure sensitive fields are pristine
  newState.form[formName].fields = excludeKeys(
    newState.form[formName].fields,
    Object.keys(insuredInfoInitialValues)
  );

  const formData = getQuoteDataPayload(state);

  return {
    data: {
      state: newState,
      formData,
    },
    email: state.form[saveQuoteFormName]?.values?.email,
  };
};
