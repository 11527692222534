// Reconstitutes a stringified and destringified JSON version of form values.
import cloneDeep from "lodash/cloneDeep";
import { formName } from "../Constants/constants";
import { allInitialState } from "../Constants/formPageInitialState";

// Some values must be reset on load. For example isloading properties
const resetIsLoading = (o) => {
  const val = { ...o };
  if (val && val.formPage && val.formPage.summary) {
    val.formPage.summary.isLoading = false;
  }
  if (val && val.formPage && val.formPage.paymentInfo) {
    val.formPage.paymentInfo.isLoading = false;
  }
  return val;
};

const removeSensitiveData = (o) => {
  const val = { ...o };
  if (!val || !val.form || !val.form[formName]) return val;

  if (val.form[formName].values) {
    delete val.form[formName].values.payeeCardNum;
    delete val.form[formName].values.payeeCardName;
    delete val.form[formName].values.payeeCardExpMon;
    delete val.form[formName].values.payeeCardExpYr;
    delete val.form[formName].values.payeeCardCVV;
    delete val.form[formName].values.excludedCheck;
    delete val.form[formName].values.consentCheck;
  }
  if (val.form[formName].fields) {
    delete val.form[formName].fields.payeeCardNum;
    delete val.form[formName].fields.payeeCardName;
    delete val.form[formName].fields.payeeCardExp;
    delete val.form[formName].fields.payeeCardCVV;
  }

  const initialState = allInitialState();
  // Because card info cannot be stored, also ensure paymentInfo is not 'confirmed'
  val.formPage.paymentInfo.confirmed = initialState.paymentInfo.confirmed;
  val.formPage.formPartTwo.complete = initialState.formPartTwo.complete;

  val.formPage.appDataError = false;
  val.formPage.maintenanceMode = false;

  return val;
};

const FormValueFilter = (o) => {
  return removeSensitiveData(resetIsLoading(cloneDeep(o)));
};

export default FormValueFilter;
