import styled from "styled-components";

const StyledRadioFieldGroup = styled.div`
  .radio-large .custom-control-label {
    &::before {
      border-radius: ${({ theme: { components } }) =>
        components.RadioFieldGroup?.borderRadius};
      border-color: ${({ theme: { components } }) =>
        components.RadioFieldGroup?.radioColor};
    }
  }

  .radio-large .custom-control-input:checked ~ .custom-control-label {
    &::before {
      border-color: ${({ theme: { components } }) =>
        components.RadioFieldGroup?.radioColorActive};
      box-shadow: 0 0 0 1px
        ${({ theme: { components } }) =>
          components.RadioFieldGroup?.radioColorActive};
    }
    &::after {
      border-radius: ${({ theme: { components } }) =>
        components.RadioFieldGroup?.checkRadius};
      background-color: ${({ theme: { components } }) =>
        components.RadioFieldGroup?.radioColorActive};
    }
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    outline: 3px solid
      ${({ theme: { components } }) =>
        components.RadioFieldGroup?.radioColorActive};
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: ${({ theme: { components } }) =>
      components.RadioFieldGroup?.radioColorSecondaryActive};
    border-color: ${({ theme: { components } }) =>
      components.RadioFieldGroup?.radioColorSecondaryActive};
  }

  ${({ theme }) => theme.components.RadioFieldGroup?.customCss}
`;

export default StyledRadioFieldGroup;
