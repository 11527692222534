import React from "react";
import {
  GBLI_PHONE_EVENTS_TITLE,
  GBLI_EMAIL_EVENTS,
} from "@gbli-events/common/src/Constants/shared";
import Alert from "@gbli-events/common/src/Components/Themed/Alert";

const UnderwritingQuestionAlert = () => (
  <Alert variant="error" className="mt-3">
    <i className="far fa-exclamation-circle alert__icon" />
    <div className="alert__text">
      Unfortunately, based on the information provided a policy can’t be bound
      online. Please contact us at{" "}
      <span className="text-nowrap">{GBLI_PHONE_EVENTS_TITLE}</span> or{" "}
      {GBLI_EMAIL_EVENTS} for further assistance.
    </div>
  </Alert>
);

export default UnderwritingQuestionAlert;
