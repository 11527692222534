import { CHANGE, UNREGISTER_FIELD } from "redux-form/lib/actionTypes";
import { cloneDeep, isEmpty } from "lodash";
import { WAIVER_OF_TRANSFER_RIGHTS } from "@gbli-events/common/src/Constants/additionalCoverages";
import { updateEventDatesFromOtherFields } from "@gbli-events/common/src/Reducers/formReducers";
import {
  formName,
  cancelFormName,
  samplePolicyFormName,
  saveQuoteFormName,
} from "../Constants/constants";
import {
  SET_ADDITIONAL_COVERAGES_PRESETS,
  DEFAULT_ADDITIONAL_COVERAGES_FORM,
  DELETE_DATE,
  SET_VENUE_CODE,
  SET_VENUE_LINK,
} from "../Actions/actions";
import {
  UPDATE_BLOCKED_OPTIONAL_VENUE,
  UPDATE_OPTIONAL_VENUE_ERROR,
  CONFIRM_OPTIONAL_VENUE,
  CLEAR_OPTIONAL_VENUE,
} from "../Actions/optionalVenues";
import initialFormValues from "../Constants/initialFormValues";
import { BY_VENUE_CODE } from "../Constants/venueSearchTypes";
import { emptyVenueForm } from "src/Models/VenueFormModel";
import { SELECT_EVENT_TYPE } from "src/Actions/events";
import {
  getSetupAndTeardownDates,
  updateBlockedOptionalVenue,
  updateOptionalVenueError,
  confirmOptionalVenue,
} from "@gbli-events/common/src/Reducers/formReducers";
import { SET_UNDERWRITING_QUESTIONS } from "src/Actions/underwritingQuestions";
import { ConnectedRouter } from "@jauntin/react-ui";
import refundCancelInitialFormValues from "src/Constants/refundCancelInitialFormValues";
import samplePolicyInitialFormValues from "src/Constants/samplePolicyInitialFormValues";
import saveQuoteInitialFormValues from "src/Constants/saveQuoteInitialFormValues";
import { dateHelpers } from "@jauntin/utilities";

const { sortDays, filterToUniqueDays } = dateHelpers;

// Default Additional Coverages state if no venue specific presets
const defaultAdditionalCoveragesForm = (state) => {
  const updated = { ...state };
  const initial = initialFormValues();
  updated.values.ACPersonalProperty = initial.ACPersonalProperty;
  updated.values.ACHostLiquor = initial.ACHostLiquor;
  updated.values.ACPersonalAndAdvertisingLimit =
    initial.ACPersonalAndAdvertisingLimit;
  updated.values.ACProductsAndCompletedOperations =
    initial.ACProductsAndCompletedOperations;
  updated.values.ACSellingLiquor = initial.ACSellingLiquor;
  updated.values.ACTerrorism = initial.ACTerrorism;
  updated.values.ACDamageToRentedProperty = initial.ACDamageToRentedProperty;
  return updated;
};

// Clear previous Additional Coverage form for venue specific presets - temporarily the same as defaults above but this may change in the future
const clearAdditionalCoveragesForm = (state) => {
  const updated = { ...state };
  const initial = initialFormValues();
  updated.values.ACPersonalProperty = initial.ACPersonalProperty;
  updated.values.ACHostLiquor = initial.ACHostLiquor;
  updated.values.ACPersonalAndAdvertisingLimit =
    initial.ACPersonalAndAdvertisingLimit;
  updated.values.ACProductsAndCompletedOperations =
    initial.ACProductsAndCompletedOperations;
  updated.values.ACSellingLiquor = initial.ACSellingLiquor;
  updated.values.ACTerrorism = initial.ACTerrorism;
  updated.values.ACDamageToRentedProperty = initial.ACDamageToRentedProperty;
  return updated;
};

const handleEventDateRangeChange = (state, value) => {
  const updated = { ...state };
  updated.values.eventDates = updateEventDatesFromOtherFields({
    daysOfWeekField: state.values.daysOfWeekField,
    eventDateRangeField: value,
  });

  const { eventSetupDates, eventTeardownDates } = getSetupAndTeardownDates(
    updated.values.eventDateRange
  );
  updated.values.eventSetupDates = eventSetupDates;
  updated.values.eventTeardownDates = eventTeardownDates;

  // Reset all Provider details fields *except count
  updated.values.performersList = initialFormValues().performersList;
  updated.values.performersFrequency = initialFormValues().performersFrequency;
  updated.values.goodsVendorsList = initialFormValues().goodsVendorsList;
  updated.values.goodsVendorsFrequency =
    initialFormValues().goodsVendorsFrequency;
  updated.values.foodVendorsList = initialFormValues().foodVendorsList;
  updated.values.foodVendorsFrequency =
    initialFormValues().foodVendorsFrequency;
  updated.values.exhibitorsList = initialFormValues().exhibitorsList;
  updated.values.exhibitorsFrequency = initialFormValues().exhibitorsFrequency;
  return updated;
};
const handleDaysOfWeekFieldChange = (state, action) => {
  const updated = { ...state };
  const element = parseInt(action.meta.field.replace(/[^0-9]/g, ""), 10);
  const value = [...updated.values.daysOfWeekField];
  value[element] = action.payload;
  updated.values.eventDates = updateEventDatesFromOtherFields({
    daysOfWeekField: value,
    eventDateRangeField: updated.values.eventDateRange,
  });
  return updated;
};

const handleEventFrequencyFieldChange = (state /* , value */) => {
  const updated = { ...state };
  // In any situation, whenever eventFrequencyField changes, both date picker
  // values should reset
  updated.values.eventDateRange = initialFormValues().eventDateRange;
  updated.values.eventDates = initialFormValues().eventDates;
  // Also daysOfWeekField will change
  updated.values.daysOfWeekField = initialFormValues().daysOfWeekField;
  // Reset all Provider details fields *except count
  updated.values.performersList = initialFormValues().performersList;
  updated.values.performersFrequency = initialFormValues().performersFrequency;
  updated.values.goodsVendorsList = initialFormValues().goodsVendorsList;
  updated.values.goodsVendorsFrequency =
    initialFormValues().goodsVendorsFrequency;
  updated.values.foodVendorsList = initialFormValues().foodVendorsList;
  updated.values.foodVendorsFrequency =
    initialFormValues().foodVendorsFrequency;
  updated.values.exhibitorsList = initialFormValues().exhibitorsList;
  updated.values.exhibitorsFrequency = initialFormValues().exhibitorsFrequency;
  return updated;
};
const handleEventDatesFieldChange = (state, value) => {
  const updated = { ...state };
  updated.values.eventDates = sortDays(filterToUniqueDays(value));
  return updated;
};

const handleProviderFrequencyChange = (state, field) => {
  const updated = { ...state };
  if (field === "performersFrequency")
    updated.values.performersList = initialFormValues().performersList;
  if (field === "goodsVendorsFrequency")
    updated.values.goodsVendorsList = initialFormValues().goodsVendorsList;
  if (field === "foodVendorsFrequency")
    updated.values.foodVendorsList = initialFormValues().foodVendorsList;
  if (field === "exhibitorsFrequency")
    updated.values.exhibitorsList = initialFormValues().exhibitorsList;
  return updated;
};

const handleProviderCountChange = (state, field) => {
  const updated = { ...state };
  if (field === "eventPerformersCount") {
    updated.values.performersList = initialFormValues().performersList;
    updated.values.performersFrequency =
      initialFormValues().performersFrequency;
  }
  if (field === "eventGoods") {
    updated.values.goodsVendorsList = initialFormValues().goodsVendorsList;
    updated.values.goodsVendorsFrequency =
      initialFormValues().goodsVendorsFrequency;
  }
  if (field === "eventFood") {
    updated.values.foodVendorsList = initialFormValues().foodVendorsList;
    updated.values.foodVendorsFrequency =
      initialFormValues().foodVendorsFrequency;
  }
  if (field === "eventExhibition") {
    updated.values.exhibitorsList = initialFormValues().exhibitorsList;
    updated.values.exhibitorsFrequency =
      initialFormValues().exhibitorsFrequency;
  }
  return updated;
};

const handleFormChange = (state, action) => {
  const updated = { ...state };

  // Reset eventPerformers, eventGoods, eventFood, and eventExhibition to zero if user selects 'No' re non-guests attending without own insurance. Otherwise, these values are maintained in the quote API call, resulting in an inaccurate quote.
  if (action.meta.field === "eventPerformers" && action.payload === "no") {
    updated.values.eventPerformersCount = "0";
    updated.values.eventGoods = "0";
    updated.values.eventFood = "0";
    updated.values.eventExhibition = "0";
  }

  if (action.meta.field === "ACTerrorism") {
    updated.values.terrorismCoverageAgreement = false;
  }

  if (action.meta.field === "eventDateRange")
    return handleEventDateRangeChange(updated, action.payload);
  if (action.meta.field.match("daysOfWeekField"))
    return handleDaysOfWeekFieldChange(updated, action);
  if (action.meta.field === "eventFrequencyField")
    return handleEventFrequencyFieldChange(updated, action.payload);
  if (action.meta.field === "eventDates")
    return handleEventDatesFieldChange(updated, action.payload);
  if (
    (action.meta.field === "performersFrequency" ||
      action.meta.field === "goodsVendorsFrequency" ||
      action.meta.field === "foodVendorsFrequency" ||
      action.meta.field === "exhibitorsFrequency") &&
    action.payload === "everyday"
  )
    return handleProviderFrequencyChange(updated, action.meta.field);
  if (
    (action.meta.field === "eventPerformersCount" ||
      action.meta.field === "eventGoods" ||
      action.meta.field === "eventFood" ||
      action.meta.field === "eventExhibition") &&
    (action.payload === "0" || action.payload === "7")
  )
    return handleProviderCountChange(updated, action.meta.field);

  return updated;
};

const deleteDate = (state, date) => {
  const updated = { ...state };
  updated.values.eventDates = updated.values.eventDates.filter(
    (a) => !a.isSame(date, "day")
  );
  // Whenever a date is deleted, we must force the UI to show the Custom date picker
  // To make it possible to show and select specific days
  updated.values.eventFrequencyField = "custom";
  return updated;
};

// Any sync errors associated with an unregistered field will be removed
const handleUnregisterField = (state, { name }) => {
  const updated = { ...state };
  delete (updated.syncErrors || {})[name];
  return updated;
};

const setVenueCode = (state, { facilityCode, venueCode }) => {
  if (!state.values) return state;

  return {
    ...state,
    values: {
      ...state.values,
      facilityCode,
      venueCode,
      venueSearchTypeRadio: BY_VENUE_CODE,
    },
  };
};

const setVenueLink = (state, venueLink) => {
  if (!state.values) return state;

  return {
    ...state,
    values: {
      ...state.values,
      venueLink,
    },
  };
};

const clearOptionalVenue = (state, { index }) => ({
  ...state,
  values: {
    ...state.values,
    optionalVenues: state.values.optionalVenues.map((venue, i) => {
      if (i === index) return cloneDeep(emptyVenueForm);

      return venue;
    }),
  },
});

const resetWeddingQuestions = (state) => ({
  ...state,
  values: {
    ...state.values,
    weddingRehearsal: false,
    weddingBrunch: false,
  },
});

const setUnderwritingQuestions = (state, data) => ({
  ...state,
  values: {
    ...state.values,
    underwritingQuestions: data.map((question) => {
      if (question.code === "interest") {
        return {
          ...question,
          answer: "Organizer",
        };
      }

      return question;
    }),
  },
});

const handleReset = (state, form) => {
  if (!state?.form) {
    return state;
  }

  let values = {};

  switch (form) {
    case formName:
      values = initialFormValues();
      break;
    case cancelFormName:
      values = refundCancelInitialFormValues();
      break;
    case samplePolicyFormName:
      values = samplePolicyInitialFormValues();
      break;
    case saveQuoteFormName:
      values = saveQuoteInitialFormValues();
      break;
    default:
  }

  const intialFormState = !isEmpty(state.form[form]) ? { values } : {};

  return {
    ...state,
    form: {
      ...state.form,
      [form]: intialFormState,
    },
  };
};

export default {
  [formName]: (state, action) => {
    const updated = { ...state };

    if (action.type === CHANGE && action.meta.form === formName) {
      return { ...handleFormChange(state, action) };
    }

    // Sets the requisite fields according to venue (where applicable). Fields are hidden or shown, and their checkboxes are either mandatory (and checked) or not mandatory (and optional)
    if (action.type === SET_ADDITIONAL_COVERAGES_PRESETS) {
      // Reset Additional Coverages to default values
      clearAdditionalCoveragesForm(updated);
      const { additionalCoverage } = action.payload.venue;

      const waiverOfTransferRights = additionalCoverage.find(
        (ac) => ac.type === WAIVER_OF_TRANSFER_RIGHTS
      );
      updated.values.ACWaiverOfTransferRights =
        waiverOfTransferRights?.value[0] === 1 ||
        waiverOfTransferRights?.value[0] === true;
    }

    if (action.type === DEFAULT_ADDITIONAL_COVERAGES_FORM) {
      return { ...defaultAdditionalCoveragesForm(updated) };
    }

    if (action.type === DELETE_DATE) {
      return { ...deleteDate(updated, action.payload.date) };
    }

    if (action.type === SET_VENUE_CODE) {
      return { ...setVenueCode(updated, action.payload) };
    }

    if (action.type === SET_VENUE_LINK) {
      return { ...setVenueLink(updated, action.payload) };
    }

    if (action.type === UNREGISTER_FIELD) {
      return { ...handleUnregisterField(updated, action.payload) };
    }

    if (action.type === UPDATE_BLOCKED_OPTIONAL_VENUE) {
      return { ...updateBlockedOptionalVenue(updated, action.payload) };
    }

    if (action.type === UPDATE_OPTIONAL_VENUE_ERROR) {
      return { ...updateOptionalVenueError(updated, action.payload) };
    }

    if (action.type === CONFIRM_OPTIONAL_VENUE) {
      return { ...confirmOptionalVenue(updated, action.payload) };
    }

    if (action.type === CLEAR_OPTIONAL_VENUE) {
      return { ...clearOptionalVenue(updated, action.payload) };
    }

    if (action.type === SELECT_EVENT_TYPE) {
      return { ...resetWeddingQuestions(updated) };
    }

    if (action.type === SET_UNDERWRITING_QUESTIONS) {
      return { ...setUnderwritingQuestions(updated, action.payload) };
    }

    return updated;
  },
  [cancelFormName]: (state, action) => {
    if (action.type === ConnectedRouter.LOCATION_CHANGE) {
      return handleReset(state, cancelFormName);
    }

    return state;
  },
  [samplePolicyFormName]: (state, action) => {
    if (action.type === ConnectedRouter.LOCATION_CHANGE) {
      return handleReset(state, samplePolicyFormName);
    }
    return state;
  },
};
