import React from "react";
import { Switch } from "react-router-dom";
import PageNotFoundRedirect from "./PageNotFoundRedirect";
import PropTypes from "prop-types";

const propTypes = {
  notFoundRedirectUrl: PropTypes.string,
};

const CustomSwitch = ({ notFoundRedirectUrl, children }) => {
  return (
    <Switch>
      {children}
      <PageNotFoundRedirect notFoundRedirectUrl={notFoundRedirectUrl} />
    </Switch>
  );
};

CustomSwitch.propTypes = propTypes;

CustomSwitch.defaultProps = {
  notFoundRedirectUrl: "",
};

export default CustomSwitch;
