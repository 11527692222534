import { ConnectedRouter } from "@jauntin/react-ui";
import { getFormRelativePath } from "src/Helpers/URLParser";

const classNameForPage = (page) => (page ? `page-${page}` : "page-index");

export default (store) => (next) => (action) => {
  // Let the action happen first, and then deal with it
  next(action);
  if (action.type === ConnectedRouter.LOCATION_CHANGE) {
    // Allow the DOM to update first before applying class name
    setTimeout(() => {
      const pageWrapper = document.getElementById("page-wrapper");
      if (pageWrapper) {
        const className = classNameForPage(
          getFormRelativePath(window.location.pathname)
        );
        const vClassName = `page-version-${process.env.REACT_APP_UI_VERSION}`;
        pageWrapper.className = `${className} ${vClassName}`.trim();
      }
    }, 0);
  }
};
