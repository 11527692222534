import { change, formValueSelector, touch } from "redux-form";
import { getVenueGlLimits } from "src/Selectors/Limits";
import { getDrpLimits } from "src/Selectors/AdditionalCoverages";
import { formName } from "src/Constants/constants";
import { touchRegistered } from "./actions";

export const preSelectDefaultLimits = () => (dispatch, getState) => {
  const state = getState();
  const glLimits = getVenueGlLimits(state);
  const drpLimits = getDrpLimits(state);
  const formValues = formValueSelector(formName);
  const selectedGlLimit = formValues(state, "generalLiabilityCoverage");

  if (!selectedGlLimit?.occurrence || !selectedGlLimit?.aggregate) {
    dispatch(change(formName, "generalLiabilityCoverage", glLimits[0]));
  }
  if (!formValues(state, "ACDamageToRentedProperty")) {
    dispatch(change(formName, "ACDamageToRentedProperty", drpLimits[0]));
  }
};

export const confirmLimits = () => (dispatch) => {
  const fields = [
    "ACTerrorism",
    "ACDamageToRentedProperty",
    "generalLiabilityCoverage",
  ];

  dispatch(touch(formName, ...fields));
  dispatch(touchRegistered(formName, "terrorismCoverageAgreement"));
};
