import { clone } from "lodash";

const samplePolicyPageState = {
  usStates: [],
  pdfLink: "",
};

const samplePolicyPageInitialState = () => clone(samplePolicyPageState);

export default samplePolicyPageInitialState;
