import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { samplePolicyFormName } from "../Constants/constants";
import SamplePolicyFormName from "../Components/SamplePolicyDocument/SamplePolicyDocument";
import { getSamplePolicy, clearSamplePolicyLink } from "../Actions/actions";
import samplePolicyInitialFormValues from "src/Constants/samplePolicyInitialFormValues";

const SamplePolicyDocumentContainer = reduxForm({
  form: samplePolicyFormName,
  initialValues: samplePolicyInitialFormValues(),
})(SamplePolicyFormName);

const mapStateToProps = (state) => ({
  usStates: state.samplePolicyPage.usStates,
  pdfLink: state.samplePolicyPage.pdfLink,
});

const mapDispatchToProps = (dispatch) => ({
  getSamplePolicyLink: (venueState, setShowLink, setThinking) =>
    dispatch(getSamplePolicy(venueState, setShowLink, setThinking)),
  clearSamplePolicyLink: () => dispatch(clearSamplePolicyLink()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SamplePolicyDocumentContainer);
