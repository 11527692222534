import { clone } from "lodash";

const refundCancelPageState = {
  waiting: false,
  disabled: true,
  policyCancelled: false,
  referenceNumber: null,
  policyNumber: null,
  contactEmail: null,
};

const refundCancelPageInitialState = () => clone(refundCancelPageState);

export default refundCancelPageInitialState;
