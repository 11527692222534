import { getFilteredFormStepsConfig } from "src/Selectors/Shared";

export const canActivateFormStep = (currentPath) => (state) => {
  if (state.thankyouPage.completedOrder?.orderNumber) return false;

  const formStepsConfig = getFilteredFormStepsConfig(state);

  if (formStepsConfig[currentPath] === undefined) return true;

  return Object.entries(formStepsConfig).every(([path, config], index, arr) => {
    const currentPathIndex = arr.indexOf(
      arr.find(([path]) => path === currentPath)
    );

    if (
      config.isPageValidSelector &&
      currentPathIndex &&
      index < currentPathIndex
    ) {
      return config.isPageValidSelector(state);
    }

    return true;
  });
};
