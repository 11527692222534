import {
  EVENT_CANNIBIS_INELIGIBLE_ERROR,
  EVENT_RISK_CLASS_9_ERROR,
  EVENT_RISK_CLASS_3_ERROR,
} from "src/Constants/errorMessages";
import {
  getVenueStateCannabisErrorMessage,
  isVenuePageValid,
  areOptionalVenuesValid,
} from "./Venue";
import { getTouchedFieldError } from "./Form";

export const isEventSelectionPageValid = (state) => {
  const { isValidating, isValid, error } = state.formPage.eventType;
  const { eventType } = state.form.application.values;

  const cannabis = state.form.application.values.cannabis !== null;
  const venueStateCannabisErrorMessage =
    getVenueStateCannabisErrorMessage(state);

  return !!(
    !isValidating &&
    eventType &&
    !error &&
    isValid &&
    cannabis &&
    venueStateCannabisErrorMessage.length === 0
  );
};

export const getCannabisErrors = (state) => {
  const cannabisError = eventCannabisError(state);
  const venueStateCannabisErrorMessage =
    getVenueStateCannabisErrorMessage(state);

  return {
    cannabisError,
    venueStateCannabisErrorMessage,
  };
};

export const isEventInformationPageValid = (state) => {
  const {
    eventName,
    eventDailyGuests,
    eventDateRange: { startDate, endDate },
  } = state.form.application.values;

  return !!(
    eventName &&
    eventDailyGuests > 0 &&
    eventDailyGuests < 501 &&
    startDate &&
    endDate
  );
};

export const eventCannabisError = (state) =>
  !!state.formPage.eventType.error?.errors?.cannabis?.some((errorMsg) =>
    errorMsg.includes(EVENT_CANNIBIS_INELIGIBLE_ERROR)
  );

export const eventRiskClass3Error = (state) =>
  !!state.formPage.eventType.error?.errors?.eventTypeId?.includes(
    EVENT_RISK_CLASS_3_ERROR
  );

export const eventRiskClass9Error = (state) =>
  !!state.formPage.eventType.error?.errors?.eventTypeId?.includes(
    EVENT_RISK_CLASS_9_ERROR
  );

export const getHasEventTypeError = (state) =>
  eventRiskClass3Error(state) ||
  eventRiskClass9Error(state) ||
  eventCannabisError(state);

export const isWeddingTypeEventSelected = (state) => {
  return (
    state.form.application.values.eventType?.value?.identifier ===
    "wedding-ceremony-reception"
  );
};

export const selectedEventType = (state) => state.formPage.eventType.selection;

export const getSetupDates = (state) =>
  state.form.application.values.eventSetupDates;

export const getTeardownDates = (state) =>
  state.form.application.values.eventTeardownDates;

export const venueEventSelectionHasErrors = (state) =>
  (state.form.application?.fields?.venue?.touched &&
    !isVenuePageValid(state)) ||
  (!!state.formPage.venue.selectedPlaceAddressComponents.state &&
    !isVenuePageValid(state)) ||
  getVenueStateCannabisErrorMessage(state).length > 0 ||
  eventCannabisError(state) ||
  eventRiskClass3Error(state) ||
  eventRiskClass9Error(state) ||
  !!getTouchedFieldError("venue")(state) ||
  !!getTouchedFieldError("cannabis")(state) ||
  !!getTouchedFieldError("eventType")(state) ||
  !areOptionalVenuesValid(state);

export const eventInformationHasErrors = (state) =>
  state.form.application.fields?.underwritingQuestions?.some(
    (uq, i) =>
      !!uq?.answer?.touched &&
      !!state.form.application.syncErrors?.underwritingQuestions?.[i]
  ) ||
  !!getTouchedFieldError("eventName")(state) ||
  !!getTouchedFieldError("eventDailyGuests")(state) ||
  !!getTouchedFieldError("eventDateRange")(state);
