import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import FormStepNavigation from "src/Components/FormStepNavigation";
import Button from "@gbli-events/common/src/Components/Themed/Button";
import EventSummaryTable from "src/Components/EventSummaryTable";
import PriceSummaryTable from "src/Components/PriceSummaryTable";
import PriceSummaryFooter from "src/Components/PriceSummaryFooter/PriceSummaryFooter";
import VenueSummaryTable from "src/Components/CoverageSummary/VenueSummaryTable";
import NavContinueButton from "src/Components/NavContinueButton";
import ModalSaveAdditionalInsured from "src/Components/ModalSaveAdditionalInsured/ModalSaveAdditionalInsured";
import ModalSaveQuote from "src/Components/ModalSaveQuote/ModalSaveQuote";
import { updateCertHolders } from "src/Actions/actions";
import {
  additionalInsuredFacilityType,
  additionalInsuredTypes,
  additionalInsuredVenueAddressType,
  additionalInsuredVenueType,
} from "src/Constants/additionalInsuredTypes";
import ModalDeleteAdditionalInsured from "src/Components/ModalDeleteAdditionalInsured/ModalDeleteAdditionalInsured";
import {
  GBLI_EMAIL_EVENTS,
  GBLI_PHONE_EVENTS_TITLE,
} from "@gbli-events/common/src/Constants/shared";
import { formValueSelector } from "redux-form";
import { formName } from "../../Constants/constants";

const formValues = formValueSelector(formName);

const CoverageSummary = ({ certHolders, facilityCode, onSave, onRemove }) => {
  const [showModalSaveQuote, setShowModalSaveQuote] = useState(false);
  const [showModalSaveAdditionalInsured, setShowModalSaveAdditionalInsured] =
    useState(false);
  const [
    showModalDeleteAdditionalInsured,
    setShowModalDeleteAdditionalInsured,
  ] = useState(false);
  const [currentType, setCurrentType] = useState(
    additionalInsuredVenueAddressType
  );
  const [currentAddress, setCurrentAddress] = useState({});
  const hasFacilityType =
    certHolders.filter(({ type }) => type === additionalInsuredFacilityType)
      .length > 0;
  const venueCode = useSelector((state) => formValues(state, "venueCode"));

  const hasAddress = ({ address1, address2, city, state, zip }) =>
    [address1, address2, city, state, zip].some((value) => Boolean(value));

  return (
    <>
      <div className="container">
        <FormStepNavigation />
      </div>
      <div className="additional-insured container">
        <div className="page-heading">
          <h2>Additional Insured</h2>
          {venueCode.length > 0 && (
            <p className="text-center mt-4">
              These additional insured are automatically added to the
              certificate of insurance and can't be changed:
            </p>
          )}
          {venueCode.length === 0 && (
            <>
              <p className="text-center mt-4">
                Additional Insured(s) can be added/edited here. The venue is
                added automatically and can only be edited.
              </p>
              <p className="text-center additional-text">
                Questions? Contact us at{" "}
                <span className="text-nowrap">{GBLI_PHONE_EVENTS_TITLE}</span>{" "}
                or {GBLI_EMAIL_EVENTS} for further assistance.{" "}
              </p>
            </>
          )}
        </div>
        <div className="additional-insured__cert-holders row">
          {certHolders.map(({ address, type }) => (
            <div
              key={type}
              className="additional-insured__cert-holder col-12 col-md-4"
            >
              <div>
                <p className="mb-0 font-weight-bold">{address.companyName}</p>
                {address.address1 && <p className="mb-0">{address.address1}</p>}
                {address.address2 && <p className="mb-0">{address.address2}</p>}
                <p className="mb-0">
                  {[address.city, address.state, address.zip]
                    .filter((value) => Boolean(value))
                    .join(", ")}
                </p>
                {address.country && hasAddress(address) && (
                  <p className="mb-0">{address.country}</p>
                )}
              </div>
              {venueCode.length === 0 && (
                <div className="buttons-place">
                  <Button
                    type="button"
                    className="mx-lg-auto edit-button"
                    onClick={() => {
                      setCurrentType(type);
                      setCurrentAddress(address);
                      setShowModalSaveAdditionalInsured(true);
                    }}
                  >
                    <i className="fas fa-pen"></i>
                  </Button>
                  {(type === additionalInsuredVenueType ||
                    (type === additionalInsuredFacilityType &&
                      facilityCode.length === 0)) && (
                    <Button
                      type="button"
                      className="mx-lg-auto delete-button"
                      onClick={() => {
                        setCurrentType(type);
                        setShowModalDeleteAdditionalInsured(true);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  )}
                </div>
              )}
            </div>
          ))}
          {certHolders.length < 3 && venueCode.length === 0 && (
            <div className="additional-insured__cert-holder col-12 col-md-4">
              <Button
                type="button"
                className="w-100 mw-md--400 mx-lg-auto add-button"
                variant="warning"
                disabled={false}
                onClick={() => {
                  setCurrentType(
                    hasFacilityType
                      ? additionalInsuredVenueType
                      : additionalInsuredFacilityType
                  );
                  setCurrentAddress({});
                  setShowModalSaveAdditionalInsured(true);
                }}
              >
                <i className="far fa-plus"></i>
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="coverage-summary">
        <div className="container">
          <div className="page-heading">
            <h2>Coverage Summary</h2>
            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
              <Button
                type="button"
                className="w-100 mw-md--400 mx-lg-auto mt-4"
                variant="success"
                onClick={() => {
                  setShowModalSaveQuote(true);
                }}
              >
                Save Quote
              </Button>
            </div>
            <p className="text-center mt-4">
              Before completing your purchase, please review your selections
              below:
            </p>
          </div>
          <EventSummaryTable />
          <VenueSummaryTable />
          <PriceSummaryTable />
        </div>
        <PriceSummaryFooter className="sticky-bottom mb--40" showLegal />
        <div className="container">
          <hr className="mt--14"></hr>
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <Button
              type="button"
              className="w-100 mw-md--400 mt--40 mr-0 mr-md-3 mr-lg-auto"
              variant="success"
              onClick={() => {
                setShowModalSaveQuote(true);
              }}
            >
              Save Quote
            </Button>
            <NavContinueButton />
          </div>
        </div>
        <ModalSaveQuote
          show={showModalSaveQuote}
          handleClose={() => {
            setShowModalSaveQuote(false);
          }}
        />
        <ModalSaveAdditionalInsured
          type={currentType}
          address={currentAddress}
          show={showModalSaveAdditionalInsured}
          handleClose={() => {
            setShowModalSaveAdditionalInsured(false);
          }}
          onSave={(type, data) => {
            onSave(type, data);
          }}
          companyNameFieldLabel={
            currentType === additionalInsuredVenueAddressType
              ? "Venue Name"
              : "Additional Insured Name"
          }
        />
        <ModalDeleteAdditionalInsured
          type={currentType}
          show={showModalDeleteAdditionalInsured}
          handleClose={() => {
            setShowModalDeleteAdditionalInsured(false);
          }}
          onRemove={(type) => {
            onRemove(type);
          }}
        />
      </div>
    </>
  );
};

const propTypes = {
  certHolders: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(additionalInsuredTypes),
      address: PropTypes.shape({
        companyName: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        country: PropTypes.string,
      }),
    })
  ).isRequired,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  certHolders: state.formPage.summary.additionalCertHolders || [],
  facilityCode: state.formPage.referral.facilityCode || "",
});

const mapDispatchToProps = (dispatch) => ({
  onSave: (type, data) => {
    dispatch((dispatch, getState) => {
      const state = getState();
      const certHolders = (
        state.formPage.summary.additionalCertHolders || []
      ).filter((certHolder) => certHolder.type !== type);
      certHolders.push({
        type,
        address: {
          ...data,
          country: "US",
        },
      });
      dispatch(updateCertHolders(certHolders));
    });
  },
  onRemove: (type) => {
    dispatch((dispatch, getState) => {
      const state = getState();
      const certHolders = (
        state.formPage.summary.additionalCertHolders || []
      ).filter((certHolder) => certHolder.type !== type);
      dispatch(updateCertHolders(certHolders));
    });
  },
});

CoverageSummary.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(CoverageSummary);
