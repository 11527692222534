import {
  VENUE_SELECTION_URL,
  EVENT_INFORMATION_URL,
  LIMITS_URL,
  COVERAGE_SUMMARY_URL,
  PAYMENT_URL,
} from "src/Constants/constants";
import { isVenuePageValid } from "src/Selectors/Venue";
import { isEventSelectionPageValid } from "src/Selectors/Event";
import { isUnderwritingQuestionsPageValid } from "src/Selectors/UnderwritingQuestions";
import { getIsLimitsPageValid } from "src/Selectors/Limits";
import { isEventInformationPageValid } from "src/Selectors/Event";

import Venue from "src/Components/Venue/Venue";
import Limits from "src/Components/Limits/Limits";
import QuoteSummaryFloater from "src/Components/QuoteSummaryFloater/QuoteSummaryFloater";
import EventSelection from "src/Components/EventSelection/EventSelection";
import EventInformation from "src/Components/EventInformation/EventInformation";
import InsuredPayment from "src/Components/InsuredPayment/InsuredPayment";
import CoverageSummary from "src/Components/CoverageSummary/CoverageSummary";

export const formStepsConfig = {
  // Key order matters here. The guard will check page validity of all previous steps relative to the currentPath.
  [VENUE_SELECTION_URL]: {
    component: () => (
      <>
        <Venue />
        <EventSelection />
      </>
    ),
    isPageValidSelector: (state) =>
      isVenuePageValid(state) && isEventSelectionPageValid(state),
    children: <QuoteSummaryFloater />,
  },
  [EVENT_INFORMATION_URL]: {
    component: () => (
      <>
        <EventInformation />
      </>
    ),
    isPageValidSelector: (state) =>
      isUnderwritingQuestionsPageValid(state) &&
      isEventInformationPageValid(state),
    children: <QuoteSummaryFloater />,
  },
  [LIMITS_URL]: {
    component: Limits,
    isPageValidSelector: getIsLimitsPageValid,
    children: <QuoteSummaryFloater />,
  },
  [COVERAGE_SUMMARY_URL]: {
    component: CoverageSummary,
    isPageValidSelector: null,
  },
  [PAYMENT_URL]: {
    component: InsuredPayment,
    isPageValidSelector: null,
    children: <QuoteSummaryFloater />,
  },
};
