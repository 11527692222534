import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import StyledRadioFieldGroup from "./StyledRadioFieldGroup";

const propTypes = {
  controlName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  label: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.func),
  className: PropTypes.string,
  direction: PropTypes.string,
  error: PropTypes.string,
};

const RadioFieldError = ({ meta }) =>
  meta.touched && meta.error ? (
    <div className="form-error ">{meta.error}</div>
  ) : null;

const RadioFieldGroup = ({
  controlName,
  options,
  label,
  validators,
  className,
  direction,
  error,
}) => {
  return (
    <StyledRadioFieldGroup
      className={`form-group ${className ? className : ""}`}
    >
      {label && (
        <div className={`label mb-3 ${error ? "text-danger" : ""}`}>
          {label}
        </div>
      )}
      <Field name={controlName} component={RadioFieldError} />
      <div className="radio-control-group">
        {options.map((option) => {
          const controlId = `${controlName}-${option.label}`;

          return (
            <div
              key={controlId}
              className={`radio-large custom-control custom-radio custom-control-${
                direction ? direction : "inline"
              }`}
            >
              <Field
                id={controlId}
                name={controlName}
                validate={validators}
                component="input"
                className="custom-control-input"
                type="radio"
                value={option.value}
                onChange={
                  option.onChange !== undefined
                    ? () => option.onChange()
                    : () => {}
                }
              />
              <label
                title=""
                htmlFor={controlId}
                className="custom-control-label"
              >
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
    </StyledRadioFieldGroup>
  );
};

RadioFieldGroup.propTypes = propTypes;

export default RadioFieldGroup;
