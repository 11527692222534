import React, { Fragment } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import SearchByAddress from "src/Components/Venue/SearchByAddress";
import { formName } from "src/Constants/constants";
import {
  chooseOptionalVenue,
  clearOptionalVenue,
  setNeedOptionalVenue,
} from "src/Actions/optionalVenues";
import { isValidAddress } from "@gbli-events/common/src/Helpers/AddressTypes";
import { isValidState, isUniqueOptionalVenue } from "src/Selectors/Venue";
import { FieldsProps } from "@gbli-events/common/src/Constants/ReduxFormPropTypes";
import { venueFormPropType } from "src/Models/VenueFormModel";
import { Button } from "@jauntin/react-ui";

const formValues = formValueSelector(formName);

const mapStateToProps = (state) => ({
  optionalVenueFormValues: formValues(state, "optionalVenues"),
  isMobile: state.formPage.summary.mobileDisplay,
  isValidState: (venueState) => isValidState(venueState, state),
  isValidAddress,
  isUniqueOptionalVenue: (optionalVenue) =>
    isUniqueOptionalVenue(state, optionalVenue),
  needOptionalVenue: state.formPage.venue.needOptionalVenue,
});

const mapDispatchToProps = (dispatch) => ({
  onSelect: (index, _, placeId, address, addressComponents, utcOffset) => {
    dispatch(
      chooseOptionalVenue(index, placeId, address, addressComponents, utcOffset)
    );
  },
  clearForm: (index) => {
    dispatch(clearOptionalVenue({ index }));
  },
  setNeedOptionalVenue: (needOptionalVenue) => {
    dispatch((_, getState) => {
      const state = getState();
      dispatch(setNeedOptionalVenue(needOptionalVenue));
      if (!needOptionalVenue) {
        const venues = formValues(state, "optionalVenues") || [];
        venues.forEach((_, index) => {
          dispatch(clearOptionalVenue({ index }));
        });
      }
    });
  },
});

const OptionalVenues = ({
  fields,
  optionalVenueFormValues,
  isMobile,
  isValidState,
  onSelect,
  clearForm,
  isValidAddress,
  isUniqueOptionalVenue,
  needOptionalVenue,
  setNeedOptionalVenue,
}) => {
  return (
    <div className="optional-venues">
      {fields.map((venueFormItem, index) => {
        const venueForm = optionalVenueFormValues[index];

        return (
          <Fragment key={venueFormItem}>
            <div className="text-center">
              <Button
                className="btn-link optional-venues__button"
                text={
                  <>
                    Do you need to add a second venue?{" "}
                    <span className="optional-venues__button--fw-500">
                      (Optional)
                    </span>
                  </>
                }
                disabled={needOptionalVenue}
                onClick={() => setNeedOptionalVenue(true)}
              />
              {needOptionalVenue && (
                <Button
                  text={<i className="fal fa-times"></i>}
                  disabled={!needOptionalVenue}
                  onClick={() => setNeedOptionalVenue(false)}
                />
              )}
            </div>
            {needOptionalVenue && (
              <SearchByAddress
                name={`${venueFormItem}.venueSearch`}
                onSelect={(
                  _,
                  placeId,
                  address,
                  addressComponents,
                  utcOffset
                ) => {
                  onSelect(
                    index,
                    _,
                    placeId,
                    address,
                    addressComponents,
                    utcOffset
                  );
                }}
                address={venueForm.selectedPlaceAddress}
                venueState={
                  venueForm.selectedPlaceAddressComponents.state || ""
                }
                mobile={isMobile}
                isValidAddress={isValidAddress(
                  venueForm.selectedPlaceAddressComponents.address1,
                  venueForm.selectedPlaceAddressComponents.city,
                  venueForm.selectedPlaceAddressComponents.state || "",
                  venueForm.selectedPlaceAddressComponents.country,
                  venueForm.selectedPlaceAddressComponents.zip,
                  venueForm.selectedPlaceAddressComponents.addressType
                )}
                clearForm={() => clearForm(index)}
                hasInput={!!venueForm.venueSearch}
                isValidState={isValidState(
                  venueForm.selectedPlaceAddressComponents.state
                )}
                hasRegionalTax={venueForm.hasRegionalTax}
                taxRegions={venueForm.taxRegions}
                isKentuckyEntity={venueForm.isKentuckyEntity}
                setKentuckyCity={() => {}}
                setIsKentuckyCity={() => {}}
                setHasLookedUpTax={() => {}}
                venueSearchReadOnly={!!venueForm.selectedPlaceId}
                setVenueSearchReadOnly={() => {}}
                isBlocked={venueForm.isBlocked}
                genericServerError={venueForm.genericServerError}
                updateManualCity={() => {}}
                venueName={
                  venueForm.selectedPlaceAddressComponents.companyName || ""
                }
                alreadySelectedError={!isUniqueOptionalVenue(venueForm)}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

OptionalVenues.propTypes = {
  fields: FieldsProps,
  optionalVenueFormValues: PropTypes.arrayOf(venueFormPropType).isRequired,
  isMobile: PropTypes.bool.isRequired,
  isValidState: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  isValidAddress: PropTypes.func.isRequired,
  isUniqueOptionalVenue: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionalVenues);
