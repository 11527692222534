import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import TextField from "../TextField";
import { format } from "date-fns";
import SelectListField from "../SelectListField/SelectListField";
import CardNumberField from "./CardNumberField";
import CardCVVField from "./CardCVVField.jsx";
import { numberedMonths, getFutureYears } from "../../../Helpers/DateHelpers";
import {
  required,
  creditCardNumber,
  creditCardCvv,
} from "@gbli-events/common/src/Helpers/validators";
import { GBLI_THEME } from "../../../Constants/themes/gbliTheme";
import themePropType from "../../../Models/Theme";
import { normalizers } from "@jauntin/utilities";

const { normalizeCreditCard, normalizeCVV } = normalizers;

const propTypes = {
  cardNumberControl: PropTypes.string.isRequired,
  cardNameControl: PropTypes.string.isRequired,
  cardExpMonControl: PropTypes.string.isRequired,
  cardExpYrControl: PropTypes.string.isRequired,
  cardCVVControl: PropTypes.string.isRequired,
  cardDateValidator: PropTypes.func.isRequired,
  theme: themePropType,
};

const CCFieldGroup = ({
  cardNumberControl,
  cardExpMonControl,
  cardExpYrControl,
  cardDateValidator,
  cardNameControl,
  cardCVVControl,
  theme,
}) => {
  const ccYearsOptions = getFutureYears(20).map((year) => {
    const value = format(year, "yy");

    return {
      label: value,
      value,
    };
  });

  return (
    <div className="form-group">
      <p className="mb-1">We accept Mastercard, Visa and Discover</p>
      <Field
        component={CardNumberField}
        name={cardNumberControl}
        validate={[required, creditCardNumber]}
        normalize={normalizeCreditCard}
        required
      />
      <div className="form-row">
        <div className="col-sm">
          <Field
            label="Month"
            component={SelectListField}
            placeholder="MM"
            validate={[required, cardDateValidator]}
            name={cardExpMonControl}
            options={numberedMonths.map((month) => ({
              label: month,
              value: month,
            }))}
            inputMode="numeric"
            pattern="\d*"
            required
            searchable
            theme={theme}
          />
        </div>
        <div className="col-sm">
          <Field
            label="Year"
            placeholder="YY"
            validate={[required, cardDateValidator]}
            component={SelectListField}
            name={cardExpYrControl}
            options={ccYearsOptions}
            inputMode="numeric"
            pattern="\d*"
            required
            searchable
            theme={theme}
          />
        </div>
        <Field
          className="col-sm"
          validate={[required, creditCardCvv]}
          component={CardCVVField}
          normalize={normalizeCVV}
          name={cardCVVControl}
          required
        />
      </div>
      <Field
        component={TextField}
        label="Name on Card"
        ariaLabel="Name on Card"
        name={cardNameControl}
        validate={[required]}
        required
      />
    </div>
  );
};

CCFieldGroup.propTypes = propTypes;
CCFieldGroup.defaultProps = {
  theme: GBLI_THEME,
};

export default CCFieldGroup;
