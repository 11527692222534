import React, { useEffect, useCallback } from "react";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Field, FieldArray, formValueSelector } from "redux-form";
import StyledDateRangePickerField from "@gbli-events/common/src/Components/Event/DateRangePickerField/StyledDateRangePickerField";
import {
  required,
  requiredDates,
  minAttendees,
  maxAttendees,
  minLength,
  maxLength,
} from "@gbli-events/common/src/Helpers/validators";
import {
  isWeddingTypeEventSelected,
  isEventInformationPageValid,
  getSetupDates,
  getTeardownDates,
  eventInformationHasErrors,
} from "src/Selectors/Event";
import {
  getUnderwritingQuestionFields,
  isUnderwritingQuestionsPageValid,
} from "src/Selectors/UnderwritingQuestions";
import { confirmEventAndUQ } from "src/Actions/events";
import { initializeForm } from "src/Actions/underwritingQuestions";
import { underwritingQuestionPropType } from "src/Models/UnderwritingQuestionModel";
import FormStepNavigation from "src/Components/FormStepNavigation";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import StyledIntegerNumberInput from "@gbli-events/common/src/Components/FormElements/IntegerNumberInput/StyledIntegerNumberInput";
import CheckboxDates from "@gbli-events/common/src/Components/Event/CheckboxDates";
import Alert from "@gbli-events/common/src/Components/Themed/Alert";
import NavContinueButton from "src/Components/NavContinueButton";
import UnderwritingQuestionFields from "src/Components/UnderwritingQuestions/UnderwritingQeustionFields";
import WeddingQuestions from "src/Components/EventInformation/WeddingQuestions";
import { MAX_EVENT_NAME_LENGTH } from "@gbli-events/common/src/Constants/event";
import { disabledDatesForDateRangePicker } from "src/Helpers/DateHelpers";
import { formName, PERMISSION_MANAGE_SETTINGS } from "src/Constants/constants";
import { saveUserEntryData } from "src/Actions/userEntryData";
import { getUserEntryDataPayload } from "src/Selectors/UserEntryData";
import { useTimeTravel } from "@jauntin/react-ui";

const minLength2 = minLength(2);
const maxEventNameLength = maxLength(MAX_EVENT_NAME_LENGTH);

const mapStateToProps = (state) => ({
  underwritingQuestions: getUnderwritingQuestionFields(state),
  isUnderwritingQuestionsPageValid: isUnderwritingQuestionsPageValid(state),
  venueUtcOffset: state.formPage.venue.selectedPlaceUtcOffset || 0,
  isWeddingEventType: isWeddingTypeEventSelected(state),
  formHasErrors: eventInformationHasErrors(state),
  isPageValid:
    isUnderwritingQuestionsPageValid(state) &&
    isEventInformationPageValid(state),
  canManageSettings:
    state.formPage.authUser?.permissions?.includes(
      PERMISSION_MANAGE_SETTINGS
    ) || false,
});

const mapDispatchToProps = (dispatch) => ({
  confirmForm: () => dispatch(confirmEventAndUQ()),
  initializeQuestions: () => dispatch(initializeForm()),
});

const propTypes = {
  underwritingQuestions: PropTypes.arrayOf(underwritingQuestionPropType)
    .isRequired,
  initializeQuestions: PropTypes.func.isRequired,
  venueUtcOffset: PropTypes.number.isRequired,
  isWeddingEventType: PropTypes.bool.isRequired,
  isPageValid: PropTypes.bool.isRequired,
  formHasErrors: PropTypes.bool.isRequired,
  confirmForm: PropTypes.func.isRequired,
  canManageSettings: PropTypes.bool.isRequired,
};

const EventInformation = ({
  underwritingQuestions,
  venueUtcOffset,
  isWeddingEventType,
  isPageValid,
  formHasErrors,
  confirmForm,
  initializeQuestions,
  canManageSettings,
}) => {
  const eventSetupDates = useSelector(getSetupDates);
  const eventTeardownDates = useSelector(getTeardownDates);
  const { startDate } = useSelector((state) =>
    formValueSelector(formName)(state, "eventDateRange")
  );

  useTimeTravel(
    process.env.REACT_APP_ENV !== "production" && canManageSettings
  );
  useEffect(() => {
    initializeQuestions();
  }, [initializeQuestions]);

  const disabledDates = useCallback(
    (day) =>
      disabledDatesForDateRangePicker({
        startDate: new Date(startDate),
        venueUtcOffset,
      })(day),
    [startDate, venueUtcOffset]
  );
  const userEntryData = useSelector(getUserEntryDataPayload);

  return (
    <>
      <div className="underwriting-questions container mb-4">
        <FormStepNavigation />
        <div className="underwriting-questions__form-container">
          <div className="page-heading">
            <h2>Let’s make sure we are a fit for your coverage needs.</h2>
          </div>
          <FieldArray
            name="underwritingQuestions"
            component={UnderwritingQuestionFields}
            underwritingQuestions={underwritingQuestions}
          />
        </div>
      </div>
      <div className="event-information container mb-4">
        <div className="event-information__form-container">
          <div className="page-heading">
            <h2>Please tell us about your event</h2>
          </div>
          <div className="event-information__form">
            <Field
              name="eventName"
              component={TextField}
              label="Name of your event:"
              ariaLabel="Name of your event"
              validate={[required, minLength2, maxEventNameLength]}
              required
              maxLength={MAX_EVENT_NAME_LENGTH}
              className="mb-md-5"
              lengthClassName="pt-1 c-gray-600 label-small"
            />
            <Field
              name="eventDailyGuests"
              className="event-information__guests mb-md-5"
              component={StyledIntegerNumberInput}
              label="How many people, on average, will attend each day?"
              placeholder=""
              min={1}
              max={500}
              validate={[required, minAttendees, maxAttendees]}
              required
            />
            <div className="event-information__dates">
              <Field
                component={StyledDateRangePickerField}
                validate={requiredDates}
                name="eventDateRange"
                type="text"
                numberOfMonths={isMobile ? 1 : 2}
                inputClassName="form-control-lg"
                disabledDates={disabledDates}
                startDateLabel="Start / first day"
                endDateLabel="End / last day"
                label="What day(s) is your event, excluding any setup and teardown
                days? Choose up to 3 consecutive days*."
              />
            </div>
            <div className="mb-3 mb-md-5 event-information__info-text">
              *For events that continue past midnight, we still consider those
              to be a one-day event that only require one day of premium.
              However, the declaration page will show coverage up until the
              following day at 12:01am for your peace of mind.
            </div>
            {isWeddingEventType && (
              <WeddingQuestions className="event-information" />
            )}
            <CheckboxDates
              label="Do you need coverage for set up on the below day?"
              className="mb-3 mb-md-5"
              controlName="eventSetupDates"
              controls={eventSetupDates}
            />
            <CheckboxDates
              label="Do you need coverage for teardown on the below day?"
              className="mb-3 mb-md-5"
              controlName="eventTeardownDates"
              controls={eventTeardownDates}
            />
          </div>
        </div>

        {formHasErrors && (
          <Alert variant="danger">
            <i className="far fa-exclamation-circle alert__icon" />
            <div className="alert__text">Please correct the errors above.</div>
          </Alert>
        )}

        <NavContinueButton
          disabled={formHasErrors}
          onClick={() => {
            confirmForm();
            if (isPageValid) saveUserEntryData(userEntryData);
            return isPageValid;
          }}
        />
      </div>
    </>
  );
};

EventInformation.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(EventInformation);
