import { isEqual } from "lodash";
import { requestQuote } from "../Actions/quote";
import Debounce from "./Debounce";
import initialFormValues from "../Constants/initialFormValues";
import { dateHelpers } from "@jauntin/utilities";

/**
 *
 * Redux observer that checks for form state changes relevant to price
 * If some value changes, then ask the server for an updated quote
 *
 */

let currentValue;
const debouncePeriod = 500;

function select(state) {
  const defaultFormValues = initialFormValues();

  const {
    generalLiabilityCoverage,
    cannabis,
    eventDates,
    eventDailyGuests,
    ACDamageToRentedProperty,
    medical,
    ACTerrorism,
    venueMunicipalityCode,
  } = state.form.application.values;

  const selectedEventType = state.form.application.values.eventType;

  let data = {
    venueState: state.formPage.venue.selectedPlaceAddressComponents.state,
    isFederalEntity: state.formPage.venue.isFederalEntity ? 1 : 0,
    isKentuckyEntity: state.formPage.venue.isKentuckyEntity ? 1 : 0,
    venueMunicipalityCode,
  };

  if (selectedEventType) {
    data = { ...data, eventType: selectedEventType.value.identifier };
  }

  if (
    !isEqual(
      generalLiabilityCoverage,
      defaultFormValues.generalLiabilityCoverage
    )
  ) {
    data = {
      ...data,
      gll: generalLiabilityCoverage,
    };
  }

  if (!isEqual(cannabis, defaultFormValues.cannabis)) {
    data = {
      ...data,
      cannabis,
    };
  }

  if (!isEqual(eventDates, defaultFormValues.eventDates)) {
    data = {
      ...data,
      eventDates: eventDates.map((date) =>
        dateHelpers.dateOnlyStringFormat(date)
      ),
    };
  }

  if (!isEqual(eventDailyGuests, defaultFormValues.eventDailyGuests)) {
    data = { ...data, averageDailyAttendance: eventDailyGuests };
  }

  if (
    !isEqual(
      ACDamageToRentedProperty,
      defaultFormValues.ACDamageToRentedProperty
    )
  ) {
    data = { ...data, damageToRentedPremises: ACDamageToRentedProperty };
  }

  if (!isEqual(medical, defaultFormValues.medical)) {
    data = { ...data, medical: 5000 };
  }

  if (!isEqual(ACTerrorism, defaultFormValues.ACTerrorism)) {
    data = { ...data, terrorism: ACTerrorism };
  }

  return data;
}

function handleChange({ store }) {
  const previousValue = currentValue;
  currentValue = select(store.getState());
  if (JSON.stringify(previousValue) !== JSON.stringify(currentValue)) {
    store.dispatch(requestQuote(currentValue));
  }
}

export default (store) => {
  const debounce = new Debounce({ period: debouncePeriod });
  return store.subscribe(() => {
    debounce.do(handleChange, { store });
  });
};
