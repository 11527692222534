/**
 * Makes a request to API server to get insurance companies
 *
 */
class InsuranceCompanyService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getInsuranceCompanies() {
    return this.api.get({
      location: "/insurance-companies",
    });
  }
}

export default InsuranceCompanyService;
