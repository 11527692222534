import axios from "axios";
import param from "@gbli-events/common/src/Helpers/param";
import { apiUrl } from "../Constants/constants";

/**
 * Base abstraction for making an axios call to our servers
 * Provides some defaults such as headers and API base url.
 *
 * It is recommended to use this class with a 'Service' class that represents a set of calls
 * e.g. PriceService.getQuote - has an internal reference to an API object and prepares a call
 *
 * Calls return axios objects, so they can be used the same as axios.
 */
class API {
  static base = apiUrl;

  static defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  _instance = null;
  _statefulApi = false;

  constructor(URL = API.base) {
    if (URL === API.base) {
      this._statefulApi = true;
    }
    const config = {
      baseURL: URL,
      headers: API.defaultHeaders,
    };
    if (this._statefulApi) {
      config.withCredentials = true;
    }
    this._instance = axios.create(config);
  }

  get = ({ location, params }) => {
    return this._instance.get(location, {
      params,
      paramsSerializer: param,
    });
  };

  getDownload = ({ location, config }) => {
    return this._instance.get(location, config);
  };

  post = async ({ location, body }) => {
    if (this._statefulApi) {
      await this._instance.get(`sanctum/csrf-cookie`);
    }
    return await this._instance.post(location, body);
  };
}

export default API;
