import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import ThemeColor from "@gbli-events/common/src/Components/Themed/ThemeColor";
import Alert from "@gbli-events/common/src/Components/Themed/Alert";
import {
  GBLI_EMAIL_EVENTS,
  GBLI_PHONE_EVENTS_TITLE,
} from "@gbli-events/common/src/Constants/shared";
import { Link } from "react-router-dom";
import PriceService from "src/Helpers/PriceService";
import API from "src/Helpers/API";
import { useGetFormPath } from "src/Hooks/FormPaths";
import { loadQuote } from "src/Actions/quote";
import { getAdditionalCertHolders } from "src/Actions/actions";
import {
  COVERAGE_SUMMARY_URL,
  VENUE_SELECTION_URL,
} from "src/Constants/constants";
import { getUnderwritingQuestionsLoaded } from "src/Selectors/UnderwritingQuestions";

const QuoteLoader = () => {
  const { id } = useParams();
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [quoteInvalid, setQuoteInvalid] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const coverageUrl = useGetFormPath(COVERAGE_SUMMARY_URL);
  const venueUrl = useGetFormPath(VENUE_SELECTION_URL);
  const underwritingQuestionsLoaded = useSelector(
    getUnderwritingQuestionsLoaded
  );

  useEffect(() => {
    const validateQuote = async () => {
      const priceService = new PriceService(new API());

      try {
        const response = await priceService.getSavedQuote(id);
        dispatch(loadQuote(response.data.data.state));
        history.push(coverageUrl);
        const { venueAddress } = response.data.data.formData;
        const venue = venueAddress[0];

        if (venue?.venueCode && venue?.facilityCode) {
          dispatch(
            getAdditionalCertHolders(`${venue.facilityCode}-${venue.venueCode}`)
          );
        }
      } catch (e) {
        if (e.response.status === 422) {
          setQuoteInvalid(true);
        } else {
          setApiError(true);
        }
        setLoading(false);
      }
    };

    /* Wait for underwriting questions to be loaded so route guard is not triggered
    when navigating to coverage summary page */
    if (underwritingQuestionsLoaded) {
      validateQuote();
    }
  }, [id, dispatch, history, coverageUrl, underwritingQuestionsLoaded]);

  return (
    <div className="quote-loader d-flex align-items-center justify-content-center mh--hf container">
      <div className="d-flex align-items-center">
        {loading ? (
          <>
            <ThemeColor variant="primary">
              <Spinner animation="border" role="status" />
            </ThemeColor>
            <span className="ml-3">Loading your quote...</span>
          </>
        ) : (
          <>
            {apiError && (
              <Alert variant="error" className="mt-3">
                <i className="far fa-exclamation-circle alert__icon" />
                <div className="alert__text">
                  Sorry, we were unable to process your request. Please try
                  again or contact us at{" "}
                  <span className="text-nowrap">{GBLI_PHONE_EVENTS_TITLE}</span>{" "}
                  or {GBLI_EMAIL_EVENTS} for further assistance.{" "}
                </div>
              </Alert>
            )}
            {quoteInvalid && (
              <Alert variant="error" className="mt-3">
                <i className="far fa-exclamation-circle alert__icon" />
                <div className="alert__text">
                  Sorry, your link for the quote has expired or is no longer
                  valid. Please apply again{" "}
                  <Link to={venueUrl}>
                    <u>here</u>
                  </Link>
                  . Or contact us at{" "}
                  <span className="text-nowrap">{GBLI_PHONE_EVENTS_TITLE}</span>{" "}
                  or {GBLI_EMAIL_EVENTS} for further assistance.{" "}
                </div>
              </Alert>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QuoteLoader;
