import { SPECIAL_INSURANCE_THEME } from "@gbli-events/common/src/Constants/themes/specialInsuranceTheme";
// import { GBLI_THEME } from '@gbli-events/common/src/Constants/themes/gbliTheme';
/**
 * Makes a request to API server to get a theme style
 *
 */
class ThemesService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  // Disable GBLI theme for now.
  getTheme(themeSlug) {
    const themes = {
      "special-insurance": SPECIAL_INSURANCE_THEME,
      // 'gbli-events': GBLI_THEME,
    };

    return new Promise((resolve, reject) => {
      if (themes[themeSlug]) {
        resolve(themes[themeSlug]);
      } else {
        reject(SPECIAL_INSURANCE_THEME);
      }
    });
  }
}

export default ThemesService;
