import CurrencyHelper from "src/Helpers/CurrencyHelper";
import { normalizeTaxToString } from "@gbli-events/common/src/Helpers/normalizers";

export const getTaxItems = (state) =>
  state.formPage.quote.quote?.surcharges.map(
    ({ amount: { amount }, state, type, code, municipalName }) => ({
      label: normalizeTaxToString({ state, type, code, municipalName }),
      value: new CurrencyHelper(amount).convertFromInt().formatDollars(),
    })
  ) || [];
